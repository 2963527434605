@font-face {
    font-family: 'The Jamsil OTF 1 Thin';
    font-weight: 100;
    font-display: swap;
    src: local('The Jamsil OTF 1 Thin'), url('/font/The Jamsil OTF 1 Thin.otf') format('opentype'), url('/font/The Jamsil OTF 1 Thin.otf') format('opentype');
}

@font-face {
    font-family: 'The Jamsil OTF 2 Light';
    font-display: swap;
    src: local('The Jamsil OTF 2 Light'), url('/font/The Jamsil OTF 2 Light.otf') format('opentype'), url('/font/The Jamsil OTF 2 Light.otf') format('opentype');
}

@font-face {
    font-family: 'The Jamsil OTF 3 Regular';
    font-display: swap;
    src: local('The Jamsil OTF 3 Regular'), url('/font/The Jamsil OTF 3 Regular.otf') format('opentype'), url('/font/The Jamsil OTF 3 Regular.otf') format('opentype');
}

@font-face {
    font-family: 'The Jamsil OTF 4 Medium';
    font-display: swap;
    src: local('The Jamsil OTF 4 Medium'), url('/font/The Jamsil OTF 4 Medium.otf') format('opentype'), url('/fontThe Jamsil OTF 4 Medium.otf') format('opentype');
}

@font-face {
    font-family: 'The Jamsil OTF 5 Bold';
    font-display: swap;
    src: local('The Jamsil OTF 5 Bold'), url('/font/The Jamsil OTF 5 Bold.otf') format('opentype'), url('/font/The Jamsil OTF 5 Bold.otf') format('opentype');
}

@font-face {
    font-family: 'The Jamsil OTF 6 ExtraBold';
    font-display: swap;
    src: local('The Jamsil OTF 6 ExtraBold'), url('/font/The Jamsil OTF 6 ExtraBold') format('opentype'), url('/font/The Jamsil OTF 6 ExtraBold.otf') format('opentype');
}

@font-face {
    font-family: 'TmonMonsori';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/TmonMonsori.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

i.icon-eye {
    width: 27px;
    height: 23px;
    background: url('/img/renew-img/icon_eye_on.svg') no-repeat center;
    background-size: 27px 23px;
    display: inline-block;
    margin-top: 13.5px;
}

i.icon-eye.fa-eye-slash {
    background: url('/img/renew-img/icon_eye_off.svg') no-repeat center;
}

i.icon-eye.fa-eye::before,
i.icon-eye.fa-eye-slash::before {
    content: '';
}

.percent-1 {
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 15px;
    font-weight: 700;
    line-height: 18.15px;
    text-align: left;
    color: #9EEF20;
}

.percent-1::-webkit-progress-bar {
    border-radius: 10px;
    background: #1F2340;
}

.percent-1::-webkit-progress-value {
    background-color: #9EEF20;
}

.percent-2 {
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 15px;
    font-weight: 700;
    line-height: 18.15px;
    text-align: left;
    color: #20E3EF;
}

.percent-2::-webkit-progress-bar {
    border-radius: 10px;
    background: #1F2340;
}

.percent-2::-webkit-progress-value {
    background-color: #20E3EF;
}

.percent-3 {
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 15px;
    font-weight: 700;
    line-height: 18.15px;
    text-align: left;
    color: #ED1C24;
}

.percent-3::-webkit-progress-bar {
    border-radius: 10px;
    background: #ED1C24;
}

.percent-3::-webkit-progress-value {
    background-color: #ED1C24;
}

.percent-4 {
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 15px;
    font-weight: 700;
    line-height: 18.15px;
    text-align: left;
    color: #ED801C;
}

.percent-4::-webkit-progress-bar {
    border-radius: 10px;
    background: #ED801C;
}

.percent-4::-webkit-progress-value {
    background-color: #ED801C;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.m-version {
    display: none !important;
}

.p-version {
    display: block !important;
}

.required-icon {
    color: #AF314F !important;
}


/* 웹 리뉴얼 퍼블리싱 부분 --- kjy  */
/* 일괄적용되는 강제 텍스트 색상 처리 */
.c-red {
    color: rgba(255, 48, 64, 1) !important
}

.c-purple {
    color: #8C55FF !important;
}

.c-green {
    color: #A0FF0B !important
}

.c-orange {
    color: #F15A29 !important
}

.c-sky-blue {
    color: #35C3FF !important
}

.c-blue {
    color: #1DAEFF !important
}

.c-yellow {
    color: #FBB040 !important
}

.icon-clock-color {
    display: inline-block;
    background: url('/img/renew-img/icon_clock_color.svg') no-repeat center;
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
    margin-right: 9px;
    vertical-align: middle;
}

/* 로고 영역 수정  */
.logo-box {
    position: absolute;
    width: 12%;
    text-align: center;
    top: 0px;
    left: 0;
    height: 100%;
    background: #1a1c2c;
    border-radius: 60px 0 0 60px;
}

input#search-funding {
    background: #2D3256;
    border-radius: 40px;
    width: 64%;
    font-size: 16px;
    font-weight: 400;
    line-height: 17.6px;
    text-align: left;
    color: #fff;
    border: none;
    box-shadow: none;
    padding: 13px 18px;
}

.notice-page-title {
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 32px;
    font-weight: 400;
    line-height: 35.2px;
    text-align: center;
}

input#search-funding::placeholder {
    color: #171C3B
}

.searchBox {
    margin: 0 25% 0% 0;
    width: 30%;
    position: relative;
    display: none;
}

i.hd-search-icon {
    display: block;
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    background: url('/img/renew-img/search-icon.svg') no-repeat center;
    position: absolute;
    top: 50%;
    right: 40%;
    transform: translate(-50%, -50%);
}

.header-menu-list {
    width: 25%;
    margin-right: 3%;
}

.header-menu-list>div {
    width: 100%;
}

.header-menu-list>div>a {
    font-family: 'The Jamsil OTF 4 Medium';
    font-size: 18px;
    font-weight: 400;
    line-height: 19.8px;
    text-align: left;
    color: #fff;
}

.header-menu-list>div>a>p {
    margin: 0;
}

/* 웹 배너 영역 퍼블리싱 수정  */
.w-banner {
    width: 55%;
}

/* 리더보드 영역 퍼블리싱 */
.ld-brd {
    position: relative;
    width: 38%;
}

.ld-brd-list.active {
    position: absolute;
    left: 0;
    top: auto;
    z-index: 100;
}

.ld-brd-list {
    background: linear-gradient(180deg, #66447B 0%, #626DBC 100%);
    border-radius: 40px;
    padding: 13px 13px
}

.ld-brd-list>ul, .ld-brd-list>ul>li {
    list-style: none;
    padding: 0;
    border-radius: 30px;
}

.ld-brd-list>ul>li.ld-brd-item {
    background: #1F2340;
    border: 30px;
    padding: 9px 11px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 13px;
}

.ld-brd-list>ul>li.ld-brd-item img {
    width: 23%;
}

.ld-brd-list>ul>li.ld-brd-item .ld-brd-content {
    margin-left: 11px;
}

.ld-brd-list>ul>li.ld-brd-item .ld-brd-content .ld-brd-item-nick {
    margin-bottom: 3.5px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
    color: #fff;
}

.ld-brd-list>ul>li.ld-brd-item .ld-brd-content .ld-brd-item-title {
    margin-bottom: 3.5px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #BCBEC0;
}

.ld-brd-list>ul>li.ld-brd-item .ld-brd-price {
    font-family: "The Jamsil OTF 5 Bold";
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.3rem;
    text-align: left;
    margin-right: .5rem;
}

/* 리더보드 더보기 버튼 처리 */
i.show-more {
    background: url('/img/renew-img/icon_show_more.svg') no-repeat center;
    background-size: 11.8px 20px;
    display: block;
    width: 11.8px;
    height: 20px;
    margin: 0 auto 4px;
}

i.show-more.active {
    transform: rotate(-180deg);
}


/* mfunds ticker icon 추가 */
.p-rltv {
    position: relative;
}

i.icon-question-mark {
    background: url('/img/renew-img/icon_question_mark.svg') no-repeat center;
    background-size: 30px 30px;
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 auto 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

i.ticket-icon {
    background: url('/img/renew-img/mfunds_ticker_icon.svg') no-repeat center;
    background-size: 112px 80px;
    display: block;
    width: 112px;
    height: 80px;
    margin: 0 auto 4px;
    position: absolute;
    bottom: 8px;
    right: 5px;
}

/* 타이틀 앞에 before 아이콘 처리 */
#ing-prjt .funding-item .content-box .title::before {
    content: '';
    border: 1px solid #58BD0B;
    background: #A0FF0B;
    border-radius: 100%;
    width: 11px;
    height: 11px;
    display: inline-block;
    margin-right: 7px;
}

#soon-prjt .funding-item .content-box .title::before {
    content: '';
    border: 1px solid #F15A29;
    background: #FBB040;
    border-radius: 100%;
    width: 11px;
    height: 11px;
    display: inline-block;
    margin-right: 7px;
}

#achieved-prjt .funding-item .content-box .title::before {
    content: '';
    border: 1px solid #1C75BC;
    background: #35C3FF;
    border-radius: 100%;
    width: 11px;
    height: 11px;
    display: inline-block;
    margin-right: 7px;
}


/* 펀딩 아이템 뱃지 및 서브텍스트 */
.badge-item {
    font-family: 'The Jamsil OTF 5 Bold';
    font-size: 8.4px;
    font-weight: 700;
    line-height: 9.24px;
    text-align: center;
    padding: 5px 9px;
    border-radius: 20px;
    color: #1F2340;
}

.bg-funding {
    background: #A0FF0B;
}

.bg-soon {
    background: #F15A29;
}

.bg-done {
    background: #35C3FF;
}

.writer-name .percentage-text {
    font-family: 'The Jamsil OTF 3 Regular';
    font-size: 18px;
    font-weight: 400;
    line-height: 19.8px;
    text-align: left;
    margin-right: 5px;
}

.writer-name .status-text {
    font-family: 'The Jamsil OTF 3 Regular';
    font-size: 10px;
    font-weight: 400;
    line-height: 11px;
    text-align: left;
    color: #BCBEC0;
}

.writer-name .d-day-text {
    font-family: 'The Jamsil OTF 3 Regular';
    font-size: 18px;
    font-weight: 400;
    line-height: 19.8px;
    text-align: left;
}

.writer-name .d-day-text.c-1 {
    color: #ED1C24;
}

.writer-name .d-day-text.c-2 {
    color: #FBB040;
}

.writer-name .d-day-text.c-3 {
    color: #35C3FF;
}

.writer-name .d-day-text.c-4 {
    color: #A0FF0B;
}


/* 탭 영역 퍼블리싱  */
#prjt-tb {
    width: 1025px;
    margin: 0 auto;
    border-radius: 60px;
}

#prjt-tb>div {
    padding: 16.5px;
    width: 23%;
    background: #172445;
    font-family: 'TmonMonsori';
    font-size: 21px;
    font-weight: 900;
    line-height: 26.25px;
    text-align: center;
    color: #FFFFFF;
    height: fit-content
}

#prjt-tb>div:not(:last-child) {
    margin-right: 14.95px;
}

#prjt-tb>div {
    border-radius: 20px;
}

#prjt-tb>div .tab-small-title {
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 12px;
    font-weight: 400;
    line-height: 13.2px;
    text-align: center;
    margin-top: 1.4px;
    color: #FFFFFF;
    margin-bottom: 0;
}

#prjt-tb>div.active {
    background: #8C55FF;
    color: #1F2340;
}

#prjt-tb>div.active .tab-small-title {
    color: #1F2340;
}

/* 웹 리뉴얼 퍼블리싱 부분 --- kjy  */


.invalid-feedback {

    font-family: "The Jamsil OTF 4 Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    text-align: left;

    color: #fff;
    ;

}

input[type="checkbox"] {
    display: none;
}

input[type="checkbox"]+label {
    position: relative;
    display: inline-block;
    width: 23.07px;
    height: 23.07px;

    border: 1px solid #FFFFFF;
    background: transparent;
    background-color: transparent;
    border-radius: 100%;
}

input[type="checkbox"]:checked+label::after {
    content: '✔';
    font-size: 18px;
    width: 23.07px;
    height: 23.07px;
    text-align: center;
    position: absolute;
    color: #9EEF20;
    left: 0;
    top: 0px;
}

.infinite-scroll-component__outerdiv {
    background: #1C1A23 !important;
}

.TTTtangsbudaejjigaeB {
    font-family: 'TTTtangsbudaejjigaeB' !important;
}

.TTTtangsbudaejjigaeM {
    font-family: 'TTTtangsbudaejjigaeM' !important;
}

.left-item-text {
    border: 2px solid #27aae1;
    background: #172445;
    border-radius: 50px;
    padding: 4px;
    font-size: 11px;
    font-weight: 400;
    line-height: 12.1px;
    text-align: left;
    position: absolute;
    top: -7px;
    left: 50%;
    z-index: 1000;
    transform: translate(-50%);
    width: max-content;
}


.voteItem input[type="checkbox"] {
    position: relative;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.c-primary {
    color: #9EEF20 !important;
}

i.icon-clock {
    background: url('/img/renew-img/icon_watch.png') no-repeat center;
    background-size: cover;
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 3px;
}

i.icon-done {
    background: url('/img/renew-img/icon_done.svg') no-repeat center;
    background-size: cover;
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 3px;
}

i.icon-calendar {
    background: url('/img/renew-img/icon_calendar.svg') no-repeat center;
    background-size: cover;
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 3px;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    right: 0;
    right: 10pt;
    height: 25px;
    width: 25px;
    background: url(/img/icon_checkbox_off.svg) no-repeat 50%;
    background-size: cover;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
    background: url('/img/icon_checkbox_on.svg') no-repeat center;
}


html {
    font-size: 62.5%;
    font-size: calc(.35vw + 8.74px);
    font-weight: 500;
    background: url('/img/renew-img/bg/web-bg.svg') no-repeat 0 0;
    background-size: cover;
    background-color: #0D1C35;
}

a {
    text-decoration: none !important;
}

.loader {
    border: 10px solid #1C1A23;
    border-top-color: #2C3D4F;
    border-radius: 50%;
    width: 100px;
    position: fixed;
    height: 100px;
    left: 47%;
    transform: translate(-50%, -50%);
    top: 40%;
}

.fund-percent {
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 15px;
    font-weight: 700;
    line-height: 18.15px;
    text-align: left;
}

.progress-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #1C1A23;
    padding: 0 0 15px;
}

body>* {
    font-family: 'The Jamsil OTF 4 Medium' !important;
    font-weight: 500 !important;
}

#main {
    height: 100vh;
}

#main .react-player {
    top: 1.4rem !important;
    height: 9rem !important;
    border-radius: 0 10px 0 10px !important;
    width: 84% !important;
    left: 10pt !important;
}

#main .communityList {
    border-radius: 12px;
    margin: 0 8pt;
    padding: 0 12pt 43pt;
}

#main #twoLevel_left .react-player {
    left: 1rem !important;
}

.event-body {
    max-width: 1000px !important;
}

.event-body>img {
    max-width: 1000px !important;
}

.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}


.bg-normal {
    background: #667080 !important;
}

.btn-box .primary-btn.bg-primary {
    background: #9EEF20 !important;
}

body {
    margin-top: 6rem;
    margin-bottom: 11rem;

    color: #939598 !important;

    font-size: 1.2rem;

    /* font-family: Mukta Mahee !important; */
    font-family: "The Jamsil OTF 4 Medium" !important;

    -ms-overflow-style: none;

    background: url('/img/renew-img/bg/web-bg.svg') no-repeat 0 0;
    background-size: cover;
}

::-webkit-scrollbar {
    display: none;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: -6px;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: -6px;
}

footer {
    position: sticky;
    bottom: 0pt;
    border-top: 1px solid #939598;
    background: url('/img/renew-img/bg/web-footer-bg.svg') no-repeat center;
    background-size: cover;
}

div.dao-menu {
    display: inline-block;
    font-size: 20px;
    color: #33ff00;
    cursor: pointer;
    vertical-align: super;
}

#mymbId .card-player {
    top: 1rem;
    height: 9.5rem;
    width: 90% !important;
    left: 5%;
    border-radius: 10px;
}

.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    overflow-x: hidden;
}

input {
    /*  /* font-family: Mukta Mahee !important; */
    font-family: "The Jamsil OTF 4 Medium" !important;
}

.title {
    margin: 18px 0;
    text-overflow: clip;
    white-space: break-spaces;
    width: 480px;
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.title_bar {
    width: 33% !important;
    margin: 3rem 0rem;
}

.voteItem .title-box {
    width: 30%;
    text-align: left;
}

.voteItem .title {
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    margin: 0 0 14px;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}

.voteItem .name {
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    margin: 0;
}

.voteItem .badge.vote-badge {
    padding: 1.5px 13.5px;
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    ;
    background: #EE574C;
    border-radius: 40px;
    margin: 0 2px;
}

.voteItem .content-box {
    margin-bottom: 18px;
}

.voteItem .content-area {
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #6B6E6F;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.voteItem .button-area {
    width: 10vw;
    display: inline-block;
    position: absolute;
    top: 23pt;
    right: 5pt;
}

.container-fluid {
    height: 4rem;
}

/* pc 헤더 메뉴 수정  */
.container-fluid.p-version {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.container-fluid.p-version.active {
    height: 280px;
}

.container-fluid.p-version .manu-list-box {
    position: absolute;
    left: 50%;
    top: 78%;
    transform: translate(-50%, -50%);
    width: 50%;

}

.container-fluid.p-version.active .logo,
.container-fluid.p-version.active .manu-list-box {
    top: 15%;
}

.container-fluid.p-version .manu-list-box .main-menu {
    font-family: "The Jamsil OTF 4 Medium";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    text-align: center;
    color: #fff;
    ;
    position: absolute;
    top: 50%;
    width: 100%;
    left: 0;
    transform: translate(0%, -50%);
    align-items: center;
}

.container-fluid.p-version.active .manu-list-box .main-menu {
    top: 85%;
    align-items: start;
}

.container-fluid.p-version.active .manu-list-box .sub-menu {
    margin-top: 42px;
}

.container-fluid.p-version .manu-list-box .sub-menu p {
    font-family: "The Jamsil OTF 4 Medium";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    text-align: center;
    color: #fff;
    ;
    margin-bottom: 15px;
    display: none;
}

.container-fluid.p-version .manu-list-box .main-menu .main-menu-item:hover,
.container-fluid.p-version .manu-list-box .sub-menu .menu-list p:hover {
    color: #9EEF20;
}

.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 4rem;
}

.logout {
    max-width: 3rem;
    position: absolute;
    left: 5%;
    top: 50%;
    margin-left: 0;
    margin-top: -1.25rem;
}

.upperBar {
    height: 4rem;
}

.col-1fr-1fr {
    grid-template-columns: 1fr 1fr !important;
}

.grid-gap-10 {
    grid-gap: 10% !important;
}

.bg-dark-black, .bg-dark {
    background: #1C1A23 !important;
    background-color: #1C1A23 !important;
}

.all-times {
    position: absolute;
    top: 1152pt;
    background: #2C3D4F;
    color: #fff;
    font-size: 20pt;
    left: 43%;
    padding: 0 12pt
}

.max-times {
    position: absolute;
    top: 1245pt;
    background: #2C3D4F;
    color: #fff;
    font-size: 20pt;
    left: 43%;
    padding: 0 12pt;
}

.logo_mymbID {
    max-height: 1.5rem;
}

.logo_paypal {
    max-height: 3rem;
}

.blank_menu {
    width: 5rem;
    background: none;
    border: none;
}

.headMenu {
    max-width: 3rem;
    position: absolute;
    right: 5%;
    top: 50%;
    margin-right: -1.5rem;
    margin-top: -1.5rem;
}

.alert {
    max-width: 3rem;
    position: absolute;
    /* right: 10%; */
    top: 50%;
    margin-right: -.5rem;
    margin-top: -2rem;
}

.validation-form {
    padding: 6px 46px;
    border-radius: 30px;
}

.projectBtn {
    max-height: 3rem;
    margin: 1rem;
}

.bottomMenu {
    max-width: 3rem;
    display: block;
    position: absolute;

    top: 50%;
    left: 50%;
    margin-left: -1.5rem;
    margin-top: -1.8rem;

    -webkit-transition: -webkit-transform 200ms;
    transition: -webkit-transform 200ms;
    transition: transform 200ms;
    transition: transform 200ms, -webkit-transform 200ms;
}

.bottomComm {
    max-height: 3rem;
    display: block;
    position: absolute;

    top: 47%;
    left: 55%;
    margin-left: -1.5rem;
    margin-top: -1.2rem;

    -webkit-transition: -webkit-transform 200ms;
    transition: -webkit-transform 200ms;
    transition: transform 200ms;
    transition: transform 200ms, -webkit-transform 200ms;
}

.bottomProject {
    max-height: 3rem;
    display: block;
    position: absolute;

    top: 48%;
    left: 57%;
    margin-left: -1.8rem;
    margin-top: -1.3rem;

    -webkit-transition: -webkit-transform 200ms;
    transition: -webkit-transform 200ms;
    transition: transform 200ms;
    transition: transform 200ms, -webkit-transform 200ms;
}

.join-bg {
    /* background: url(/img/login_bg.svg) no-repeat left !important;
    width: 100%;
    height: 100%;
    background-size: cover !important; */
    background-color: #F8F9FC !important;
    background: #F8F9FC !important;

}


.join-bg.dark {
    background-color: #1E1E1Edf !important;
}

.input-form {
    max-width: 1200px;
    padding: 3rem 0 1rem;
    /* height: 100vh; */
    margin: 0px auto;
    align-items: center;
    align-content: center;
}

.login-form {
    max-width: 70%;
    background: #fff;
    padding: 36px 17px;
    background: #FFFFFF;
    border-radius: 30px;
    margin: 13px auto 0;
    border: 1px solid #1C1A23;
}

.login-btn {
    margin-top: 32px;
}

.join-box {
    display: grid;
    align-items: center;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
    margin-top: 30px;
}

.join-box .join-btn {
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;

    color: #fff;
    text-align: center;

    padding: 12px;
    width: 100%;
    background: #F8F9FC;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
}

button {
    border: none !important;
}

.box {
    background: #111;
    border-color: #000000;
    border-radius: 0px 10px;
    -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
    box-shadow: 0 1px 10px 0 #2C3D4F;

    font-size: 1rem;

    width: 100%;

    color: white;
}

#mymbId .id-img {
    position: absolute;
    left: 6pt;
    width: 90% !important;
    border-radius: 10px;
    top: 7pt;
    height: 9.9rem;
}

.menu-bars {
    background: url('/img/menu.png') center no-repeat !important;
    background-size: contain !important;
    width: 30px;
    height: 30px;
}

.vote-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 18px;
    letter-spacing: 0.16px;

    color: #2C3D4E;
    padding-left: 23px;
    border-left: 5px solid #2C3D4E;
}

.vote-noti {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;

    color: #666666;
}

.basicBtn {
    font-family: 'S-CoreDream-8';
    background: #2C3D4F !important;
    border-color: #2C3D4F !important;
    border-radius: 0px 10px;
    -webkit-box-shadow: 0 1px 10px 0 #2C3D4F !important;
    -moz-box-shadow: 0 1px 10px 0 #2C3D4F !important;
    box-shadow: 0 1px 10px 0 #2C3D4F !important;
    width: 100%;
    font-weight: 700;
    color: #fff !important;
}

#projectContent .basicBtn {
    background: #EE574C !important;
    border-color: #EE574C !important;
    width: fit-content;
    color: #fff !important;
    float: right;
    box-shadow: none !important;
    border-radius: 40px;
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 12px !important;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    text-align: center;
    padding: 12px 48px;
}

.fixed-top {
    box-shadow: 0px 4px 10px 0px #00000040;
    background: #1A1C2C !important;
    width: calc(100% - 112px);
    margin: 0 auto;
    border-radius: 60px;
    top: 56px !important;
    position: relative !important;
}

.fixed-bottom {
    width: 100vw !important;
    background: #1C1A23 !important;
}

.swiper-button-next:after, .swiper-button-prev:after,
.swiper-pagination-bullets {
    display: none;
}

.swiper-button-next {
    background: url('/img/renew-img/next-btn.svg') no-repeat center;
    background-size: 8px 15px;
    width: 8px;
    height: 15px;
}

.swiper-button-prev {
    background: url('/img/renew-img/prev-btn.svg') no-repeat center;
    background-size: 8px 15px;
    width: 8px;
    height: 15px;
}


.basicBtn:hover {
    background-color: #000000 !important;
    color: #fff !important;
}

.badge-btn {
    font-family: Inter;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    text-align: center;
    color: #000;
    padding: 2px 13px;
    background: #9EEF20;
    border-radius: 6px;
    cursor: pointer;
}

.badge-btn.bg-orange {
    color: #1F2340;
    background: #F15A29;
}

.badge-btn.bg-sky-blue {
    color: #1F2340;
    background: #35C3FF;
}

.chargeBtn {
    background: #2C3D4F;
    border-color: #2C3D4F;
    border-radius: 0px 10px;
    -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
    box-shadow: 0 1px 10px 0 #2C3D4F;

    color: white;
}

.chargeBtn:hover {
    background-color: #222;
    color: #2C3D4F;
}

.withdrawBtn {
    background: #2C3D4F;
    border-color: #2C3D4F;
    border-radius: 0px 10px;
    -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
    box-shadow: 0 1px 10px 0 #2C3D4F;

    color: white;
}

.withdrawBtn:hover {
    background-color: #1C1A23;
    color: #2C3D4F;
}

.mymCard_create {
    float: right;
    margin: auto;
    cursor: pointer;
    width: 50%;
    height: 45px;
    background: none;
    border: 2px solid #2C3D4F;
    -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
    box-shadow: 0 0px 10px 0 #2C3D4F;
    border-radius: 0px 10px;

    font-size: 1rem;
    text-align: center;
    color: #2C3D4F;
}

.ab-title {
    font-weight: 600;
    color: #fff;
    position: absolute;
    top: 50%;
    right: 3%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    transform: translate(0%, -50%);
    background: #939598;
    padding: 0 16pt;
}

.overviewBtn {
    margin: auto;
    cursor: pointer;
    width: 50%;
    height: 45px;
    background: none;
    border: 2px solid #2C3D4F;
    -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
    box-shadow: 0 0px 10px 0 #2C3D4F;
    border-radius: 0px 10px;

    font-size: 1rem;
    text-align: center;
    color: #2C3D4F;
}

.uploadMymC {
    display: block;
    margin-top: 3rem;
    padding: 0px 1rem;
    cursor: pointer;
    height: 45px;
    width: 15rem;
    background: none;
    border: 2px solid #2C3D4F;
    -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
    box-shadow: 0 0px 10px 0 #2C3D4F;
    border-radius: 0px 10px;

    font-size: 1rem;
    text-align: center;
    color: #2C3D4F;
}

.buyTkt {
    display: block;
    margin-top: 5rem;
    padding: 0px 1rem;
    cursor: pointer;
    height: 45px;
    width: 15rem;
    background: none;
    border: 2px solid #2C3D4F;
    -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
    box-shadow: 0 0px 10px 0 #2C3D4F;
    border-radius: 0px 10px;

    font-size: 1rem;
    text-align: center;
    color: #2C3D4F;
}

.publishBtn {
    display: block;
    margin-top: 3rem;
    padding: 0px 1rem;
    cursor: pointer;
    height: 45px;
    width: 15rem;
    background: none;
    border: 2px solid #2C3D4F;
    -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
    box-shadow: 0 0px 10px 0 #2C3D4F;
    border-radius: 0px 10px;

    font-size: 1rem;
    text-align: center;
    color: #2C3D4F;
}

.mymCard_create:hover,
.overviewBtn:hover,
.uploadMymC:hover,
.buyTkt:hover,
.publishBtn:hover {
    background-color: #1C1A23;
    ;
    color: #fff;
}

#twoLevel_top {
    background: none;
    display: block;
    height: 35px;
    padding-bottom: 2rem;
    position: relative;
}

#twoLevel_left {
    float: left;
    width: 50%;
    background: none;
    margin-top: auto;
    position: relative;
    ;
}

.twoLevel_right {
    float: right;
    width: 50% !important;
    background: none;
    position: relative;
}

#twoLevel_bottom {
    clear: both;
    background: none;
    display: block;
    /* padding-top: 2rem; */
    position: relative;
    display: inline-block;
    width: 100%;
    margin-top: 40pt;
}

#main #twoLevel_bottom {
    margin-top: 7pt;
}

#mymC_meme {
    width: 85% !important;
    height: 70% !important;
    top: 15% !important;
    left: 50% !important;
    transform: translate(-50%, -10%) !important;
    background-color: black !important;
    border-radius: 0px 10px !important;
}

.mymC_wrap {
    width: 90%;
    height: 14rem;
}

.mymC_memeName {
    padding: 1rem;
    width: 15rem;
    display: inline;

    line-height: 1rem;

    top: 33%;
    left: -15%;
}

.mb-20px {
    margin-bottom: 20px;
}

.mb-40px {
    margin-bottom: 40px;
}

.ready-speech-bubble {
    position: absolute;
    top: -14pt;
    left: 50%;
    transform: translate(-50%, 0);
}

.memeName_text p {
    font-size: 1.5rem;
}

h2 {
    color: #2C3D4F;
}

input {
    border-color: #2C3D4F;
    color: #939598;
}

button {
    background-color: #2C3D4F;
}

h4 {
    font-size: 2rem;
}

.stage {
    /* width: 15rem;
    height: 15rem; */
    position: relative;
    display: block;
    margin: 0;

    -webkit-perspective: 60rem;
    -ms-perspective: 60rem;
    -moz-perspective: 60rem;
    -o-perspecitive: 60rem;
    perspective: 60rem;
}

.cube {
    /* height: 15rem;
    width: 15rem; */
    position: relative;
    margin: 0;

    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
}

/* .cube div {
    height: 15rem;
    width: 15rem;
    position: absolute;
    color: white;
    text-align: center;
    line-height: 15rem;
} */

.cube, .stage {
    width: 12rem;
    height: 12rem;
    position: relative;
    margin: 0;
}

.side_top {
    background: #ffffff;
    top: -6rem;
    left: 0px;
    position: absolute;
    width: 12rem;
    height: 12rem;
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    transform: rotateX(90deg);
}

.side_bottom {
    background: #ffffff;
    top: 6rem;
    left: 0px;
    position: absolute;
    width: 12rem;
    height: 12rem;
    -webkit-transform: rotateX(-90deg);
    -ms-transform: rotateX(-90deg);
    -o-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
}

.side_front {
    background: #818181;
    top: 0px;
    left: 0px;
    position: absolute;
    width: 12rem;
    height: 12rem;
    -webkit-transform: rotateY(0deg) translateZ(0rem);
    -ms-transform: rotateY(0deg) translateZ(0rem);
    -o-transform: rotateY(0deg) translateZ(0rem);
    transform: rotateY(0deg) translateZ(0rem);
}

.side_back {
    background: #818181;
    opacity: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    width: 12rem;
    height: 12rem;
    -webkit-transform: rotateY(180deg) translateZ(0rem);
    -ms-transform: rotateY(180deg) translateZ(0rem);
    -o-transform: rotateY(180deg) translateZ(0rem);
    transform: rotateY(180deg) translateZ(0rem);
}

.side_right {
    background: #2C3D4F;
    top: 0px;
    left: 6rem;
    position: absolute;
    width: 12rem;
    height: 12rem;
    -webkit-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    transform: rotateY(90deg);
}

.side_left {
    background: #2C3D4F;
    top: 0px;
    left: -6rem;
    position: absolute;
    width: 12rem;
    height: 12rem;
    -webkit-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
}

.stage.rotate:hover .cube {
    -webkit-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg);
}


/* 사용자 상세 페이지 cube css 수정  */

.toast {
    display: none;
    position: fixed;
    left: 50%;
    top: 30%;
    z-index: 10000;
    transform: translate(-50%, -40%);
    background: #000 !important;
    background-color: #000 !important;
    -webkit-box-shadow: 0 0px 10px 0 #2C3D4F !important;
    -moz-box-shadow: 0 0px 10px 0 #2C3D4F !important;
    box-shadow: 0 0px 10px 0 #2C3D4F !important;

}

.bigBox {
    margin: 15rem 0;
}

.hotMymCs.bigCube {
    height: 28rem;
}

.hotMymCs.bigCube .stage,
.hotMymCs.bigCube .cube,
.hotMymCs.bigCube .cube div {
    height: 27rem;
    width: 27rem;
}

.bigCube .side_top {
    background: #ffffff;
    top: -50%;
    left: 0px;
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    transform: rotateX(90deg);
}

.bigCube .hotMymC-logo-box {
    left: 66%;
    top: 60%;
}

.bigCube .side_bottom {
    background: #ffffff;
    top: 50%;
    left: 0px;
    -webkit-transform: rotateX(-90deg);
    -ms-transform: rotateX(-90deg);
    -o-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
}

.bigCube .side_front {
    background: #818181;
    top: 0px;
    left: 0px;
    -webkit-transform: rotateY(0deg) translateZ(0rem);
    -ms-transform: rotateY(0deg) translateZ(0rem);
    -o-transform: rotateY(0deg) translateZ(0rem);
    transform: rotateY(0deg) translateZ(0rem);
}

.bigCube .side_back {
    background: #818181;
    opacity: 100%;
    top: 0px;
    left: 0px;
    -webkit-transform: rotateY(180deg) translateZ(0rem);
    -ms-transform: rotateY(180deg) translateZ(0rem);
    -o-transform: rotateY(180deg) translateZ(0rem);
    transform: rotateY(180deg) translateZ(0rem);
}

.bigCube .side_right {
    background: #2C3D4F;
    top: 0px;
    left: 80%;
    -webkit-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    transform: rotateY(90deg);
}

.bigCube .side_left {
    background: #2C3D4F;
    top: 0px;
    left: -20%;
    -webkit-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    color: #2C3D4F !important;
    visibility: hidden;
}

.nav-link {
    text-decoration: none;
    color: white;
    text-align: left;
    margin-left: 10px;
}

.card-back>img {
    width: 100% !important;
    height: 12.5rem;
}

#freeCommunity .input-form {
    /* max-width: 100% !important; */
}

#freeCommunity .card-back>img {
    height: 17.5rem !important;
}

#freeCommunity .card-back>img.comm-img {
    position: absolute;
    left: 14pt;
    top: 20pt;
    border-radius: 0 12px 0 12px;
    width: 84% !important;
    height: 12.7rem !important;
}

#freeCommunity .card-back>.react-player {
    border-radius: 0 5px 0 5px;
    width: 84% !important;
    height: 5.7rem !important;
    left: 6pt;
    top: 10pt;
}

#freeCommunity .hotMymC .community-content-box .cube,
#freeCommunity .hotMymC .community-content-box .stage {
    width: 20rem;
    height: 20rem;
}

#freeCommunity .hotMymC .community-content-box .card-back>img,
#freeCommunity .hotMymC .community-content-box .hotMymCs {
    height: 20rem !important;
}

#freeCommunity .hotMymC .video-js.vjs-fluid {
    padding-top: 100% !important;
}

#freeCommunity .hotMymC .video-js .vjs-tech {
    height: 14.2rem !important;
}

#freeCommunity .hotMymC .video-js-player {
    width: 15rem;
    height: 14.3rem !important;
}

#freeCommunity .hotMymC .video-js-player>div {
    width: 15rem !important;
}

#freeCommunity .hotMymC .community-content-box .card-back>img.comm-img {
    position: absolute;
    left: 10%;
    top: 11.4%;
    border-radius: 0 30px 0 30px;
    width: 84% !important;
    height: 14.5rem !important;
    -o-object-fit: contain;
    object-fit: contain;
}

#freeCommunity .hotMymC .comm-category {
    right: 5%;
    top: 0.5%;
}

#freeCommunity .hotMymC #profile-box {
    display: block !important;
}


#freeCommunity .hotMymC .community-icon-box {
    position: absolute;
    bottom: 13%;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    text-align: center;
    width: 80%;
}

#freeCommunity .hotMymC .accordion li i.fa-circle-play {
    right: auto;
    left: 33%;
    font-size: 22pt;
    bottom: 11.3rem;
    position: absolute;
}

.label-id {
    text-align: left;
    font-family: "The Jamsil OTF 4 Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 21px;

    letter-spacing: 0.1px;
}

.label-content {
    text-align: left;
    font-family: "The Jamsil OTF 4 Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 12pt;
    color: #fff;
    letter-spacing: 0.1px;
}

/* share btn */
.btn_wrap {
    float: left;
    display: flex;
    overflow: hidden;
    cursor: pointer;
    width: 22%;
    height: 45px;
    background-color: none;
    -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
    box-shadow: 0 0px 10px 0 #2C3D4F;
    border-radius: 0px 10px;
    will-change: transform;
    -webkit-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

.btn_wrap:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.btn_wrap span {
    font-family: 'S-CoreDream-8';
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 45px;
    border-radius: 0px 10px;
    font-size: 1rem;
    text-align: center;
    line-height: 45px;
    color: #fff;
    font-weight: 700;
    background-color: #2C3D4F;
    -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
    box-shadow: 0 0px 10px 0 #2C3D4F;
    -webkit-transition: all .25s ease;
    transition: all .25s ease;
}

.btn_wrap>.container {
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 45px;
    border-radius: 0px 10px;
}

.container i:nth-of-type(1) {
    -webkit-transition-delay: .9s;
    transition-delay: .9s;
}

.container i:nth-of-type(2) {
    -webkit-transition-delay: .7s;
    transition-delay: .7s;
}

.container i:nth-of-type(3) {
    -webkit-transition-delay: .4s;
    transition-delay: .4s;
}

.container i:nth-of-type(4) {
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
}

.btn_wrap:hover span {
    -webkit-transition-delay: .25s;
    transition-delay: .25s;
    -webkit-transform: translateX(-18rem);
    transform: translateX(-18rem)
}

.btn_wrap:hover i {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

/* 탭 전체 스타일 */
.tabs {
    background-color: none;
    width: 100%;
}

/* 탭 스타일 */
.tab_item {
    height: 50px;
    padding: 0rem 1rem;
    margin: 0rem 2rem;
    border: 2px solid #2C3D4F;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
    box-shadow: 0 1px 10px 0 #2C3D4F;

    background-color: none;

    line-height: 50px;
    text-align: center;
    color: #2C3D4F;

    display: block;
    float: left;
    transition: all 0.2s ease;
}


#mymbID_edit {
    float: left;
    margin: auto;
    padding: 0px 1rem;
    margin-left: 2rem;
    cursor: pointer;
    height: 45px;
    line-height: 45px;
    background: none;
    border: 2px solid #2C3D4F;
    -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
    box-shadow: 0 0px 10px 0 #2C3D4F;
    border-radius: 0px 10px;

    font-size: 1rem;
    text-align: center;
    color: #2C3D4F;
}

input[type="text"]:disabled {
    background: none;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    font-size: 1.2rem;
    padding: 0%;
}

input[type="password"]:disabled {
    background: none;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 1.2rem;
    padding: 0%;
}

select.box:disabled {
    background: none;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.secured_edit, .btn {
    position: relative;
    padding: 15px !important;
    font-family: "The Jamsil OTF 4 Medium" !important;
    font-size: 24px;
    font-weight: 500;
    line-height: 26.4px;
    text-align: center;

    background: #A0FF0B !important;
    color: #09142B !important;
    border-radius: 20px !important;

    width: fit-content;
}

#editBtn {
    position: relative;
    padding: 16px 12px;
    background: #9EEF20;
    border-radius: 8px;
    font-family: "The Jamsil OTF 4 Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000;
    width: 100%;
}

.secured:hover {
    background-color: #1C1A23;
    ;
}

.secured_save {
    float: right;
    cursor: pointer;
    width: 6rem;
    height: 35px;
    line-height: 35px;
    background: none;
    border: 2px solid #2C3D4F;
    margin-top: 1rem;
    -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
    box-shadow: 0 0px 10px 0 #2C3D4F;
    border-radius: 0px 10px;

    font-size: 1rem;
    text-align: center;
    color: #2C3D4F;
}

.secured:hover {
    background-color: #1C1A23;
    ;
}

#mymbID_edit_select {
    float: right;
    margin: auto;
    padding: 0px 1rem;
    margin-top: 1rem;
    cursor: pointer;
    height: 45px;
    line-height: 45px;
    background: none;
    border: 2px solid #2C3D4F;
    -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
    box-shadow: 0 0px 10px 0 #2C3D4F;
    border-radius: 0px 10px;

    font-size: 1rem;
    text-align: center;
    color: #2C3D4F;
}

#mymbID_edit:hover {
    background-color: #1C1A23;
    ;
    cursor: pointer;
}

#mymbID_edit_select:hover {
    background-color: #1C1A23;
    ;
    cursor: pointer;
}

.tab_item:hover {
    background-color: #1C1A23;
    ;
    cursor: pointer;
}

/* 라디오 버튼 UI삭제*/
input[name="tab_item"] {
    display: none;
}

/* 탭 컨텐츠 스타일 */
.tab_content {
    display: none;
    align-content: center;
    padding: 100px 0;
    ;
    clear: both;
    height: auto;
    overflow: auto;
}

/* 선택 된 탭 콘텐츠를 표시 */
#secured_content {
    display: block;
}

#trustlessCube:checked~#trustlessCube_content {
    display: block;
}

#trustlessCube_content {
    border: none !important;
}

/* 선택된 탭 스타일 */
.tabs input:checked+.tab_item {
    background-color: none;
}


.menu-item,
.menu-open-button {
    background: #fff;
    border-radius: 100%;
    width: 5rem;
    height: 5rem;
    margin-left: -2rem;
    position: absolute;
    color: #2C3D4F;
    text-align: center;
    line-height: 5rem;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform ease-out 200ms;
    transition: -webkit-transform ease-out 200ms;
    transition: transform ease-out 200ms;
    transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
}

.menu-item.mymbID {
    font-size: 0.2rem !important;
}

.menu-open {
    display: none;
}

.menu {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 5rem;
    height: 5rem;
    text-align: center;
    box-sizing: border-box;
    font-size: 2rem;
}

/* .menu-item {
    transition: all 0.1s ease 0s;
} */

.menu-item:hover {
    background: #EEEEEE;
    color: #fff;
}

.menu-item:nth-child(3) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-item:nth-child(4) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-item:nth-child(5) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-open-button {
    z-index: 2;
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1ㅁ.275);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transition-duration: 400ms;
    transition-duration: 400ms;
    -webkit-transform: scale(1.3, 1.3) translate3d(0, 0, 0);
    transform: scale(1.3, 1.3) translate3d(0, 0, 0);
    cursor: pointer;
    -webkit-box-shadow: 0 1px 10px 0 #ddd;
    -moz-box-shadow: 0 1px 10px 0 #ddd;
    box-shadow: 0 1px 10px 0 #ddd;
}

.menu-open-button:hover {
    -webkit-transform: scale(1.4, 1.4) translate3d(0, 0, 0);
    transform: scale(1.4, 1.4) translate3d(0, 0, 0);
}

.menu-open:checked+.menu-open-button {
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transition-duration: 200ms;
    transition-duration: 200ms;
    -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
    transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.menu-open:checked~.menu-item {
    -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
    transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
}

.menu-open:checked~.menu-item:nth-child(3) {
    transition-duration: 180ms;
    -webkit-transition-duration: 180ms;
    -webkit-transform: translate3d(-7.5rem, -4.3rem, 0);
    transform: translate3d(-7.5rem, -4.3rem, 0);
}

.menu-open:checked~.menu-item:nth-child(4) {
    transition-duration: 280ms;
    -webkit-transition-duration: 280ms;
    -webkit-transform: translate3d(0.07rem, -8.8rem, 0);
    transform: translate3d(0.07rem, -8.8rem, 0);
}

.menu-open:checked~.menu-item:nth-child(5) {
    transition-duration: 380ms;
    -webkit-transition-duration: 380ms;
    -webkit-transform: translate3d(7.5rem, -4.3rem, 0);
    transform: translate3d(7.5rem, -4.3rem, 0);
}

.poM, .mymbID, .wallet {
    -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
    box-shadow: 0 1px 10px 0 #2C3D4F;

    color: #2C3D4F;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
}



.comm-item,
.comm-open-button {
    background: #fff;
    border-radius: 100%;
    width: 5rem;
    height: 5rem;
    margin-left: -3rem;
    position: absolute;
    color: #2C3D4F;
    text-align: center;
    line-height: 5rem;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform ease-out 200ms;
    transition: -webkit-transform ease-out 200ms;
    transition: transform ease-out 200ms;
    transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
}

.comm-open {
    display: none;
}

.comm {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 80%;
    right: 0;
    width: 5rem;
    height: 5rem;
    text-align: center;
    box-sizing: border-box;
    font-size: 1rem;
}

/* .menu-item {
    transition: all 0.1s ease 0s;
} */

.comm-item:hover {
    background: #EEEEEE;
    color: #fff;
}

.comm-item:nth-child(3) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.comm-item:nth-child(4) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.comm-open-button {
    z-index: 2;
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transition-duration: 400ms;
    transition-duration: 400ms;
    -webkit-transform: scale(1.3, 1.3) translate3d(0, 0, 0);
    transform: scale(1.3, 1.3) translate3d(0, 0, 0);
    cursor: pointer;
    -webkit-box-shadow: 0 1px 10px 0 #ddd;
    -moz-box-shadow: 0 1px 10px 0 #ddd;
    box-shadow: 0 1px 10px 0 #ddd;
}

.comm-open-button:hover {
    -webkit-transform: scale(1.4, 1.4) translate3d(0, 0, 0);
    transform: scale(1.4, 1.4) translate3d(0, 0, 0);
}

.comm-open:checked+.comm-open-button {
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transition-duration: 200ms;
    transition-duration: 200ms;
    -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
    transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.comm-open:checked~.comm-item {
    -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
    transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
}

.comm-open:checked~.comm-item:nth-child(3) {
    transition-duration: 180ms;
    -webkit-transition-duration: 180ms;
    -webkit-transform: translate3d(-7.5rem, -4.3rem, 0);
    transform: translate3d(-7.5rem, -4.3rem, 0);
}

.comm-open:checked~.comm-item:nth-child(4) {
    transition-duration: 280ms;
    -webkit-transition-duration: 280ms;
    -webkit-transform: translate3d(0.07rem, -8.8rem, 0);
    transform: translate3d(0.07rem, -8.8rem, 0);
}

.frComm, .Hot {
    -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
    box-shadow: 0 1px 10px 0 #2C3D4F;

    color: #2C3D4F;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
}


.project-item,
.project-open-button {
    background: #fff;
    border-radius: 100%;
    width: 5rem;
    height: 5rem;
    margin-left: -2rem;
    position: absolute;
    color: #2C3D4F;
    text-decoration: none;
    text-align: center;
    line-height: 5rem;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform ease-out 200ms;
    transition: -webkit-transform ease-out 200ms;
    transition: transform ease-out 200ms;
    transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
}

.project-open {
    display: none;
}

.project {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0%;
    right: 80%;
    width: 5rem;
    height: 5rem;
    text-align: center;
    box-sizing: border-box;
    font-size: 1rem;
}

/* .menu-item {
    transition: all 0.1s ease 0s;
} */

.project-item:hover {
    background: #EEEEEE;
    color: #fff;
}

.project-item:nth-child(3) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.project-open-button {
    z-index: 2;
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transition-duration: 400ms;
    transition-duration: 400ms;
    -webkit-transform: scale(1.3, 1.3) translate3d(0, 0, 0);
    transform: scale(1.3, 1.3) translate3d(0, 0, 0);
    cursor: pointer;
    -webkit-box-shadow: 0 1px 10px 0 #ddd;
    -moz-box-shadow: 0 1px 10px 0 #ddd;
    box-shadow: 0 1px 10px 0 #ddd;
}

.project-open-button:hover {
    -webkit-transform: scale(1.4, 1.4) translate3d(0, 0, 0);
    transform: scale(1.4, 1.4) translate3d(0, 0, 0);
}

.project-open:checked+.project-open-button {
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transition-duration: 200ms;
    transition-duration: 200ms;
    -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
    transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.project-open:checked~.project-item {
    -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
    transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
}

.project-open:checked~.project-item:nth-child(3) {
    transition-duration: 280ms;
    -webkit-transition-duration: 280ms;
    -webkit-transform: translate3d(0.07rem, -8.8rem, 0);
    transform: translate3d(0.07rem, -8.8rem, 0);
}

.project_soon {
    -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
    box-shadow: 0 1px 10px 0 #2C3D4F;

    color: #2C3D4F;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
}

.notice-title {
    font-family: 'The Jamsil OTF 4 Medium';
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #fff;
}

.notice-title::before {
    content: '';
    border: 1px solid #9fb88c;
    background: #A0FF0B;
    border-radius: 100%;
    width: 11px;
    height: 11px;
    display: inline-block;
    margin-right: 31px;
}

.notice-date {
    font-family: 'The Jamsil OTF 4 Medium';
    font-size: 16px;
    font-weight: 300;
    line-height: 17.6px;
    text-align: center;
    color: #fff;
}

#mymCwrapper {
    display: block;
    position: relative;
    width: 40rem;
}

.slider {
    overflow-x: scroll;
    white-space: nowrap;
}

.slider img {
    width: 15rem;
    border-radius: 0px 10px;
}

.fa-regular.fa-heart {}

#wallet-container #nftCollection_content .react-player.card-player {
    top: 0;
}


#slider2 {
    overflow-y: scroll;
    white-space: nowrap;
    width: 15rem;
    height: 22rem;
}

#slider2 img {
    position: relative;
    display: block;
    width: 15rem;
    border-radius: 0px 10px;
}



/* Track */
::-webkit-scrollbar-track {
    background: none;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: none;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: none;
}

#check-btn {
    display: none;
}

#check-btn:checked~.email-verificate {
    display: block;
}

.email-verificate {
    display: none;
}




/* wallet tabs */
.wallet_tab {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.wallet_tab input[type="radio"] {
    display: none;
}

.wallet_tab label {
    margin: auto;
    height: 50px;
    width: calc(100%/3);
    border: 2px solid #2C3D4F;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
    box-shadow: 0 1px 10px 0 #2C3D4F;

    background-color: none;

    line-height: 50px;
    text-align: center;
    color: #2C3D4F;

    display: block;
    float: left;
    transition: all 0.2s ease;
}

.wallet_tab label:hover {
    background: #ffffff;
    cursor: pointer;
    color: black;
}

.wallet_tab_content {
    width: 100%;
    display: none;
    border-top: 1px solid #ffffff;
}

#poM:checked~#poM_content,
#cash:checked~#cash_content,
#nftCollection:checked~#nftCollection_content {
    display: flex;
}

.wallet_tab input:checked+label {
    background: #ffffff;
    color: black;
}





.code-editor {
    background-color: none;
    border-radius: 5px;
    box-shadow: 0 10px 6px -6px #2C3D4F;
    border: 1px solid #1B1C22;
    font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier;
    font-weight: bold;

    position: initial;
}

.code-editor>.log-tab label {
    margin: 0rem 0rem 2rem;
    height: 2rem;
    width: calc(100%/4);
    border: none;
    border-radius: 0px;
    box-shadow: none;

    background: none;

    line-height: 2rem;
    text-align: center;
    font-size: 1rem;
    color: #2C3D4F;

    display: block;
    float: left;
    transition: all 0.2s ease;
}

.code-editor>.log-tab>.log-tab input:checked+label {
    background: none;
    color: #3F4F5F;
}

.code-editor>.log-tab>.log-tab label:hover {
    cursor: pointer;
}

.code-editor>.log-tab>.tab-title {
    width: 100%;
    position: relative;
    padding: 5px 0 7px 5px;
    text-align: center;
    font-size: 1.5rem;
    color: #2C3D4F;

    margin: 2rem 0rem;
}

.code-editor>.log-tab>.log-tab>.tab-title {
    width: 100%;
    position: relative;
    margin: auto;
    text-align: center;
    font: 1rem;
    color: #2C3D4F;
}

.code-editor>.log-tab>.log-tab>.tab-title:hover {
    cursor: pointer;
    color: #3F4F5F;
}


.code-editor .log-content {
    padding: 1.5rem;
    font-size: .8rem;
    display: none;
    color: #2C3D4F;
}

.code-editor .lines>.line ::selection {
    background-color: #CCC;
}

.lines {
    width: 100%;
}


.log-content .lines>.line>span {
    white-space: normal;
    word-wrap: break-word;
}

.code-editor .lines .linenumber {
    color: #CCC;
    padding: 0 15px 0 0;
    font-weight: normal;
}


.log-tab input[type="radio"] {
    display: none;
}

#-myHistory:checked~#-myHistory_content,
/*#-smartContract:checked ~ #-smartContract_content,*/
#-myPost:checked~#-myPost_content,
#-nftPurchase:checked~#-nftPurchase_content,
#-etc:checked~#-etc_content {
    display: flex;
}

.pom-level {
    width: 100%;
    display: block;
    text-align: center;

    margin: 2rem 0rem;
}

.pom-level img {
    width: 13rem;
}



.meme-title {
    font-family: 'S-CoreDream-8';
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .5s;
    color: white;

    /* position the text in t’ middle*/
    display: flex;
    align-items: center;
    justify-content: center;
}

.location-listing:hover .location-title {
    opacity: 1;
}

.fa-circle-play {
    color: #2C3D4F !important;
}


.location-listing img {
    width: 45%;
    height: auto;
}


.hidden {
    transition: all 200ms ease;
    opacity: 0;
    pointer-events: none;
}

.carousel {
    width: 100%;
    height: 35rem;
}

.btn-primary {
    background: #A0FF0B !important;
    border-radius: 60px !important;
}

.btn-secondary {
    background: #667080 !important;
    border-radius: 60px !important;
}

.carousel .btn {
    position: absolute;
    height: calc(100% - 15%);
    width: calc(100% - 90%);
    margin: 3rem 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 400ms ease;
    overflow: hidden;
}

.carousel .btn:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 300ms ease;
}

.carousel .btn i {
    font-size: 1.6rem;
    color: #2C3D4F;
    transition: all 400ms ease;
    opacity: 0;
}

.carousel .btn-back {
    top: -3.5rem;
    left: 8%;
}

.carousel .btn-back:before {
    border-radius: 100% 0 0 100%;
    transform: translateX(10rem);
}

.carousel .btn-back .fa-arrow-left {
    transform: translateX(10rem);
}

.carousel .btn-next {
    top: -3.5rem;
    right: 9%;
}

.carousel .btn-next:before {
    border-radius: 0 100% 100% 0;
    transform: translate(-10rem);
}

.carousel .btn-next .fa-arrow-right {
    transform: translateX(-10rem);
}

.carousel .btn .right-indicator,
.carousel .btn .left-indicator {
    opacity: 1;
    color: #3F4F5F;
}

.carousel .btn .right-indicator {
    transform: translateX(-.5rem);
}

.carousel .btn .left-indicator {
    transform: translateX(-.5rem);
}

.carousel .btn:hover:before {
    border-radius: 0;
    background: none;
    transform: translateX(0);
}

.carousel .btn:hover .fa-arrow-left,
.carousel .btn:hover .fa-arrow-right {
    opacity: 1;
}

.carousel .btn:hover .fa-arrow-left {
    transform: translateX(.8rem);
}

.carousel .btn:hover .fa-arrow-right {
    transform: translateX(.8rem);
}

.carousel .btn:hover .right-indicator {
    transform: translateX(9rem);
    opacity: 0;
}

.carousel .btn:hover .left-indicator {
    transform: translateX(-9rem);
    opacity: 0;
}

.carousel .viewbox {
    width: 70%;
    height: 70%;
    position: relative;
    margin: 25px auto;
    z-index: 2;
    overflow: hidden;
    background: none;

}

.carousel .viewbox .track {
    width: 100%;
    height: 100%;
    position: relative;
    transition: all 500ms ease;
    z-index: 2;

}

.carousel .viewbox .track .slide {
    width: 100%;
    height: 100%;
    position: absolute;
}

.carousel .viewbox .track .slide .images {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: contain;
}


.carousel .nav-indicator {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}

.carousel .nav-indicator .dot,
.swiper-pagination-bullet {
    margin: 0 5px !important;
    width: .5rem !important;
    height: .5rem !important;
    border-radius: 50% !important;
    background: #ffffff !important;
    cursor: pointer !important;
    transition: all 300ms ease !important;
}

.carousel .nav-indicator .dot.active,
.swiper-pagination-bullet-active {
    background: #2C3D4F !important;
    -webkit-box-shadow: 0 0px 10px 0 #2C3D4F !important;
    -moz-box-shadow: 0 0px 10px 0 #2C3D4F !important;
    box-shadow: 0 0px 10px 0 #2C3D4F !important;
}

.hotMymC_1 {
    width: 100%;
    margin: auto;
    overflow: hidden;
}

.hotMymC_1 .mymC_wrap {
    width: 90%;
    height: 9rem;
}

.hotMymC_1 .mymC_memeName {
    padding: 0rem;
    width: 10rem;
    max-height: 1rem;
    display: inline;
    text-align: left;

    line-height: 1rem;

    top: 40%;
    left: -.5rem;
}

.hotMymC_1 .memeName_text p {
    font-size: .5rem;
}



.hotMymC_2 {
    width: 100%;
    margin-top: 2rem;
    overflow: hidden;
}

.hotMymC_2 .mymC_wrap {
    width: 90%;
    height: 9rem;
}

#userInfo .info-img {
    position: absolute;
    left: 8%;
    top: 17%;
    width: 83% !important;
    height: 9rem;
    border-radius: 0 10px 0 10px;
}

.hotMymC_2 .mymC_memeName {
    padding: 0rem;
    width: 10rem;
    max-height: 1rem;
    display: inline;
    text-align: left;
    height: 1rem;

    line-height: 1rem;

    top: 40%;
    left: -.1%;
}

.hotMymC_2 .memeName_text p {
    font-size: .5rem;
}


.hotMymCs {
    width: 100%;
    height: 15rem;
    align-items: center;
    justify-content: center;
    position: relative;
}

.hotMymC_1 .hotMymCs>.stage>.cube div {
    height: 12rem;
    width: 12rem;
    position: absolute;
    color: white;
    text-align: center;
    line-height: 10rem;
}

.hotMymC_2>.hotMymCs>.stage>.cube div {
    height: 8rem;
    width: 8rem;
    position: absolute;
    color: white;
    text-align: center;
    line-height: 8rem;
}


.hotMymC_1 {
    display: flex;
    /* grid-template-columns: 1fr 1fr 1fr; */
    gap: 2rem !important;
    /* grid-auto-flow: row dense; */
}

.hotMymC_2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: .5rem;
    grid-auto-flow: row dense;
}


.hotMymC_1 .hotMymCs>.stage>.cube .side_top {
    background: #ffffff;
    top: -6rem;
    left: 0pt;
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    transform: rotateX(90deg);
}

.hotMymC_1 .hotMymCs>.stage>.cube .side_bottom {
    background: #ffffff;
    top: 6rem;
    left: 0pt;
    -webkit-transform: rotateX(-90deg);
    -ms-transform: rotateX(-90deg);
    -o-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
}

.hotMymC_1 .hotMymCs>.stage>.cube .side_front {
    background: #818181;
    top: 0px;
    left: 0px;
    -webkit-transform: rotateY(0deg) translateZ(0rem);
    -ms-transform: rotateY(0deg) translateZ(0rem);
    -o-transform: rotateY(0deg) translateZ(0rem);
    transform: rotateY(0deg) translateZ(0rem);
}

.hotMymC_1 .hotMymCs>.stage>.cube .side_back {
    background: #818181;
    opacity: 100%;
    top: 0px;
    left: 0px;
    -webkit-transform: rotateY(180deg) translateZ(0rem);
    -ms-transform: rotateY(180deg) translateZ(0rem);
    -o-transform: rotateY(180deg) translateZ(0rem);
    transform: rotateY(180deg) translateZ(0rem);
}

.hotMymC_1 .hotMymCs>.stage>.cube .side_right {
    background: #2C3D4F;
    top: 0pt;
    left: 6rem;
    transform: rotateY(90deg) !important;
    -webkit-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
}

.hotMymC_1 .hotMymCs>.stage>.cube .side_left {
    background: #2C3D4F;
    top: 0pt;
    left: -6rem;
    -webkit-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
}

.hotMymC_2>.hotMymCs>.stage {
    width: 10rem;
    height: 10rem;
}

.hotMymC_2>.hotMymCs>.stage>.cube {
    width: 10rem;
    height: 10rem;
}


#freeCommunity .hotMymC_2>.hotMymCs>.stage,
#freeCommunity .hotMymC_2>.hotMymCs>.stage>.cube {
    width: 9rem;
    height: 9rem;
    margin: 0 0 0 12pt;
}


#freeCommunity .hotMymCs {
    height: 21rem;
}

#userInfo .collectionBox {
    height: 13rem !important;
}

.hotMymC_2>.hotMymCs>.stage>.cube .side_top {
    background: #ffffff;
    top: -4rem;
    left: 0pt;
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    transform: rotateX(90deg);
}

.hotMymC_2>.hotMymCs>.stage>.cube .side_bottom {
    background: #ffffff;
    top: 4rem;
    left: 0pt;
    -webkit-transform: rotateX(-90deg);
    -ms-transform: rotateX(-90deg);
    -o-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
}

.hotMymC_2>.hotMymCs>.stage>.cube .side_front {
    background: #818181;
    top: 0px;
    left: 0px;
    -webkit-transform: rotateY(0deg) translateZ(0rem);
    -ms-transform: rotateY(0deg) translateZ(0rem);
    -o-transform: rotateY(0deg) translateZ(0rem);
    transform: rotateY(0deg) translateZ(0rem);
}

.hotMymC_2>.hotMymCs>.stage>.cube .side_back {
    background: #818181;
    opacity: 100%;
    top: 0px;
    left: 0px;
    -webkit-transform: rotateY(180deg) translateZ(0rem);
    -ms-transform: rotateY(180deg) translateZ(0rem);
    -o-transform: rotateY(180deg) translateZ(0rem);
    transform: rotateY(180deg) translateZ(0rem);
}

.hotMymC_2>.hotMymCs>.stage>.cube .side_right {
    background: #2C3D4F;
    top: 0pt;
    left: 4rem;
    transform: rotateY(90deg) !important;
    -webkit-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
}

.hotMymC_2>.hotMymCs>.stage>.cube .side_left {
    background: #2C3D4F;
    top: 0pt;
    left: -4rem;
    -webkit-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
}



#wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Accordion Container */
#wrapper .container {
    width: 98%;
    position: relative;
    height: auto;

}

#wrapper .container:before {
    content: '';
    position: absolute;
    width: calc(100% - 30px);
    height: 100%;
    margin: 0;
    left: 15px;
    background-color: transparent;
    top: 0px;
    box-shadow: 0px 20px 40px #52616b;
    opacity: 0.2;
    z-index: -1;
    transition: ease-in-out 0.6s all;
}

#wrapper .container:hover.container:before {
    box-shadow: 0px 40px 90px #52616b;
}


#wrapper ul {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: none;
    border-radius: 10px;
    border: 2px solid #2C3D4F;
}

#wrapper .accordionTitle {
    font-family: "The Jamsil OTF 4 Medium";
    padding: 20px;
    position: relative;
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.8px;
    color: #3F4F5F;
    transition: ease-in-out 0.2s all;
    cursor: pointer;
}

#wrapper .accordionTitle:hover {
    padding-left: 25px;
}

/* Accordion Item line */
#wrapper .accordionTitle:before,
#wrapper .accordionTitle:after {
    content: "";
    position: absolute;
    height: 2px;
    border-radius: 50px;
    transition: ease-in-out 0.6s all;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#wrapper .accordionTitle:before {
    width: 100%;
    background-color: #1C1A23;
    ;
}

#wrapper .accordionTitle:after {
    background-image: linear-gradient(90deg, #000, #ffffff);
    width: 0%;
}

#wrapper .accordionTitle:hover::after {
    width: 100%;
}

/* Accordion Item line - Active */
#wrapper .accordionTitleActive:after {
    content: "";
    position: absolute;
    height: 2px;
    border-radius: 50px;
    transition: ease-in-out 0.6s all;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#wrapper .accordionTitleActive:after {
    background-image: linear-gradient(90deg, #52616b, #ffffff);
    width: 100%;
}

/* Accordion Item Icon  */
#wrapper .accIcon {
    float: right;
    width: 2rem;
    height: 2rem;
    display: flex;
    margin-top: -.4rem;
    align-items: center;
    justify-content: center;
}

#wrapper .accIcon:before,
#wrapper .accIcon:after {
    content: "";
    position: absolute;
    border-radius: 50px;
    background-color: #1C1A23;
    ;
    transition: ease 0.3s all;
}

#wrapper .accIcon:before {
    width: .2rem;
    height: 2rem;
}

#wrapper .accIcon:after {
    width: 2rem;
    height: .2rem;
}

#wrapper .accordionTitle:hover .accIcon:before,
#wrapper .accordionTitle:hover .accIcon:after {
    background-color: #1C1A23;
    ;
}

#wrapper .accIcon.anime.accIcon:before {
    transform: rotate(90deg);
}

/* Text Content */
#wrapper .accordion .item .text {
    opacity: 0;
    height: 0;
    padding: 0px 20px;
    position: relative;
    line-height: 24px;
    font-size: 1rem;
    font-weight: 200;
    transition: all 0.6s cubic-bezier(0.42, 0.2, 0.08, 1);
    overflow: hidden;
    letter-spacing: 0.5px;
}

/* Text Content - Class for JS to hide and show */
#wrapper .accordion .item .text.show {
    opacity: 1;
    height: auto;
    padding: 25px 20px;
    position: relative;
    z-index: 0;
    border-radius: 0px 0px 3px 3px;
}









* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.notice-page-title {}

.accordion ul {
    list-style: none;
}

.accordion li {
    list-style: none;
    position: relative;
}

.accordion a {
    color: #2C3D4F;
    text-decoration: none;
}

.notice-item {
    padding: 33px 0;
}

.accordion {
    width: 100%;
    margin: 1rem auto 0;
    /* max-width: 80rem; */
    background: none;
}

.accordion .link:first-child {
    border-top: 1px solid #010201;
}

.accordion .link {
    font-family: 'S-CoreDream-8';
    cursor: pointer;
    display: block;
    padding-bottom: 1rem 1.5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    border-bottom: 1px solid #010201;
    position: relative;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    left: 0rem;
}

.accordion li:last-child .link {
    border-bottom: 0;
}

.accordion li i {
    position: absolute;
    font-size: 1rem;
    color: #3F4F5F;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    right: 1rem;
}


.accordion li i.fa-circle-play {
    right: auto;
    left: 30%;
    font-size: 22pt;
    bottom: 17.3rem;
    /* left: -27px; */
    position: absolute;
}

.accordion li.open .link {
    color: #9EEF20;
}

.accordion li.open i.fa-circle-play {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.submenu {
    display: none;
    /* background: #1C1A23;; */
    font-size: 1rem;
    position: relative;
    left: 0rem;
}

.submenu li {
    border-bottom: 2px solid #2C3D4F;
    position: relative;
    padding-left: .5rem;
    padding-bottom: 1rem;
    left: -1.25rem;
}

.hotMymC_1 .hotMymCs {
    margin-left: 7% !important;
    margin-top: 7% !important;
}

.submenu a {
    display: block;
    text-decoration: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #3F4F5F;
    padding-left: 0rem;
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.fa-heart {
    color: #2C3D4F;
}

.fa-heart:hover {
    cursor: pointer;
    color: #2C3D4F;
    transform: scale(1.2);
}

.fa-eye::after {
    content: "  ";
    text-shadow: none;
    color: #fff;
}

.fa-heart::after {
    content: "  ";
    text-shadow: none;
    color: #fff;
}

.fa-hand-spock:hover {
    cursor: pointer;
    color: #2C3D4F;
    transform: scale(1.2);
}

.fa-hand-spock::after {
    content: "  ";
    text-shadow: none;
    color: #fff;
}

/*----- 검색창 ------*/
.search-wrapper {
    position: relative;
    transform: translate(-50%, -50%);
    top: 10%;
    left: 50%;
}

.search-wrapper .input-holder {
    height: 3rem;
    width: 3rem;
    overflow: hidden;
    background: rgba(255, 255, 255, 0);
    border-radius: 6px;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.search-wrapper.active .input-holder {
    width: 20rem;
    border-radius: 10px;
    background: none;
    border: 2px solid #2C3D4F;
    color: #3F4F5F;
    transition: all .5s cubic-bezier(0.000, 0.105, 0.035, 1.570);
}

.search-wrapper .input-holder .search-input {
    width: 100%;
    height: 3rem;
    padding: 0px 5rem 0 2rem;
    opacity: 0;
    background: none;
    position: absolute;
    transform: translate(0, 4rem);
    transition: all .3s cubic-bezier(0.000, 0.105, 0.035, 1.570);
    transition-delay: 0.3s;
}

.search-wrapper.active .input-holder .search-input {
    font-family: "The Jamsil OTF 4 Medium";
    opacity: 1;
    top: 0px;
    left: 0px;
    box-sizing: border-box;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2rem;
    color: #fff;
    transform: translate(0);
    border: none;
}

.search-wrapper .input-holder .search-icon {
    width: 3rem;
    height: 3rem;
    border: none;
    border-radius: 6px;
    background: #111;
    padding: 1px;
    outline: none;
    position: relative;
    z-index: 2;
    float: right;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.search-wrapper.active .input-holder .search-icon {
    width: 3rem;
    height: 3rem;
    border-radius: 10px;
}

.search-wrapper .input-holder .search-icon span {
    width: 3rem;
    height: 3rem;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    transform: rotate(45deg);
    transition: all .4s cubic-bezier(0.650, -0.600, 0.240, 1.650);
}

.search-wrapper.active .input-holder .search-icon span {
    transform: rotate(-45deg);
}

.search-wrapper .input-holder .search-icon span::before,
.search-wrapper .input-holder .search-icon span::after {
    position: absolute;
    content: '';
}

.search-wrapper .input-holder .search-icon span::before {
    width: .3rem;
    height: 1rem;
    left: 1.3rem;
    top: 1.6rem;
    border-radius: 2px;
    background: #2C3D4F;
}

.search-wrapper .input-holder .search-icon span::after {
    width: 1.3rem;
    height: 1.3rem;
    left: .8rem;
    top: .5rem;
    border-radius: 16px;
    border: 4px solid #2C3D4F;
}

.search-wrapper .close {
    position: absolute;
    z-index: 1;
    top: .6rem;
    right: 90%;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    transform: rotate(-180deg);
    transition: all .3s cubic-bezier(0.285, -0.450, 0.935, 0.110);
    transition-delay: 0.2s;
}

.search-wrapper.active .close {
    right: 0%;
    transform: rotate(45deg);
    transition: all .6s cubic-bezier(0.000, 0.105, 0.035, 1.570);
    transition-delay: 0.5s;
}

.search-wrapper .close::before, .search-wrapper .close::after {
    position: absolute;
    content: '';
    background: #2C3D4F;
    border-radius: 2px;
}

.search-wrapper .close::before {
    width: .5rem;
    height: 2rem;
    left: .7rem;
    top: 0px;
}

.search-wrapper .close::after {
    width: 2rem;
    height: .5rem;
    left: 0px;
    top: .7rem;
}

.search-wrapper input::placeholder {
    color: #3F4F5F;
}

.date-box {
    position: absolute;
    font-family: 'S-CoreDream-8';
    top: 44.9%;
    left: 37%;
    width: 16%;
    background: #2C3D4F;
    font-size: 19pt;
    color: #fff;
    font-weight: 800;
}

#event .date-box {
    font-family: 'S-CoreDream-8';
    position: absolute;
    top: 100.7rem;
    left: 42%;
    text-align: left;
    width: 16%;
    background: #2C3D4F;
    font-size: 22pt;
    color: #fff;
    font-weight: 800;
}

.btn-container {
    position: relative;
    transform: translate(0%, -50%);
    top: 10%;
    left: 0%;
}

.btn-container button {
    width: 8rem;
    height: 3rem;
    border: 0;
    border-radius: 6px;
    position: relative;
    color: #2C3D4F;
    font-family: 'S-CoreDream-8';
    font-weight: bold;
    background: #1C1A23;
    ;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-container button .text,
.btn-container button .icon-container {
    position: relative;
    z-index: 2;
}

.btn-container button .icon-container {
    position: relative;
    width: var(--icon-size);
    height: var(--icon-size);
    margin-left: 1.3rem;
    transition: transform 500ms ease;
}

.btn-container button .icon-container .icon {
    position: absolute;
    left: 0;
    top: 0;
    width: var(--icon-size);
    height: var(--icon-size);
    transition: transform 500ms ease, opacity 250ms ease;
}

.btn-container button::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--btn-bg);
    border-radius: var(--height);
    z-index: 1;
    transition: transform 500ms ease;
}

.btn-container button:hover::after {
    transform: translateX(65%);
}

.btn-container button:hover .icon-container {
    transform: translateX(125%);
}

.postWrap {
    max-width: 50rem;
    padding: 1rem;

    margin: 0px auto;
    align-items: center;
    align-content: center;
}

.postWrap input {
    margin: 1rem 0;
}

.postWrap textarea {
    background: #111;
    border: none;
    border-radius: 0px 10px;
    -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
    -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
    box-shadow: 0 1px 10px 0 #2C3D4F;

    color: #3F4F5F;
    padding: 1rem;
    margin: 1rem auto;

    width: 100%;
}

.postWrap .box {
    width: 100%;
}

.postWrap .btnsWrap {
    max-width: 50rem;
    text-align: right;
}

.postWrap .basicBtn {
    max-width: calc(50%/3);
    margin: 1rem auto;
}

.neonHr {
    border: 0.2rem solid #2C3D4F;
    height: 0.5rem;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #2C3D4F;
    padding: 0;
    animation: glow 1.5s ease-in-out infinite;
    -webkit-animation: glow 1.5s ease-in-out infinite;
    opacity: 1;
}

.video-js.vjs-fluid {
    width: 100%;
    max-width: 100%;
    padding-top: 85% !important
}

.neonHr::before {
    content: '';
    position: absolute;
    border: 0.1rem solid #fff;
    width: 47rem;
    margin: -1px -23.5rem;
    border-radius: 2px;
    box-shadow: 0 0 3px #ffffff;
    animation: glowMinor 2s ease-in-out;
    -webkit-animation: glowMinor 2s ease-in-out infinite
}

@keyframes glow {
    0% {
        box-shadow: 0 0 2px #2C3D4F;
    }

    50% {
        box-shadow: 0 0 8px #2C3D4F;
    }

    100% {
        box-shadow: 0 0 2px #2C3D4F;
    }
}

@-webkit-keyframes glow {
    0% {
        box-shadow: 0 0 2px #2C3D4F;
    }

    50% {
        box-shadow: 0 0 8px #2C3D4F;
    }

    100% {
        box-shadow: 0 0 2px #2C3D4F;
    }
}

@keyframes glowMinor {
    0% {
        box-shadow: 0 0 2px #fff;
    }

    50% {
        box-shadow: 0 0 8px #fff;
    }

    100% {
        box-shadow: 0 0 2px #fff;
    }
}

@-webkit-keyframes glowMinor {
    0% {
        box-shadow: 0 0 2px #fff;
    }

    50% {
        box-shadow: 0 0 8px #fff;
    }

    100% {
        box-shadow: 0 0 2px #fff;
    }
}

.modal {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: none;

    background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
    display: block;
}

.modal_body {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 450px;
    height: fit-content;
    padding: 40px 50px 80px;
    text-align: center;
    background-color: #1C1A23;
    opacity: 1;
    border-radius: 15px;
    color: #fff;
    overflow: scroll;
    transform: translateX(-50%) translateY(-50%);
}

.modal_body p,
.modal_body li {

    font-family: "The Jamsil OTF 4 Medium";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    text-align: center;

    color: #fff;

}

.modal_body table,
.modal_body th .modal_body td {
    border: 1px solid white;
    padding: .3rem;
}


.modal_body p.title {
    font-family: "The Jamsil OTF 4 Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    /* or 100% */
    text-align: center;

    color: #fff;
    ;
    width: 100%;
}

.modal_body p.content {
    font-family: "The Jamsil OTF 4 Medium";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    text-align: center;
    color: #fff;
    ;
    margin-top: 6px;
    width: 100%;
}

.outer {
    position: fixed;
    top: 3rem;
    left: 3rem;

    width: 2rem;
    cursor: pointer;
}

.inner {
    width: inherit;
    text-align: center;
}

.inner:before, .inner:after {
    position: absolute;
    content: '';
    height: 1px;
    width: inherit;
    background: #2C3D4F;
    left: 0;
    transition: all .3s ease-in;
}

.inner:before {
    top: 50%;
    transform: rotate(45deg);
}

.inner:after {
    bottom: 50%;
    transform: rotate(-45deg);
}

.outer:hover label {
    opacity: 1;
}

.outer:hover .inner:before,
.outer:hover .inner:after {
    transform: rotate(0);
}

.outer:hover .inner:before {
    top: 0;
}

.outer:hover .inner:after {
    bottom: 0;
}

.collectionWrap {
    width: 100%;
    height: 25rem;
    margin: 15pt auto;
    overflow-y: scroll;

    justify-content: right;
}

.collection {
    background: #818181;
    width: 15.5rem;
    height: 15.5rem;
    margin: 1.5rem;
    box-shadow: .2rem .2rem .2rem #fff;

    float: left;

    -webkit-transition: -webkit-transform 200ms;
    transition: -webkit-transform 200ms;
    transition: transform 200ms;
    transition: transform 200ms, -webkit-transform 200ms;
}

.collection:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    cursor: pointer;
}

.collection .mymC_wrap {
    width: 100%;
    height: 11rem;
    background-size: cover;
}

.collection .mymC_wrap img {
    width: 85%;
    height: 70%;
    transform: translate(8%, 8%);
    background-color: black;
    border-radius: 0px 10px;
}

/* 김주연 작업 -- 0914 ~ */

.mymC_wrap img {
    width: auto !important;
    max-height: .6rem;
    padding: 0rem;
}

.mymC_wrap .logo-box {
    text-align: right;
    top: 38%;
    right: 5%;
    height: 10%;
}

.collection .mymC_wrap .collection-logo-box {
    text-align: right;
    margin-top: .7rem;
}

.collection .mymC_wrap .collection-logo-box img {
    width: 1.5rem;
    padding: 0rem;
    background: none;
    border-radius: 0px;
}

.collection .mymC_wrap .mymC_memeName .name-tag {
    float: left;
    font-size: .5rem;
    margin-top: .7rem;
    width: 4rem;
}

#wallet-container .input-form.row {
    overflow: visible;
    margin-top: 0rem;
    padding-top: 2rem;
    align-items: initial;
    margin: 0 auto !important;
}

#poM_content .input-form.row {
    overflow: visible;
    margin-top: 0rem;
    padding-top: 2rem;
    align-items: center;
    margin: 0 auto !important;
}

#poM_content .input-form.row .text-center h3.py-3 {
    font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier !important;
    color: #2C3D4F !important;
}

#poM_content .pom-level {
    position: relative;
    z-index: 50;
}

#poM_content .pom-level>img {
    width: 17rem;
    z-index: 50;
}


#poM_content .pom-level-1 {
    position: absolute;
    top: 3rem;
    left: 60%;
    z-index: 49;
}

#poM_content .pom-level-1>img {
    opacity: 30%;
}

#poM_content .pom-level-2 {
    position: absolute;
    top: 5rem;
    left: 70%;
    z-index: 48;
}

#poM_content .pom-level-2>img {
    width: 10rem;
    opacity: 20%;
}

#poM_content .pom-level-3 {
    position: absolute;
    top: 7rem;
    left: 80%;
    z-index: 47;
}

#poM_content .pom-level-3>img {
    width: 7rem;
    opacity: 10%;
}

#twoLevel_top {
    margin-Top: 3rem
}

#twoLevel_top>img {
    margin-left: 1rem;
    width: auto;
}

#accordion .hotMymC_2 {
    margin-bottom: 0;
}

/* .bg-dark {
    background: #101111 !important;
} */

.overviewBtn {
    width: 100%;
    margin-top: 2rem
}

.swiper-wrapper img {
    width: 100% !important;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: -3pt !important;
}

#logo_mymbID {
    margin-left: 1rem;
}

.l60-t6-pr {
    left: 55%;
    top: 7%;
}

/* .swiper-button-next:after{
    content: "\f054" !important;
} 

.swiper-button-prev:after{
    content: "\f053" !important;
} */

.btn.buyTkt {
    width: 30%;
    margin: 0px;
    margin-left: 5%;
    color: #2C3D4F;
}

.current.ticketBtn {
    font-size: 1.5rem;
    border-bottom: 1px solid #939598;
    width: 50%;
    height: fit-content;
    text-align: center;
}

.current-cash {
    font-size: 1.5rem;
    border-bottom: 1px solid #939598;
    height: fit-content;
    text-align: center;
}

#twoLevel_top h3.py-3 {
    text-align: left;
    width: 100%;
    justify-self: baseline;
}

.mt-10 {
    margin-top: 10rem !important;
}

.mt-5 {
    margin-top: 5rem !important;
}

.mt-3 {
    margin-top: 3rem !important;
}

.mt-2 {
    margin-top: 2rem !important;
}

.mt-1 {
    margin-top: 1rem !important;
}

.mb-3 {
    margin-bottom: 3rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.ml-3 {
    margin-left: 3rem !important;
}

.ml-2 {
    margin-left: 2rem !important;
}

.ml-1 {
    margin-left: 1rem !important;
}

.mr-3 {
    margin-right: 3rem !important;
}

.mr-2 {
    margin-right: 2rem !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.m-0 {
    margin: 0 !important;
}

.pt-3 {
    margin-top: 3rem !important;
}

.pt-2 {
    margin-top: 2rem !important;
}

.pt-1 {
    margin-top: 1rem !important;
}

.pb-3 {
    margin-bottom: 3rem !important;
}

.pb-2 {
    margin-bottom: 2rem !important;
}

.pb-1 {
    margin-bottom: 1rem !important;
}

.pl-3 {
    margin-left: 3rem !important;
}

.pl-2 {
    margin-left: 2rem !important;
}

.pl-1 {
    margin-left: 1rem !important;
}

.pr-3 {
    margin-right: 3rem !important;
}

.pr-2 {
    margin-right: 2rem !important;
}

.pr-1 {
    margin-right: 1rem !important;
}

.p-0 {
    padding: 0 !important;
}



.btn_wrap.share {
    width: 50%;
    float: right;
    margin-top: 1rem;
    margin-right: .5rem;
}

#twoLevel_bottom h5.py-3,
#twoLevel_left h5.py-3 {
    text-align: left;
    width: 15%;
    justify-self: baseline;
}

#nftCollection_content .input-form>.input-form {
    margin-top: 1rem;
    /* border: 1px solid #fff; */
    padding: 1rem;
    border-radius: 10px;
    height: 24rem;
    justify-content: space-evenly;
    align-items: flex-start;
}

#nftCollection_content .input-form>.input-form:last-child {
    border: none;
}

#nftCollection_content #twoLevel_left {
    margin: 0 !important;
}

#nftCollection_content .twoLevel_right {
    float: right;
    padding-left: .5rem;
    margin: 0;
}

#nftCollection_content .twoLevel_right .container {}

#nftCollection_content .mymC-logo-box {
    text-align: right;
    left: 20%;
    top: 6%;
}

#nftCollection_content .mymC-logo-box>img {
    max-height: 1rem;
}

#wallet-container .mymCard_create {
    margin-top: 6rem;
    width: 15rem;
    right: 0;
    display: block;
    position: absolute;
}

#wallet-container .btn_wrap.share {
    width: 15rem;
}

.t-1 {
    top: 1rem !important;
}

.l-1 {
    left: 1rem !important;
}

.text-center {
    text-align: center !important;
}

.l20-t6-pr {
    left: 20%;
    top: 6%
}

#currentTicket {
    font-size: 1.5rem;
    border-bottom: 1px solid #939598;
    width: 50%;
    height: fit-content;
    text-align: center;
}

.container {
    margin: 53pt 0 0;
    overflow-y: scroll;
}

#main>content>div.input-form {
    margin: 0pt auto !important;
    overflow-y: scroll !important;
}

#main #twoLevel_left {
    display: inline-block;
    width: 30% !important;
}

#main .twoLevel_right {
    margin-right: 46pt;
}

#main #refcode {
    border: none;
    background: none;
    color: white;
    width: 18rem;
    cursor: default;
}

#trustlessCube_content {}


.hotMymC-logo-box {
    text-align: right;
    top: 38%;
    right: -33%;
    height: 10%;
}

#mymbID_content {
    height: max-content;
    position: relative;
}

#mymbID_content .btn_wrap.share {
    position: absolute;
    bottom: 15pt;
    right: 20%;
}

#freeCommunity .hotMymC_2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 2.5rem;
    height: 21rem;
}

.form-control {
    background: #09142B !important;
    background-color: #09142B !important;
    border: none !important;
    border-radius: 40px !important;
    color: #939598 !important;
    padding: 16px 12px !important;
    margin: 7px auto;
    width: 100%;
    box-shadow: none !important;

    font-family: 'The Jamsil OTF 2 Light';
    font-size: 24px;
    font-weight: 300;
    line-height: 26.4px;
    text-align: left;

    color: #fff;

}

.form-control[readonly] {
    background: #09142B !important;

    font-family: "The Jamsil OTF 4 Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    color: #4E5A8F !important;
}

.form-control::placeholder {
    color: #F4f4f4 !important;
}

.form-control.error {
    border: 1px solid #FF0000 !important;
}

.form-control.success {
    border: 1px solid #9EEF20 !important;
}

input[type=password] {
    font-family: "굴림" !important;
}


.w-5 {
    width: 5% !important;
}

.w-10 {
    width: 10% !important;
}

.w-15 {
    width: 15% !important;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-30 {
    width: 30% !important;
}

.w-33 {
    width: 33% !important;
}

.w-40 {
    width: 40% !important;
}

.w-50 {
    width: 50% !important;
}

.w-60 {
    width: 60% !important;
}

.w-64 {
    width: 64% !important;
}

.w-65 {
    width: 65% !important;
}

.w-70 {
    width: 70% !important;
}

.w-80 {
    width: 80% !important;
}

.w-90 {
    width: 90% !important;
}

.w-100 {
    width: 100% !important;
}

.navbar-dark .navbar-toggler {
    color: #1C1A23 !important;
    border-color: #1C1A23 !important;
}

.PENDING, .REVIEWING, .POLLING {
    background-color: #e5e5e5;
    color: #fff;
}

.DROPPED {
    background-color: #FF9999;
    color: #fff;
}

.SELECTED {
    background-color: #FFF501;
    color: #fff;
}

.RECEIVED, .ACCEPTED {
    background-color: #99F988;
    color: #fff;
}

#wallet-container .collection .mymC_wrap .mymC_memeName .mymC_tag {
    float: left;
    font-size: .5rem;
    width: 7rem;
    position: absolute;
    background: #000000ad;
    top: 1rem;
    color: #fff;
}

#wallet-container .collection .mymC_wrap .mymC_memeName .mymC_category {
    float: left;
    font-size: .5rem;
    width: 7rem;
    position: absolute;
    background: #000000ad;
    top: 3rem;
    border-radius: 10px;
    color: #fff;
}

#wallet-container .collection .mymC_wrap .mymC_memeName .mymC_logo {
    text-align: right;
    margin-top: 0;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    bottom: 2pt;
}

#wallet-container .collection .mymC_wrap .mymC_memeName .mymC_logo>img {
    width: 1.5rem;
    padding: 0rem;
    background: none;
    border-radius: 0px
}

.collection {
    position: relative;
}

.collection .layerDim {
    display: block;
    width: 10.5rem;
    height: 10.5rem;
    background: #000000aa;
    position: absolute;
    z-index: 5;
    display: none;
}

.collection .buttonBox {
    display: block;
    width: 8rem;
    height: 8rem;
    position: absolute;
    z-index: 7;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
}

.collection .shareBtn {
    width: 40%;
    float: right;
    z-index: 10;
    position: absolute;
    bottom: 0;
    right: 0;
}

#myIdContent .my-img {
    position: absolute;
    left: 5.6pt;
    border-radius: 4px;
    width: 90% !important;
    top: 9pt;
    height: 9.5rem;
}

#wallet-container .collection .mymC_memeName {
    width: 100%;
}

#userInfo {
    background: #111;
}

#wallet-container .cashTable,
#wallet-container .historyTable {
    border-collapse: separate;
    border: 1px solid #D3D3D3;
    text-align: center;
    border-radius: 10px;
}


#wallet-container .cashTable .unit,
#wallet-container .historyTable .unit {
    font-family: 'S-CoreDream-8';
    font-size: 12pt;
    font-weight: 700;
    margin-left: 4pt;
}

#wallet-container .table-box {
    border: 1px solid #d3d3d3;
    border-radius: 10px;
}

#wallet-container .historyTable {
    width: 100%;
    border: none;
    border-bottom: 1px solid #d3d3d3 !important;
    border-radius: 0;
}

#wallet-container .cashTable tr,
#wallet-container .cashTable th,
#wallet-container .cashTable td {
    border: 1px solid #D3D3D3;
}

#wallet-container .cashTable td,
#wallet-container .cashTable th {
    padding: 7pt 15pt;
}

#wallet-container .history td,
#wallet-container .history th {
    padding: 4pt 8pt;
}

#wallet-container .tb-hcontent {
    width: 100%;
}

.card-back {
    margin: 0 auto;
    position: relative;
}

.react-player {
    position: absolute;
    left: 5%;
    top: 8%;
    border-radius: 0px 10px 0px 10px;
}

.card-player {
    background: #d3d3d3d3;
    left: 0%;
    top: 0%;
}

video {
    border-radius: 20px;
}

#wallet-container .history-box {
    max-height: 250px;
    overflow-y: scroll;
    width: 100%;
    text-align: center;
}

#wallet-container .history-box::-webkit-scrollbar {
    background: #D3D3D3 0% 0% no-repeat padding-box;
    mix-blend-mode: overlay;
    border: 3px solid #FFFFFF;
    border-radius: 4px;
    display: block;
}

#mymC_meme {
    /* position:relative; */
}

.replyBox {
    overflow-y: scroll;
    max-height: 300px;
}

#mymC_meme .rankInfo {
    position: absolute;
    left: -45%;
    top: -60%;
}

#mymC_meme .rankInfo>span {
    font-family: 'S-CoreDream-8';
    color: #fff;
    font-size: 12pt;
    font-weight: bolder;
}


.footer-box {
    margin: 0 auto 0pt;
    padding: 60px 0;
    font-family: 'Pretendard-r' !important;
}


.footer-box .footer-data {
    font-family: "The Jamsil OTF 4 Medium";
    margin: 0 !important;
    width: fit-content;
    font-size: 15px;
    line-height: 1.6;
    color: #667080;
    font-weight: 500;
    text-align: left;
}

.footer-box .footer-data p {
    font-family: 'Pretendard-r';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    letter-spacing: -0.02em;
    color: #667080;
    margin-bottom: 6px;
}

.footer-box .footer-data .footer-title {
    /* 고객센터 */
    font-family: 'Pretendard-b' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height, or 120% */
    letter-spacing: -0.02em;

    color: #667080;
    margin-bottom: 20px;
}

.f-logo {
    width: 60px;
    /* height: 96px; */
}

.f-logo img {
    width: 100%;
}

.hallOfRanking {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-87%, 0);
    color: #fff;
    background: rgb(44, 61, 79);
    padding: 5pt 12pt;
    border-radius: 6px;
}

.community-hash-tag {
    margin-right: 4pt;
}

#mymbId #twoLevel_bottom {
    margin-top: 45pt;
}

#mymbId .react-player {
    /* height: 13rem !important; */
}

#userInfo .react-player {
    height: 9rem !important;
    background: #fff;
    top: 14.7pt;
    left: 8pt;
    width: 85% !important;
}

#userInfo .swiper {
    height: 11rem !important;
}

.trustBtn {
    text-align: center;
    margin: 7pt 0;
    position: absolute;
    bottom: 0;
    right: 6pt;
    padding: 7pt;
    text-align: center;
    border: 1px solid #2C3D4F;
    border-radius: 12px;
    box-shadow: 0 0 7px #2C3D4F;
}

.trustBtn>img {
    width: 25%;
}

.trust-count-box {
    margin-left: 19pt;
}

#main .swiper .swiper-slide {
    height: 30rem;
    text-align: center;
}

#main .swiper .swiper-slide video,
#main .swiper .swiper-slide>img {
    border: 1px solid #000;
    border-radius: 10px;
    width: 100% !important;
}




#main .swiper.hotMymC_1 .swiper-slide {
    height: auto !important;
}

.leftHidingBox {
    /* position: absolute;
    top: 0;
    height: 16rem;
    width: 9rem;
    top: 50%;
    left: 1%;
    z-index: 100;
    transform: translate(0, -50%); */
    /* background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 1) 20%); */
}

#join .form-label {
    margin-top: 2rem !important;
}

#wallet-container .wallet-img {
    top: 14pt;
    width: 83% !important;
    left: 10.5pt;
    border-radius: 10px;
    position: absolute;
    height: 9rem !important;
}

#wallet-container .card-img {
    transform: translate(0);
    max-height: 15.7rem;
    height: 15.7rem !important;
    width: 100% !important;
}


#wallet-container #nftCollection_content .react-player.card-player {
    height: 15.7rem !important;
}

#userView .cube,
#userView .stage {
    width: 50vw;
    height: 35vw;
    position: relative;
    margin: 30pt auto;
}

#userView .side_top {
    background: #ffffff;
    top: -5rem;
    left: 0;
    width: 50vw;
    height: 9vw;
    position: absolute;
    position: absolute;
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    transform: rotateX(90deg);
}

#userView .side_bottom {
    background: #ffffff;
    top: 33.1rem;
    left: 0rem;
    width: 50vw;
    height: 7.5vw;
    position: absolute;
    -webkit-transform: rotateX(-90deg);
    -ms-transform: rotateX(-90deg);
    -o-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
}

#userView .side_front {
    background: #818181;
    top: 0px;
    left: 0px;
    width: 50vw;
    height: 35vw;
    position: absolute;
    -webkit-transform: rotateY(0deg) translateZ(0rem);
    -ms-transform: rotateY(0deg) translateZ(0rem);
    -o-transform: rotateY(0deg) translateZ(0rem);
    transform: rotateY(0deg) translateZ(0rem);
}

#userView .side_back {
    background: #818181;
    opacity: 100%;
    top: 0px;
    left: 0px;
    width: 50vw;
    height: 35vw;
    position: absolute;
    -webkit-transform: rotateY(180deg) translateZ(0rem);
    -ms-transform: rotateY(180deg) translateZ(0rem);
    -o-transform: rotateY(180deg) translateZ(0rem);
    transform: rotateY(180deg) translateZ(0rem);
}

#userView .side_right {
    background: #2C3D4F;
    top: 0;
    width: 10vw;
    height: 36vw;
    left: 46.5rem;
    position: absolute;
    transform: rotateY(90deg) !important;
    -webkit-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
}

#userView .side_left {
    background: #2C3D4F;
    top: 0pt;
    left: -5rem;
    width: 10vw;
    height: 36vw;
    position: absolute;
    -webkit-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
}

#userView .card-back>img {
    height: 35vw;
}

#userView .card-back>img:nth-child(2) {
    height: 27.4vw;
    width: 44.7vw !important;
    position: absolute;
    top: 31pt;
    left: 27pt;
    border-radius: 0 34px 0 34px;
}

.snsBox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin: 12pt 0;
    color: #010201;
    font-size: 25px;
    grid-gap: 50px;
}

.snsBox i {
    font-weight: 900;
    padding: 7px 7px;
    border-radius: 100%;
    /* width: 10px; */
    background: #A0FF0B;
    text-align: center;
}

.comm-delete {
    font-family: "The Jamsil OTF 4 Medium";
    position: absolute;
    right: 1rem;
    top: 1rem;
    border: none;
    padding: 4pt 8pt;
    border-radius: 10px;
    font-weight: 500;
    z-index: 100;
}

.comm-category {
    position: absolute;
    right: 7rem;
    top: 1rem;
    border: none;
    padding: 4pt 8pt;
    border-radius: 10px;
    font-weight: 600;
    z-index: 100;
    background: #D3D3D3;
    background-color: #D3D3D3;
}

#userView .card-nickName {
    position: absolute;
    bottom: 28pt;
    left: 108pt;
}

#userView .card-cardName {
    position: absolute;
    bottom: 16pt;
    left: 108pt;
}

#userView .card-hashTag {
    position: absolute;
    bottom: 3pt;
    left: 108pt;
}

#myIdContent .layerDim {
    display: none;
    background: rgb(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}

#myIdContent .none-login {
    font-family: 'S-CoreDream-8';
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1001;
    font-size: 15pt;
    font-weight: 800;
    transform: translate(-50%, -50%);
}

.community-content-box {
    position: relative;
}

.community-icon-box {
    position: absolute;
    bottom: 8%;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    text-align: center;
    width: 50%;
}

.trust-icon-box {
    position: absolute;
    top: 27%;
    left: 4%;
    padding: 7pt;
    text-align: center;
    border-radius: 12px;
    width: 100pt;
}

.trust-icon-box .trust-flex {
    justify-content: space-between;
    display: flex;
    align-items: center;
}

#userInfo .hotMymC_2 {
    display: grid;
    grid-template-columns: 0.3fr 1fr !important;
    padding: 0 12pt;
}

#userProfile .hotMymC_2 {
    display: inline-block !important;
}

#userProfile .hotMymCs {
    height: 9rem !important;
}

/* 
#userProfile .hotMymC_2 .hotMymCs div{
    width: 5rem !important;
    height: 5rem !important;
}*/

#userProfile {
    margin: 0;
}

#userProfile .card-back>img {
    height: 8rem !important;
}

#userProfile .card-back>img.info-img {
    position: absolute;
    left: 7pt;
    top: 10pt;
    width: 6.6rem !important;
    height: 5.8rem !important;
    border-radius: 0 5px 0 5px;
    object-fit: contain;
}

#main .swiper .main-player {
    width: 100% !important;
    left: 0 !important;

}


#freeCommunity .community-content-box .cube,
#freeCommunity .community-content-box .stage {
    width: 43rem;
    height: 43rem;
    position: relative;
    margin: 0;
}

#freeCommunity .community-content-box .hotMymCs,
#freeCommunity .community-content-box .card-back>img {
    height: 43rem !important;
}

#freeCommunity .board .hotMymC_2 {
    height: 60rem !important;
}

.video-js-player {
    position: absolute;
    top: 11%;
    left: 8%;
    width: 35rem;
    height: 35rem !important;
    border-radius: 0 20px 0 20px;
    /* pointer-events: none; */
}

.video-js-player>div {
    width: 36rem !important;
    border-radius: 0 20px 0 20px;
}

.video-js .vjs-tech {
    height: 31.2rem !important;
    border-radius: 0 27px 0px 27px;
    background: #111;
}

.vjs-control-bar {
    width: 36rem !important;
    height: 2rem !important;
    bottom: -2% !important;
    left: 1px !important;
    border-radius: 0 0 0 24px;
}

.vjs-control-bar>div {
    position: relative !important;
    width: 6rem !important;
}

.video-js.vjs-1-1, .video-js.vjs-16-9, .video-js.vjs-4-3, .video-js.vjs-9-16, .video-js.vjs-fluid {
    border-radius: 0 20px 0 20px;
}

#freeCommunity .community-content-box .card-back>img.comm-img {
    position: absolute;
    left: 36pt;
    top: 11.4%;
    height: 35rem !important;
    border-radius: 0 30px 0 30px;
    width: 84% !important;
    height: 31rem !important;
    object-fit: contain;
}

.projectCategory {
    font-family: 'S-CoreDream-8';
    border: 2px solid #ddd;
    background: #ddd;
    padding: 12pt 15pt;
    font-weight: 800;
    font-size: 16px;
    width: auto;
    line-height: 1.3;
    border-radius: 20px;
    margin: 50pt 4pt;
    color: #222;
}

.projectCategory.active {
    border: 2px solid #2C3D4F;
    background: #111;
    color: #fff;
}

#projectContent .content-list {
    padding: 85px 70px 30px;
    background: #fff !important;
    border-radius: 20px;
}

#projectContent .content-box {
    margin: 0 0 30px;
    border: 1px solid #2C3D4F;
    height: 400px;
    overflow-y: scroll;
    line-height: 2.2;
}

i.icon-crown {
    background: url('/img/crown.png') no-repeat center;
    background-size: 25px 25px;
    width: 25px;
    height: 25px;
    display: block;
}

.speech-bubble {
    position: fixed;
    right: 16%;
    background: #d07c7c;
    border-radius: 0.4em;
    z-index: 100000;
    width: 300px;
    height: 300px;
    display: none;
    top: 100pt;
    color: #fff;
}

.speech-bubble:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-left-color: #d07c7c;
    border-right: 0;
    border-bottom: 0;
    margin-top: -56px;
    margin-right: -10px;
}

.border-none {
    border: none !important;
}

#projectContent .content-box ul {
    padding: 0 !important;
}

#projectContent .content-box ul>li.voteItem {
    box-shadow: 0px 4px 4px 0px #00000040;
    margin: 16px 0;
    padding: 31px 13px 10px;
    width: 100%;
}

#projectContent .content-box ul>li.voteItem label {
    position: relative;
    width: 6vw;
    text-align: center;
}

#projectContent .btn-area .vote-date {
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}

#projectContent .content-box .viewBtn {
    padding: 4px 18px;
    border-radius: 10px;
    background: #fff;
    color: #fff;
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    border-radius: 20px;
}

#projectContent .content-box .playerBtn {
    background: #EE574C;
    padding: 4px 18px;
    border-radius: 20px;
    color: #fff;
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;

}

#projectContent .content .input[type="checkbox"] {
    width: 35px;
    height: 35px;
}


.sub-project {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    padding: 12pt 0;
    width: 1100px;
    margin: auto;
    position: relative;
    border-bottom: 1px solid #1C1A23;
    ;
}

.sub-project .name {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.sub-project.created-at {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.sub-project .badge {
    color: #fff !important;
    font-size: 14px;
}

.sub-project .delete-btn {
    font-family: 'S-CoreDream-8';
    background: #B40431;
    color: #fff;
    border: 1px solid #B40431;
    border-radius: 5px;
    font-weight: 800;
    font-size: 14px;
    padding: 4pt 15pt;
}

.writer-bg {
    /* The image used */
    background-image: url("/img/writer.jpg");
    /* Add the blur effect */
    filter: blur(8px);
    -webkit-filter: blur(8px);
    /* Full height */
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

i.download-btn {
    background: url('/img/icon_download.svg') no-repeat center;
    background-size: cover;
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-left: 8px;
}

i.video-btn {
    background: url('/img/icon_video.svg') no-repeat center;
    background-size: cover;
    display: inline-block;
    width: 20px;
    height: 16px;
    vertical-align: middle;
    margin-left: 8px;
}

@supports (-webkit-overflow-scrolling: touch) {
    input {
        font-size: 16px;
    }
}

.motion-bg {
    width: 100%;
    height: 100%;
    background: #222;
    position: absolute;
    top: 52pt;
    left: 0;
}

.dao-floating-btn {
    display: block;
    position: fixed;
    bottom: 15pt;
    right: 15pt;
    width: 150px;
    height: 75px;
    z-index: 100;
    background: url('/img/renew-img/w_dao_floating_btn.png') no-repeat center;
    background-size: 150px 75px;
    object-fit: contain;
}

/* 전체 타이틀 리퍼블리싱  */
.menu-list-title {
    padding: 0 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu-list-title .d-line {
    height: 0.5px;
    width: 82%;
}

.l-green {
    background: #A0FF0B;
}

.l-orange {
    height: 0.5px;
    background: #F15A29;
    width: 82%;
}

.l-sky-blue {
    background: #35C3FF;
}

.menu-list-title img {
    width: auto !important;
}

.renew-main-content {
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 24px;
    font-weight: 400;
    line-height: 29.05px;
    text-align: left;
    color: #fff;
    margin-bottom: 5px;
}

.renew-sub-content {
    font-family: "The Jamsil OTF 4 Medium";
    font-size: 11px;
    font-weight: 400;
    line-height: 13.31px;
    text-align: left;
    color: #939598;
    margin-bottom: 0;
}

/* 전체 타이틀 리퍼블리싱  */
/* container */
@media (min-width: 992px) {

    .container, .container-lg,
    .container-md, .container-sm {
        max-width: 100%;
    }
}

/* 모바일 맞춤 미디어 쿼리 - 0916 kjy */
@media (max-width: 767px) {

    .m-version {
        display: block !important;
    }

    .p-version {
        display: none !important;
    }

    .dao-floating-btn {
        display: block;
        position: fixed;
        bottom: 9pt;
        right: 5pt;
        width: 45px;
        height: 45px;
        border-radius: 100%;
        background: url('/img/renew-img/m_dao_floating_btn.png') no-repeat center;
        background-size: 45px 45px;
        object-fit: contain;
    }

    #projectContent .input-form {
        padding: 15px 9px !important
    }

    #projectContent .content-box .viewBtn {
        padding: 3px 7px;
        border-radius: 10px;
        background: #fff;
        color: #fff;
    }

    #projectContent .content-box .playerBtn {
        background: #FF0000;
        padding: 3px 7px;
        border-radius: 6px;
        color: #fff;
    }

    .trustBtn {
        position: relative;
        right: 0;
    }

    .trustBtn>img {
        width: 25% !important;
    }

    .community-content-box {
        padding: 15pt 12pt;
    }

    .community-icon-box {
        position: relative;
        display: flex;
        align-items: center;
        width: 80% !important;
        margin: 0 auto;
        justify-content: space-between;
    }

    .leftHidingBox {
        left: 3%;
    }


    #wallet-container .card-img {
        transform: translate(0, 0px);
        max-height: 14.7rem;
        height: 13.7rem !important;
        width: 100% !important;
    }


    #main #myIdContent #twoLevel_bottom {
        margin-top: 0 !important;
    }

    #main .swiper .react-player {
        height: 6.5rem !important;
        top: 7pt !important;
        width: 88% !important;
        left: 4pt !important;
    }

    #main .swiper .main-player {
        height: 6.5rem !important;
        top: 7pt !important;
        width: 97% !important;
        left: 4pt !important;
    }

    #main .swiper .swiper-slide {
        height: 26rem;
    }

    #main .swiper-wrapper .card-back>img {
        width: 100% !important;
        height: 8.5rem !important;
    }

    #main #twoLevel_bottom .swiper-wrapper .card-back .my-img {
        border-radius: 0 4px 0 4px !important;
        height: 6rem !important;
        top: 8pt !important;
        left: 6pt !important;
        width: 81% !important;
    }

    #mymbId .react-player {
        height: 10.5rem !important;
    }


    .replyBox {
        max-height: 250px;
    }

    #freeCommunity .fa-regular.fa-hand-spock,
    #hotMymC .fa-regular.fa-hand-spock {
        margin-left: 1rem !important;
    }

    .stage {
        /* width: 9rem;
        height: 9rem; */
        position: relative;
        display: block;
        margin: 0;

        -webkit-perspective: 60rem;
        -ms-perspective: 60rem;
        -moz-perspective: 60rem;
        -o-perspecitive: 60rem;
        perspective: 60rem;
    }

    .cube {
        /* height: 9rem;
        width: 9rem; */
        position: relative;
        margin: .2rem 0;
        -webkit-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;
    }

    .cube div {
        /* height: 9rem;
        width: 9rem; */
        /* position: absolute; */
        color: white;
        text-align: center;
        line-height: 8rem;
    }

    .side_top {
        background: #ffffff;
        top: -4.5rem;
        left: 0pt;
        width: 12rem;
        height: 12rem;
        position: absolute;
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
        transform: rotateX(90deg);
    }

    .side_bottom {
        background: #ffffff;
        top: 5.5rem;
        left: 0rem;
        width: 12rem;
        height: 12rem;
        position: absolute;
        -webkit-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
    }

    .side_front {
        background: #818181;
        top: 0px;
        left: 0px;
        width: 12rem;
        height: 12rem;
        position: absolute;
        -webkit-transform: rotateY(0deg) translateZ(0rem);
        -ms-transform: rotateY(0deg) translateZ(0rem);
        -o-transform: rotateY(0deg) translateZ(0rem);
        transform: rotateY(0deg) translateZ(0rem);
    }

    .side_back {
        background: #818181;
        opacity: 100%;
        top: 0px;
        left: 0px;
        width: 12rem;
        height: 12rem;
        position: absolute;
        -webkit-transform: rotateY(180deg) translateZ(0rem);
        -ms-transform: rotateY(180deg) translateZ(0rem);
        -o-transform: rotateY(180deg) translateZ(0rem);
        transform: rotateY(180deg) translateZ(0rem);
    }

    .side_right {
        background: #2C3D4F;
        top: 0pt;
        left: 4.5rem;
        width: 12rem;
        height: 12rem;
        position: absolute;
        transform: rotateY(90deg) !important;
        -webkit-transform: rotateY(90deg);
        -ms-transform: rotateY(90deg);
        -o-transform: rotateY(90deg);
    }

    .side_left {
        background: #2C3D4F;
        top: 0pt;
        left: -4.5rem;
        width: 12rem;
        height: 12rem;
        position: absolute;
        -webkit-transform: rotateY(-90deg);
        -ms-transform: rotateY(-90deg);
        -o-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
    }

    .stage.rotate:hover .cube {
        -webkit-transform: rotateY(360deg);
        -ms-transform: rotateY(360deg);
        -o-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }

    .nav-link {
        text-decoration: none;
        color: white;
        text-align: left;
        margin-left: 10px;
    }

    .l20-t6-pr {
        left: 30%;
        top: 13%;
    }

    .l60-t6-pr {
        left: 60%;
        top: 13%;
    }

    #mymbID_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    #mymbId #mymbID_content {
        /* display: block !important; */
    }

    .hotMymC_1 .hotMymCs>.stage {
        height: 7rem;
        width: 7rem;
    }

    .hotMymCs>.stage>.cube {
        height: 7rem;
        width: 7rem;
        position: relative;
        margin: .5rem 0;
    }

    .hotMymC_2>.hotMymCs>.stage>.cube {
        height: 6rem;
        width: 6rem;
        position: relative;
        margin: .5rem 0;
    }

    .hotMymC_2>.hotMymCs>.stage {
        height: 6rem;
        width: 6rem;
    }

    .hotMymC_1 .hotMymCs>.stage>.cube div {
        height: 9rem;
        width: 9rem;
        position: absolute;
        color: white;
        text-align: center;
        line-height: 7rem;
    }

    .hotMymC_2>.hotMymCs>.stage>.cube div {
        height: 6rem;
        width: 6rem;
        position: absolute;
        color: white;
        text-align: center;
        line-height: 5rem;
    }


    .hotMymC_1 .hotMymCs>.stage>.cube .side_top {
        background: #ffffff;
        top: -3.5rem;
        left: 0;
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
        transform: rotateX(90deg);
    }

    .hotMymC_1 .hotMymCs>.stage>.cube .side_bottom {
        background: #ffffff;
        top: 3.5rem;
        left: 0pt;
        -webkit-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
    }

    .hotMymC_1 .hotMymCs>.stage>.cube .side_front {
        background: #818181;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(0deg) translateZ(0rem);
        -ms-transform: rotateY(0deg) translateZ(0rem);
        -o-transform: rotateY(0deg) translateZ(0rem);
        transform: rotateY(0deg) translateZ(0rem);
    }

    .hotMymC_1 .hotMymCs>.stage>.cube .side_back {
        background: #818181;
        opacity: 100%;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(180deg) translateZ(0rem);
        -ms-transform: rotateY(180deg) translateZ(0rem);
        -o-transform: rotateY(180deg) translateZ(0rem);
        transform: rotateY(180deg) translateZ(0rem);
    }

    .hotMymC_1 .hotMymCs>.stage>.cube .side_right {
        background: #2C3D4F;
        top: 0pt;
        left: 3.5rem;
        transform: rotateY(90deg) !important;
        -webkit-transform: rotateY(90deg);
        -ms-transform: rotateY(90deg);
        -o-transform: rotateY(90deg);
    }

    .hotMymC_1 .hotMymCs>.stage>.cube .side_left {
        background: #2C3D4F;
        top: 0pt;
        left: -3.5rem;
        -webkit-transform: rotateY(-90deg);
        -ms-transform: rotateY(-90deg);
        -o-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_top {
        background: #ffffff;
        top: -3rem;
        left: 0pt;
        transform: rotateX(90deg);
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_bottom {
        background: #ffffff;
        top: 3rem;
        left: 0pt;
        -webkit-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_front {
        background: #818181;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(0deg) translateZ(0rem);
        -ms-transform: rotateY(0deg) translateZ(0rem);
        -o-transform: rotateY(0deg) translateZ(0rem);
        transform: rotateY(0deg) translateZ(0rem);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_back {
        background: #818181;
        opacity: 100%;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(180deg) translateZ(0rem);
        -ms-transform: rotateY(180deg) translateZ(0rem);
        -o-transform: rotateY(180deg) translateZ(0rem);
        transform: rotateY(180deg) translateZ(0rem);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_right {
        background: #2C3D4F;
        top: 0pt;
        left: 3rem;
        transform: rotateY(90deg) !important;
        -webkit-transform: rotateY(90deg);
        -ms-transform: rotateY(90deg);
        -o-transform: rotateY(90deg);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_left {
        background: #2C3D4F;
        top: 0pt;
        left: -3rem;
        -webkit-transform: rotateY(-90deg);
        -ms-transform: rotateY(-90deg);
        -o-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .mymC_memeName {
        margin-top: .25rem !important;
    }

    .hotMymC-logo-box {
        width: fit-content !important;
        height: fit-content !important;
        top: 47%;
        right: 5%;
    }

    #wallet-container .mymCard_create {
        margin-top: 3rem;
        width: 10rem;
        left: 1rem;
        display: block;
        position: relative;
        float: none;
    }

    #nftCollection_content .mymC-logo-box {
        top: 12%;
        left: 55%;
    }

    .postWrap .basicBtn {
        max-width: calc(80%/3);
    }

    /* in commPost */

    #commPost .stage,
    #wallet-container #nftCollection_content .stage {
        /* width: 15rem;
        height: 15rem; */
    }

    #commPost .cube,
    #wallet-container #nftCollection_content .cube {
        /* height: 15rem;
        width: 15rem; */
        margin: .2rem 0;
    }

    #commPost .cube div,
    #wallet-container #nftCollection_content .cube div {
        /* height: 15rem;
        width: 15rem; */
        position: absolute;
        color: white;
        text-align: center;
        line-height: 11rem;
    }

    #wallet-container #nftCollection_content #twoLevel_left .cube div.react-player {
        width: 83% !important;
        left: 8pt !important;
    }

    #commPost .side_top,
    #wallet-container #nftCollection_content .side_top {
        background: #ffffff;
        top: -6rem;
        left: 0pt;
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
        transform: rotateX(90deg);
    }

    #commPost .side_bottom,
    #wallet-container #nftCollection_content .side_bottom {
        background: #ffffff;
        top: 6rem;
        left: 0pt;
        -webkit-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
    }

    #commPost .side_front,
    #wallet-container #nftCollection_content .side_front {
        background: #818181;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(0deg) translateZ(0rem);
        -ms-transform: rotateY(0deg) translateZ(0rem);
        -o-transform: rotateY(0deg) translateZ(0rem);
        transform: rotateY(0deg) translateZ(0rem);
    }

    #commPost .side_back,
    #wallet-container #nftCollection_content .side_back {
        background: #818181;
        opacity: 100%;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(180deg) translateZ(0rem);
        -ms-transform: rotateY(180deg) translateZ(0rem);
        -o-transform: rotateY(180deg) translateZ(0rem);
        transform: rotateY(180deg) translateZ(0rem);
    }

    #commPost .side_right,
    #wallet-container #nftCollection_content .side_right {
        background: #2C3D4F;
        top: 0pt;
        left: 6rem;
        transform: rotateY(90deg) !important;
        -webkit-transform: rotateY(90deg);
        -ms-transform: rotateY(90deg);
        -o-transform: rotateY(90deg);
    }

    #commPost .side_left,
    #wallet-container #nftCollection_content .side_left {
        background: #2C3D4F;
        top: 0pt;
        left: -6rem;
        -webkit-transform: rotateY(-90deg);
        -ms-transform: rotateY(-90deg);
        -o-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
    }

    #main .twoLevel_right {
        margin: 0 !important;
    }

    #commPost .stage.rotate:hover .cube,
    #wallet-container #nftCollection_content .stage.rotate:hover .cube {
        -webkit-transform: rotateY(360deg);
        -ms-transform: rotateY(360deg);
        -o-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }

    /* 탭 스타일 */
    .tab_item {
        height: 50px;
        padding: 0rem .7rem;
        margin: 0rem .5rem;
        border: 2px solid #2C3D4F;
        border-radius: 6px;
    }


    .mt-10 {
        margin-top: 1rem !important;
    }

    .mt-3 {
        margin-top: .75rem !important;
    }

    .mt-2 {
        margin-top: .5rem !important;
    }

    .mt-1 {
        margin-top: .25rem !important;
    }

    .mb-3 {
        margin-bottom: .75rem !important;
    }

    .mb-2 {
        margin-bottom: .5rem !important;
    }

    .mb-1 {
        margin-bottom: .25rem !important;
    }

    .ml-3 {
        margin-left: .75rem !important;
    }

    .ml-2 {
        margin-left: .5rem !important;
    }

    .ml-1 {
        margin-left: .25rem !important;
    }

    .mr-3 {
        margin-right: .75rem !important;
    }

    .mr-2 {
        margin-right: .5rem !important;
    }

    .mr-1 {
        margin-right: .25rem !important;
    }

    .m-0 {
        margin: 0 !important;
    }

    .pt-3 {
        margin-top: .75rem !important;
    }

    .pt-2 {
        margin-top: .5rem !important;
    }

    .pt-1 {
        margin-top: .25rem !important;
    }

    .pb-3 {
        margin-bottom: .75rem !important;
    }

    .pb-2 {
        margin-bottom: .5rem !important;
    }

    .pb-1 {
        margin-bottom: .25rem !important;
    }

    .pl-3 {
        margin-left: .75rem !important;
    }

    .pl-2 {
        margin-left: .5rem !important;
    }

    .pl-1 {
        margin-left: .25rem !important;
    }

    .pr-3 {
        margin-right: .75rem !important;
    }

    .pr-2 {
        margin-right: .5rem !important;
    }

    .pr-1 {
        margin-right: .25rem !important;
    }

    .p-0 {
        padding: 0 !important;
    }

    #wallet-container #nftCollection_content .twoLevel_right {
        padding-left: .5rem;
    }

    #wallet-container #nftCollection_content #twoLevel_left,
    #wallet-container #nftCollection_content .twoLevel_right {
        width: 100% !important;
        height: 15rem !important;
    }

    #wallet-container #nftCollection_content #twoLevel_left .stage {
        margin: 0 auto !important;
    }

    #nftCollection_content .input-form>.input-form {
        height: 50rem;
    }

    #wallet-container #nftCollection_content .input-form.d-flex {
        display: block !important;
    }

    #wallet-container .collection .mymC_wrap .mymC_memeName .mymC_tag {
        float: left;
        font-size: .5rem;
        margin-top: 0rem;
        width: 4rem
    }

    #wallet-container .collection .mymC_wrap .mymC_memeName .mymC_logo {
        text-align: right;
        margin-top: 0;
        width: -moz-fit-content;
        width: fit-content;
        position: absolute;
        bottom: 2pt;
    }

    #wallet-container .collection .mymC_wrap .mymC_memeName .mymC_logo>img {
        width: fit-content;
        padding: 0rem;
        background: none;
        border-radius: 0px
    }

    #wallet-container .collection .mymC_memeName {
        width: 100%;
    }

    .f-logo {
        width: 35px;
        /* height: 96px; */
    }

    .event-body>img {
        width: 100% !important;
    }

    #freeCommunity .fa-regular.fa-hand-spock {
        left: 65% !important;
        bottom: 0pt !important;
        margin-left: 0 !important;
    }

    .trust-icon-box {
        left: 27%;
        position: absolute;
        width: 10rem;
        top: 18%;
        transform: translate(-50%, -10%);
    }

    .trust-icon-box .trust-flex {
        justify-content: space-between;
        display: flex;
        align-items: center;
        max-width: 40%;
        margin: 0 auto;
    }

    #freeCommunity .trust-icon-box .fa-regular.fa-hand-spock {
        bottom: 0pt !important;
        left: 0pt !important;
    }

    #mymbId #twoLevel_left {
        width: 100% !important;
        margin: 0 !important;
    }

    .label-id {
        font-size: 12pt;
    }

    .label-content {
        font-size: 9pt;
    }

    #commPost .react-player,
    #wallet-container #nftCollection_content .react-player {
        top: 18pt;
    }

    html {
        font-size: 62.5%;
        font-size: calc(00.35vw + 8.74px);
        font-family: 'The Jamsil OTF 3 Regular' !important;

    }

    .hotMymC_1 .hotMymCs {
        margin-left: -.5rem !important;
        margin-top: .5rem !important;
    }

    body {
        margin-top: 6rem;
        margin-bottom: 11rem;

        background: url('/img/renew-img/bg/mob-bg.svg') no-repeat 0 0;
        background-size: cover;
        color: #939598 !important;

        font-size: 14px;

        -ms-overflow-style: none;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    input {
        /* font-family: Mukta Mahee !important; */
        font-family: 'The Jamsil OTF 3 Regular' !important;
    }

    .title {
        margin: 18px 0;
        text-overflow: clip;
        white-space: break-spaces;
        width: 180px;
        text-align: left;
        font-family: "The Jamsil OTF 4 Medium";
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
    }

    .title_bar {
        width: 45% !important;
        margin: 3rem 0rem;
    }

    .title_bar:nth-child(odd) {
        width: 22% !important;
        margin: 3rem 0rem;
    }


    .container-fluid {
        height: 4rem;
    }

    .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 4rem;
    }

    .searchBox {
        margin: 0 0% 0 18%;
        width: fit-content;
        position: relative;
        display: none;
        width: 40%;
    }

    input#search-funding {
        width: auto;
        padding: 4px 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22.2px;
        text-align: left;
    }

    .logout {
        max-width: 3rem;
        position: absolute;
        left: 5%;
        top: 50%;
        margin-left: -1.5rem;
        margin-top: -1.25rem;
    }

    .upperBar {
        height: 4rem;
    }

    .bg-dark-black, .bg-dark {
        background: #1C1A23 !important;
        background-color: #1C1A23 !important;
    }

    .logo_mymbID {
        max-height: 1.5rem;
    }

    .logo_paypal {
        max-height: 3rem;
    }

    .blank_menu {
        width: 5rem;
        background: none;
        border: none;
    }

    .headMenu {
        max-width: 3rem;
        position: absolute;
        right: 5%;
        top: 50%;
        margin-right: -1.5rem;
        margin-top: -1.5rem;
    }

    .alert {
        max-width: 3rem;
        position: absolute;
        /* right: 10%; */
        top: 50%;
        margin-right: -.5rem;
        margin-top: -2rem;
    }

    .projectBtn {
        max-height: 3rem;
        margin: 1rem;
    }

    .bottomMenu {
        max-width: 3rem;
        display: block;
        position: absolute;

        top: 50%;
        left: 50%;
        margin-left: -1.5rem;
        margin-top: -1.8rem;

        -webkit-transition: -webkit-transform 200ms;
        transition: -webkit-transform 200ms;
        transition: transform 200ms;
        transition: transform 200ms, -webkit-transform 200ms;
    }

    .bottomComm {
        max-height: 3rem;
        display: block;
        position: absolute;

        top: 47%;
        left: 55%;
        margin-left: -1.5rem;
        margin-top: -1.2rem;

        -webkit-transition: -webkit-transform 200ms;
        transition: -webkit-transform 200ms;
        transition: transform 200ms;
        transition: transform 200ms, -webkit-transform 200ms;
    }

    #mymbId .id-img {
        position: absolute;
        left: 5.5pt;
        width: 86% !important;
        border-radius: 10px;
        top: 10pt;
        height: 9.2rem !important;
    }

    .bottomProject {
        max-height: 3rem;
        display: block;
        position: absolute;

        top: 48%;
        left: 57%;
        margin-left: -1.8rem;
        margin-top: -1.3rem;

        -webkit-transition: -webkit-transform 200ms;
        transition: -webkit-transform 200ms;
        transition: transform 200ms;
        transition: transform 200ms, -webkit-transform 200ms;
    }

    .notice-page-title {
        font-family: "The Jamsil OTF 4 Medium";
        font-size: 24px;
        font-weight: 400;
        line-height: 30.2px;
        text-align: center;
    }

    .input-form {
        max-width: 50rem;
        padding: 0rem !important;
        /* height: 100vh; */
        margin: 3rem auto 50px;
        align-items: center;
        align-content: center;
    }

    .login-form {
        max-width: 70%;
        background: #fff;
        padding: 12px 10px;
        background: #FFFFFF;
        border-radius: 12px;
        margin: 10px auto 0;
    }

    .box {
        background: #111;
        border-color: #fff;
        border-radius: 0px 10px;
        -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
        box-shadow: 0 1px 10px 0 #2C3D4F;

        font-size: 1rem;

        width: 100%;

        color: white;
    }

    .menu-bars {
        background: url('/img/menu.png') center no-repeat !important;
        background-size: contain !important;
        width: 30px;
        height: 30px;
    }

    .basicBtn {
        background: #2C3D4F !important;
        border-color: #2C3D4F !important;
        border-radius: 0px 10px;
        -webkit-box-shadow: 0 1px 10px 0 #2C3D4F !important;
        -moz-box-shadow: 0 1px 10px 0 #2C3D4F !important;
        box-shadow: 0 1px 10px 0 #2C3D4F !important;
        width: 100%;
        font-weight: 700;
        color: #fff !important;
    }

    #projectContent .basicBtn {
        background: #EE574C !important;
        border-color: #EE574C !important;
        float: inherit;
        border-radius: 40px;
        -webkit-box-shadow: 0 1px 10px 0 #EE574C !important;
        -moz-box-shadow: 0 1px 10px 0 #EE574C !important;
        box-shadow: 0 1px 10px 0 #EE574C !important;
        width: 272px;
        padding: 10px 7px;
        color: #fff !important;
    }

    .fixed-top {
        width: calc(100% - 26px);
        top: 3rem !important;
    }

    i.hd-search-icon {
        width: 16px;
        height: 16px;
        background-size: 16px 16px;
        right: 0;
    }

    .logo-box {
        width: 25%;
    }

    .fixed-bottom {
        width: 100vw !important;
    }

    .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
        color: #2C3D4F !important;
        visibility: hidden;
    }

    .basicBtn:hover {
        background-color: #222 !important;
        color: #fff !important;
    }

    .chargeBtn {
        background: #2C3D4F;
        border-color: #2C3D4F;
        border-radius: 0px 10px;
        -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
        box-shadow: 0 1px 10px 0 #2C3D4F;

        color: white;
    }

    .chargeBtn:hover {
        background-color: #1C1A23;
        color: #2C3D4F;
    }

    .withdrawBtn {
        background: #2C3D4F;
        border-color: #2C3D4F;
        border-radius: 0px 10px;
        -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
        box-shadow: 0 1px 10px 0 #2C3D4F;

        color: white;
    }

    .withdrawBtn:hover {
        background-color: #1C1A23;
        color: #2C3D4F;
    }

    .mymCard_create {
        float: right;
        margin: auto;
        cursor: pointer;
        width: 50%;
        height: 45px;
        background: none;
        border: 2px solid #2C3D4F;
        -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
        box-shadow: 0 0px 10px 0 #2C3D4F;
        border-radius: 0px 10px;

        font-size: 1rem;
        text-align: center;
        color: #2C3D4F;
    }

    .overviewBtn {
        float: right;
        margin: auto;
        cursor: pointer;
        width: 50%;
        height: 45px;
        background: none;
        border: 2px solid #2C3D4F;
        -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
        box-shadow: 0 0px 10px 0 #2C3D4F;
        border-radius: 0px 10px;

        font-size: 1rem;
        text-align: center;
        color: #2C3D4F;
    }

    .uploadMymC {
        display: block;
        margin-top: 3rem;
        padding: 0px 1rem;
        cursor: pointer;
        height: 45px;
        width: 15rem;
        background: none;
        border: 2px solid #2C3D4F;
        -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
        box-shadow: 0 0px 10px 0 #2C3D4F;
        border-radius: 0px 10px;

        font-size: 1rem;
        text-align: center;
        color: #2C3D4F;
    }

    .buyTkt {
        display: block;
        margin-top: 5rem;
        padding: 0px 1rem;
        cursor: pointer;
        height: 45px;
        width: 15rem;
        background: none;
        border: 2px solid #2C3D4F;
        -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
        box-shadow: 0 0px 10px 0 #2C3D4F;
        border-radius: 0px 10px;

        font-size: 1rem;
        text-align: center;
        color: #2C3D4F;
    }

    .publishBtn {
        display: block;
        margin-top: 3rem;
        padding: 0px 1rem;
        cursor: pointer;
        height: 45px;
        width: 15rem;
        background: none;
        border: 2px solid #2C3D4F;
        -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
        box-shadow: 0 0px 10px 0 #2C3D4F;
        border-radius: 0px 10px;

        font-size: 1rem;
        text-align: center;
        color: #2C3D4F;
    }

    .mymCard_create:hover,
    .overviewBtn:hover,
    .uploadMymC:hover,
    .buyTkt:hover,
    .publishBtn:hover {
        background-color: #1C1A23;
        ;
        color: #fff;
    }

    #twoLevel_top {
        background: none;
        display: block;
        height: 35px;
        padding-bottom: 2rem;
        position: relative;
    }

    #twoLevel_left {
        float: left;
        width: 50%;
        background: none;
        margin-top: auto;
        position: relative;
        ;
    }

    #main #twoLevel_left .react-player {
        left: 7pt !important;
    }

    .twoLevel_right {
        float: right;
        width: 50% !important;
        background: none;
        position: relative;
    }

    #twoLevel_bottom {
        clear: both;
        background: none;
        display: block;
        /* padding-top: 2rem; */
        position: relative;
        display: inline-block;
        width: 100%;
        margin-top: 20pt;
    }

    #join #twoLevel_bottom {
        margin-top: 7pt;
    }

    #mymC_meme {
        width: 85% !important;
        height: 70% !important;
        top: 15% !important;
        left: 50% !important;
        transform: translate(-50%, -10%) !important;
        background-color: black !important;
        border-radius: 0px 10px !important;
    }

    .mymC_wrap {
        width: 90%;
        height: 14rem;
    }

    .mymC_memeName {
        padding: 1rem;
        width: 15rem;
        display: inline;

        line-height: 1rem;

        top: 33%;
        left: -15%;
    }

    .memeName_text p {
        font-size: 1.5rem;
    }

    h2 {
        color: #2C3D4F;
    }

    input {
        border-color: #ced4da;
    }

    button {
        background-color: #ee574c;
    }

    h4 {
        font-size: 2rem;
    }

    .stage {
        /* width: 15rem;
    height: 15rem; */
        position: relative;
        display: block;
        margin: 0;

        -webkit-perspective: 60rem;
        -ms-perspective: 60rem;
        -moz-perspective: 60rem;
        -o-perspecitive: 60rem;
        perspective: 60rem;
    }

    .cube {
        /* height: 15rem;
    width: 15rem; */
        position: relative;
        margin: 0;

        -webkit-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;
    }

    /* .cube div {
    height: 15rem;
    width: 15rem;
    position: absolute;
    color: white;
    text-align: center;
    line-height: 15rem;
} */

    .cube, .stage {
        /* width: 12rem;
    height: 12rem; */
        position: relative;
        margin: 0;
    }

    .side_top {
        background: #ffffff;
        top: -6.1rem;
        left: 0px;
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
        transform: rotateX(90deg);
    }

    .side_bottom {
        background: #ffffff;
        top: 6.1rem;
        left: 0px;
        -webkit-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
    }

    .side_front {
        background: #818181;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(0deg) translateZ(0rem);
        -ms-transform: rotateY(0deg) translateZ(0rem);
        -o-transform: rotateY(0deg) translateZ(0rem);
        transform: rotateY(0deg) translateZ(0rem);
    }

    .side_back {
        background: #818181;
        opacity: 100%;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(180deg) translateZ(0rem);
        -ms-transform: rotateY(180deg) translateZ(0rem);
        -o-transform: rotateY(180deg) translateZ(0rem);
        transform: rotateY(180deg) translateZ(0rem);
    }

    .side_right {
        background: #2C3D4F;
        top: 0px;
        left: 6.1rem;
        -webkit-transform: rotateY(90deg);
        -ms-transform: rotateY(90deg);
        -o-transform: rotateY(90deg);
        transform: rotateY(90deg);
    }

    .side_left {
        background: #2C3D4F;
        top: 0px;
        left: -6.1rem;
        -webkit-transform: rotateY(-90deg);
        -ms-transform: rotateY(-90deg);
        -o-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
    }

    /* .stage.rotate:hover .cube {
    -webkit-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg);
} */


    /* 사용자 상세 페이지 cube css 수정  */

    .toast {
        position: fixed;
        left: 50%;
        top: 30%;
        z-index: 10000;
        transform: translate(-50%, -40%);
        background: #000 !important;
        background-color: #000 !important;
        -webkit-box-shadow: 0 0px 10px 0 #2C3D4F !important;
        -moz-box-shadow: 0 0px 10px 0 #2C3D4F !important;
        box-shadow: 0 0px 10px 0 #2C3D4F !important;

    }

    .bigBox {
        margin: 15rem 0;
    }

    .hotMymCs.bigCube {
        height: 28rem;
    }

    .hotMymCs.bigCube .stage,
    .hotMymCs.bigCube .cube,
    .hotMymCs.bigCube .cube div {
        height: 27rem;
        width: 27rem;
    }

    .bigCube .side_top {
        background: #ffffff;
        top: -50%;
        left: 0px;
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
        transform: rotateX(90deg);
    }

    .bigCube .hotMymC-logo-box {
        left: 66%;
        top: 60%;
    }

    .bigCube .side_bottom {
        background: #ffffff;
        top: 50%;
        left: 0px;
        -webkit-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
    }

    .bigCube .side_front {
        background: #818181;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(0deg) translateZ(0rem);
        -ms-transform: rotateY(0deg) translateZ(0rem);
        -o-transform: rotateY(0deg) translateZ(0rem);
        transform: rotateY(0deg) translateZ(0rem);
    }

    .bigCube .side_back {
        background: #818181;
        opacity: 100%;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(180deg) translateZ(0rem);
        -ms-transform: rotateY(180deg) translateZ(0rem);
        -o-transform: rotateY(180deg) translateZ(0rem);
        transform: rotateY(180deg) translateZ(0rem);
    }

    .bigCube .side_right {
        background: #2C3D4F;
        top: 0px;
        left: 80%;
        -webkit-transform: rotateY(90deg);
        -ms-transform: rotateY(90deg);
        -o-transform: rotateY(90deg);
        transform: rotateY(90deg);
    }

    .bigCube .side_left {
        background: #2C3D4F;
        top: 0px;
        left: -20%;
        -webkit-transform: rotateY(-90deg);
        -ms-transform: rotateY(-90deg);
        -o-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
    }

    .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
        color: #2C3D4F !important;
        visibility: hidden;
    }

    .nav-link {
        text-decoration: none;
        color: white;
        text-align: left;
        margin-left: 10px;
    }

    .card-back>img {
        width: 100% !important;
        height: 12.5rem !important;
    }

    #freeCommunity .card-back>img.comm-img {
        left: 11pt;
        top: 15pt;
        border-radius: 0 10px 0 10px;
        height: 12.6rem !important;
        width: 83% !important;
    }

    #freeCommunity .card-back>.react-player {
        top: 5.6pt;
        left: 4pt;
        border-radius: 0 3px 0 3px;
        height: 4.5rem !important;
    }

    .hand-spock-count {
        bottom: -.3rem !important;
        left: 70% !important;
    }

    .hand-spock-count.like {
        bottom: 0.7rem !important;
        left: 70% !important;
    }

    .label-id {
        text-align: left;
        font-family: 'S-CoreDream-8';
        font-style: normal;
        font-weight: 800;
        font-size: 21px;

        letter-spacing: 0.1px;
    }

    .label-content {
        text-align: left;
        font-family: 'S-CoreDream-8';
        font-style: normal;
        font-weight: 800;
        font-size: 12pt;
        color: #fff;
        letter-spacing: 0.1px;
    }

    /* share btn */
    .btn_wrap {
        float: left;
        display: flex;
        overflow: hidden;
        cursor: pointer;
        width: 22%;
        height: 45px;
        background-color: none;
        -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
        box-shadow: 0 0px 10px 0 #2C3D4F;
        border-radius: 0px 10px;
        will-change: transform;
        -webkit-transition: all .25s ease-in-out;
        transition: all .25s ease-in-out;
    }

    .btn_wrap:hover {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }

    .btn_wrap span {
        position: absolute;
        z-index: 99;
        width: 100%;
        height: 45px;
        border-radius: 0px 10px;
        font-size: 1rem;
        text-align: center;
        line-height: 45px;
        color: #fff;
        font-family: 'S-CoreDream-8';
        font-weight: 800;
        background-color: #2C3D4F;
        -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
        box-shadow: 0 0px 10px 0 #2C3D4F;
        -webkit-transition: all .25s ease;
        transition: all .25s ease;
    }

    .btn_wrap>.container {
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        height: 45px;
        border-radius: 0px 10px;
    }

    .container i:nth-of-type(1) {
        -webkit-transition-delay: .9s;
        transition-delay: .9s;
    }

    .container i:nth-of-type(2) {
        -webkit-transition-delay: .7s;
        transition-delay: .7s;
    }

    .container i:nth-of-type(3) {
        -webkit-transition-delay: .4s;
        transition-delay: .4s;
    }

    .container i:nth-of-type(4) {
        -webkit-transition-delay: .1s;
        transition-delay: .1s;
    }

    .btn_wrap:hover span {
        -webkit-transition-delay: .25s;
        transition-delay: .25s;
        -webkit-transform: translateX(-18rem);
        transform: translateX(-18rem)
    }

    .btn_wrap:hover i {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    /* 탭 전체 스타일 */
    .tabs {
        background-color: none;
        width: 100%;
    }

    /* 탭 스타일 */
    .tab_item {
        height: 50px;
        padding: 0rem 1rem;
        margin: 0rem 2rem;
        border: 2px solid #2C3D4F;
        border-bottom: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
        box-shadow: 0 1px 10px 0 #2C3D4F;

        background-color: none;

        line-height: 50px;
        text-align: center;
        color: #2C3D4F;

        display: block;
        float: left;
        transition: all 0.2s ease;
    }


    #mymbID_edit {
        float: left;
        margin: auto;
        padding: 0px 1rem;
        margin-left: 2rem;
        cursor: pointer;
        height: 45px;
        line-height: 45px;
        background: none;
        border: 2px solid #2C3D4F;
        -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
        box-shadow: 0 0px 10px 0 #2C3D4F;
        border-radius: 0px 10px;

        font-size: 1rem;
        text-align: center;
        color: #2C3D4F;
    }

    input[type="text"]:disabled {
        background: none;
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        font-size: 1.2rem;
        padding: 0%;
    }

    input[type="password"]:disabled {
        background: none;
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        font-size: 1.2rem;
        padding: 0%;
    }

    select.box:disabled {
        background: none;
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .secured_edit, .btn {
        position: relative;
        padding: 3px 8px !important;
        font-family: "The Jamsil OTF 4 Medium" !important;
        font-style: normal !important;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 24px !important;

        text-align: center !important;

        color: #fff !important;

        background: #667080;
        border-radius: 8px !important;

        width: fit-content;
    }

    .secured:hover {
        background-color: #1C1A23;
        ;
    }

    .secured_save {
        float: right;
        cursor: pointer;
        width: 6rem;
        height: 35px;
        line-height: 35px;
        background: none;
        border: 2px solid #2C3D4F;
        margin-top: 1rem;
        -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
        box-shadow: 0 0px 10px 0 #2C3D4F;
        border-radius: 0px 10px;

        font-size: 1rem;
        text-align: center;
        color: #2C3D4F;
    }

    .secured:hover {
        background-color: #1C1A23;
        ;
    }

    #mymbID_edit_select {
        float: right;
        margin: auto;
        padding: 0px 1rem;
        margin-top: 1rem;
        cursor: pointer;
        height: 45px;
        line-height: 45px;
        background: none;
        border: 2px solid #2C3D4F;
        -webkit-box-shadow: 0 0px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 0px 10px 0 #2C3D4F;
        box-shadow: 0 0px 10px 0 #2C3D4F;
        border-radius: 0px 10px;

        font-size: 1rem;
        text-align: center;
        color: #2C3D4F;
    }

    #mymbID_edit:hover {
        background-color: #1C1A23;
        ;
        cursor: pointer;
    }

    #mymbID_edit_select:hover {
        background-color: #1C1A23;
        ;
        cursor: pointer;
    }

    .tab_item:hover {
        background-color: #1C1A23;
        ;
        cursor: pointer;
    }

    /* 라디오 버튼 UI삭제*/
    input[name="tab_item"] {
        display: none;
    }

    /* 탭 컨텐츠 스타일 */
    .tab_content {
        display: none;
        align-content: center;
        padding: 3rem 3rem 6rem 3rem;
        clear: both;
        height: auto;
        overflow: auto;
        border-radius: 20px;
    }

    #myIdContent .tab_content {
        padding: 1rem 1rem 3rem;
    }

    /* 선택 된 탭 콘텐츠를 표시 */

    #secured_content,
    #mymbID:checked~#mymbID_content,
    #secured:checked~#secured_content,
    #trustlessCube:checked~#trustlessCube_content {
        display: block;
    }

    /* 선택된 탭 스타일 */
    .tabs input:checked+.tab_item {
        background-color: none;
    }


    .menu-item,
    .menu-open-button {
        background: #FFFFFF;
        border-radius: 100%;
        width: 5rem;
        height: 5rem;
        margin-left: -2rem;
        position: absolute;
        color: #2C3D4F;
        text-align: center;
        line-height: 5rem;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition: -webkit-transform ease-out 200ms;
        transition: -webkit-transform ease-out 200ms;
        transition: transform ease-out 200ms;
        transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
    }

    .menu-open {
        display: none;
    }

    .menu {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 5rem;
        height: 5rem;
        text-align: center;
        box-sizing: border-box;
        font-size: 2rem;
    }

    /* .menu-item {
    transition: all 0.1s ease 0s;
} */

    .menu-item:hover {
        background: #EEEEEE;
        color: #fff;
    }

    .menu-item:nth-child(3) {
        -webkit-transition-duration: 180ms;
        transition-duration: 180ms;
    }

    .menu-item:nth-child(4) {
        -webkit-transition-duration: 180ms;
        transition-duration: 180ms;
    }

    .menu-item:nth-child(5) {
        -webkit-transition-duration: 180ms;
        transition-duration: 180ms;
    }

    .menu-open-button {
        z-index: 2;
        -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1ㅁ.275);
        transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -webkit-transition-duration: 400ms;
        transition-duration: 400ms;
        -webkit-transform: scale(1.3, 1.3) translate3d(0, 0, 0);
        transform: scale(1.3, 1.3) translate3d(0, 0, 0);
        cursor: pointer;
        -webkit-box-shadow: 0 1px 10px 0 #ddd;
        -moz-box-shadow: 0 1px 10px 0 #ddd;
        box-shadow: 0 1px 10px 0 #ddd;
    }

    .menu-open-button:hover {
        -webkit-transform: scale(1.4, 1.4) translate3d(0, 0, 0);
        transform: scale(1.4, 1.4) translate3d(0, 0, 0);
    }

    .menu-open:checked+.menu-open-button {
        -webkit-transition-timing-function: linear;
        transition-timing-function: linear;
        -webkit-transition-duration: 200ms;
        transition-duration: 200ms;
        -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
        transform: scale(0.8, 0.8) translate3d(0, 0, 0);
    }

    .menu-open:checked~.menu-item {
        -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
        transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
    }

    .menu-open:checked~.menu-item:nth-child(3) {
        transition-duration: 180ms;
        -webkit-transition-duration: 180ms;
        -webkit-transform: translate3d(-7.5rem, -4.3rem, 0);
        transform: translate3d(-7.5rem, -4.3rem, 0);
    }

    .menu-open:checked~.menu-item:nth-child(4) {
        transition-duration: 280ms;
        -webkit-transition-duration: 280ms;
        -webkit-transform: translate3d(0.07rem, -8.8rem, 0);
        transform: translate3d(0.07rem, -8.8rem, 0);
    }

    .menu-open:checked~.menu-item:nth-child(5) {
        transition-duration: 380ms;
        -webkit-transition-duration: 380ms;
        -webkit-transform: translate3d(7.5rem, -4.3rem, 0);
        transform: translate3d(7.5rem, -4.3rem, 0);
    }

    .poM, .mymbID, .wallet {
        -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
        box-shadow: 0 1px 10px 0 #2C3D4F;

        color: #2C3D4F;
        text-align: center;
        text-decoration: none;
        font-size: 1rem;
    }



    .comm-item,
    .comm-open-button {
        background: #FFFFFF;
        border-radius: 100%;
        width: 5rem;
        height: 5rem;
        margin-left: -3rem;
        position: absolute;
        color: #2C3D4F;
        text-align: center;
        line-height: 5rem;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition: -webkit-transform ease-out 200ms;
        transition: -webkit-transform ease-out 200ms;
        transition: transform ease-out 200ms;
        transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
    }

    .comm-open {
        display: none;
    }

    .comm {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 80%;
        right: 0;
        width: 5rem;
        height: 5rem;
        text-align: center;
        box-sizing: border-box;
        font-size: 1rem;
    }

    /* .menu-item {
    transition: all 0.1s ease 0s;
} */

    .comm-item:hover {
        background: #EEEEEE;
        color: #fff;
    }

    .comm-item:nth-child(3) {
        -webkit-transition-duration: 180ms;
        transition-duration: 180ms;
    }

    .comm-item:nth-child(4) {
        -webkit-transition-duration: 180ms;
        transition-duration: 180ms;
    }

    .comm-open-button {
        z-index: 2;
        -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -webkit-transition-duration: 400ms;
        transition-duration: 400ms;
        -webkit-transform: scale(1.3, 1.3) translate3d(0, 0, 0);
        transform: scale(1.3, 1.3) translate3d(0, 0, 0);
        cursor: pointer;
        -webkit-box-shadow: 0 1px 10px 0 #ddd;
        -moz-box-shadow: 0 1px 10px 0 #ddd;
        box-shadow: 0 1px 10px 0 #ddd;
    }

    .comm-open-button:hover {
        -webkit-transform: scale(1.4, 1.4) translate3d(0, 0, 0);
        transform: scale(1.4, 1.4) translate3d(0, 0, 0);
    }

    .comm-open:checked+.comm-open-button {
        -webkit-transition-timing-function: linear;
        transition-timing-function: linear;
        -webkit-transition-duration: 200ms;
        transition-duration: 200ms;
        -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
        transform: scale(0.8, 0.8) translate3d(0, 0, 0);
    }

    .comm-open:checked~.comm-item {
        -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
        transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
    }

    .comm-open:checked~.comm-item:nth-child(3) {
        transition-duration: 180ms;
        -webkit-transition-duration: 180ms;
        -webkit-transform: translate3d(-7.5rem, -4.3rem, 0);
        transform: translate3d(-7.5rem, -4.3rem, 0);
    }

    .comm-open:checked~.comm-item:nth-child(4) {
        transition-duration: 280ms;
        -webkit-transition-duration: 280ms;
        -webkit-transform: translate3d(0.07rem, -8.8rem, 0);
        transform: translate3d(0.07rem, -8.8rem, 0);
    }

    .frComm, .Hot {
        -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
        box-shadow: 0 1px 10px 0 #2C3D4F;

        color: #2C3D4F;
        text-align: center;
        text-decoration: none;
        font-size: 1rem;
    }


    .project-item,
    .project-open-button {
        background: #FFFFFF;
        border-radius: 100%;
        width: 5rem;
        height: 5rem;
        margin-left: -2rem;
        position: absolute;
        color: #2C3D4F;
        text-align: center;
        line-height: 5rem;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition: -webkit-transform ease-out 200ms;
        transition: -webkit-transform ease-out 200ms;
        transition: transform ease-out 200ms;
        transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
    }

    #join {
        font-size: 10pt !important;
    }

    .project-open {
        display: none;
    }

    .project {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0%;
        right: 80%;
        width: 5rem;
        height: 5rem;
        text-align: center;
        box-sizing: border-box;
        font-size: 1rem;
    }

    /* .menu-item {
    transition: all 0.1s ease 0s;
} */

    .project-item:hover {
        background: #EEEEEE;
        color: #fff;
    }

    .project-item:nth-child(3) {
        -webkit-transition-duration: 180ms;
        transition-duration: 180ms;
    }

    .project-open-button {
        z-index: 2;
        -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -webkit-transition-duration: 400ms;
        transition-duration: 400ms;
        -webkit-transform: scale(1.3, 1.3) translate3d(0, 0, 0);
        transform: scale(1.3, 1.3) translate3d(0, 0, 0);
        cursor: pointer;
        -webkit-box-shadow: 0 1px 10px 0 #ddd;
        -moz-box-shadow: 0 1px 10px 0 #ddd;
        box-shadow: 0 1px 10px 0 #ddd;
    }

    .project-open-button:hover {
        -webkit-transform: scale(1.4, 1.4) translate3d(0, 0, 0);
        transform: scale(1.4, 1.4) translate3d(0, 0, 0);
    }

    .project-open:checked+.project-open-button {
        -webkit-transition-timing-function: linear;
        transition-timing-function: linear;
        -webkit-transition-duration: 200ms;
        transition-duration: 200ms;
        -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
        transform: scale(0.8, 0.8) translate3d(0, 0, 0);
    }

    .project-open:checked~.project-item {
        -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
        transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
    }

    .project-open:checked~.project-item:nth-child(3) {
        transition-duration: 280ms;
        -webkit-transition-duration: 280ms;
        -webkit-transform: translate3d(0.07rem, -8.8rem, 0);
        transform: translate3d(0.07rem, -8.8rem, 0);
    }

    .project_soon {
        -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
        box-shadow: 0 1px 10px 0 #2C3D4F;

        color: #2C3D4F;
        text-align: center;
        text-decoration: none;
        font-size: 1rem;
    }


    #mymCwrapper {
        display: block;
        position: relative;
        width: 40rem;
    }

    .slider {
        overflow-x: scroll;
        white-space: nowrap;
    }

    .slider img {
        width: 15rem;
        border-radius: 0px 10px;
    }


    #slider2 {
        overflow-y: scroll;
        white-space: nowrap;
        width: 15rem;
        height: 22rem;
    }

    #slider2 img {
        position: relative;
        display: block;
        width: 15rem;
        border-radius: 0px 10px;
    }



    /* Track */
    ::-webkit-scrollbar-track {
        background: none;
        border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: none;
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: none;
    }

    #check-btn {
        display: none;
    }

    #check-btn:checked~.email-verificate {
        display: block;
    }

    #check-btn:checked~.email-verificate>input[name='code'] {
        margin: 2rem 0 !important;
        font-size: 1.25rem !important;
    }

    input {
        padding: 4pt 0;
    }

    .email-verificate {
        display: none;
    }




    /* wallet tabs */
    .wallet_tab {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .wallet_tab input[type="radio"] {
        display: none;
    }

    .wallet_tab label {
        margin: auto;
        height: 50px;
        width: calc(100%/3);
        border: 2px solid #2C3D4F;
        border-bottom: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
        box-shadow: 0 1px 10px 0 #2C3D4F;

        background-color: none;

        line-height: 50px;
        text-align: center;
        color: #2C3D4F;

        display: block;
        float: left;
        transition: all 0.2s ease;
    }

    .wallet_tab label:hover {
        background: #ffffff;
        cursor: pointer;
        color: black;
    }

    .wallet_tab_content {
        width: 100%;
        display: none;
        border-top: 1px solid #ffffff;
    }

    #poM:checked~#poM_content,
    #cash:checked~#cash_content,
    #nftCollection:checked~#nftCollection_content {
        display: flex;
    }

    .wallet_tab input:checked+label {
        background: #ffffff;
        color: black;
    }





    .code-editor {
        background-color: none;
        border-radius: 5px;
        box-shadow: 0 10px 6px -6px #2C3D4F;
        border: 1px solid #1B1C22;
        font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier;
        font-weight: bold;
        font-family: 'S-CoreDream-8';
        position: initial;
    }

    .code-editor>.log-tab label {
        margin: 0rem 0rem 2rem;
        height: 2rem;
        width: calc(100%/4);
        border: none;
        border-radius: 0px;
        box-shadow: none;

        background: none;

        line-height: 2rem;
        text-align: center;
        font-size: 1rem;
        color: #2C3D4F;

        display: block;
        float: left;
        transition: all 0.2s ease;
    }

    .code-editor>.log-tab>.log-tab input:checked+label {
        background: none;
        color: #3F4F5F;
    }

    .code-editor>.log-tab>.log-tab label:hover {
        cursor: pointer;
    }

    .code-editor>.log-tab>.tab-title {
        width: 100%;
        position: relative;
        padding: 5px 0 7px 5px;
        text-align: center;
        font-size: 1.5rem;
        color: #2C3D4F;

        margin: 2rem 0rem;
    }

    .code-editor>.log-tab>.log-tab>.tab-title {
        width: 100%;
        position: relative;
        margin: auto;
        text-align: center;
        font: 1rem;
        color: #2C3D4F;
    }

    .code-editor>.log-tab>.log-tab>.tab-title:hover {
        cursor: pointer;
        color: #3F4F5F;
    }


    .code-editor .log-content {
        padding: 1.5rem;
        font-size: .8rem;
        display: none;
        color: #2C3D4F;
    }

    .code-editor .lines>.line ::selection {
        background-color: #CCC;
    }

    .lines {
        width: 100%;
    }


    .log-content .lines>.line>span {
        white-space: normal;
        word-wrap: break-word;
    }

    .code-editor .lines .linenumber {
        color: #CCC;
        padding: 0 15px 0 0;
        font-weight: normal;
    }


    .log-tab input[type="radio"] {
        display: none;
    }

    #-myHistory:checked~#-myHistory_content,
    /*#-smartContract:checked ~ #-smartContract_content,*/
    #-myPost:checked~#-myPost_content,
    #-nftPurchase:checked~#-nftPurchase_content,
    #-etc:checked~#-etc_content {
        display: flex;
    }

    .pom-level {
        width: 100%;
        display: block;
        text-align: center;

        margin: 2rem 0rem;
    }

    .pom-level img {
        width: 13rem;
    }



    .meme-title {
        font-size: 1rem;
        font-family: 'S-CoreDream-8';
        font-weight: bold;
        text-decoration: none;
        z-index: 1;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity .5s;
        color: white;

        /* position the text in t’ middle*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .location-listing:hover .location-title {
        opacity: 1;
    }


    .location-listing img {
        width: 45%;
        height: auto;
    }


    .hidden {
        transition: all 200ms ease;
        opacity: 0;
        pointer-events: none;
    }

    .btn {
        padding: 8px !important;
        border-radius: 8px !important;

        font-family: "The Jamsil OTF 4 Medium" !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 24px !important;

        text-align: center !important;

    }

    .carousel {
        width: 100%;
        height: 35rem;
    }

    .carousel .btn {
        position: absolute;
        height: calc(100% - 15%);
        width: calc(100% - 90%);
        margin: 3rem 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 400ms ease;
        overflow: hidden;
    }

    .carousel .btn:before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all 300ms ease;
    }

    .carousel .btn i {
        font-size: 1.6rem;
        color: #2C3D4F;
        transition: all 400ms ease;
        opacity: 0;
    }

    .carousel .btn-back {
        top: -3.5rem;
        left: 8%;
    }

    .carousel .btn-back:before {
        border-radius: 100% 0 0 100%;
        transform: translateX(10rem);
    }

    .carousel .btn-back .fa-arrow-left {
        transform: translateX(10rem);
    }

    .carousel .btn-next {
        top: -3.5rem;
        right: 9%;
    }

    .carousel .btn-next:before {
        border-radius: 0 100% 100% 0;
        transform: translate(-10rem);
    }

    .carousel .btn-next .fa-arrow-right {
        transform: translateX(-10rem);
    }

    .carousel .btn .right-indicator,
    .carousel .btn .left-indicator {
        opacity: 1;
        color: #3F4F5F;
    }

    .carousel .btn .right-indicator {
        transform: translateX(-.5rem);
    }

    .carousel .btn .left-indicator {
        transform: translateX(-.5rem);
    }

    .carousel .btn:hover:before {
        border-radius: 0;
        background: none;
        transform: translateX(0);
    }

    .carousel .btn:hover .fa-arrow-left,
    .carousel .btn:hover .fa-arrow-right {
        opacity: 1;
    }

    .carousel .btn:hover .fa-arrow-left {
        transform: translateX(.8rem);
    }

    .carousel .btn:hover .fa-arrow-right {
        transform: translateX(.8rem);
    }

    .carousel .btn:hover .right-indicator {
        transform: translateX(9rem);
        opacity: 0;
    }

    .carousel .btn:hover .left-indicator {
        transform: translateX(-9rem);
        opacity: 0;
    }

    .carousel .viewbox {
        width: 70%;
        height: 70%;
        position: relative;
        margin: 25px auto;
        z-index: 2;
        overflow: hidden;
        background: none;

    }

    .carousel .viewbox .track {
        width: 100%;
        height: 100%;
        position: relative;
        transition: all 500ms ease;
        z-index: 2;

    }

    .carousel .viewbox .track .slide {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .carousel .viewbox .track .slide .images {
        width: 100%;
        height: 100%;
        position: relative;
        object-fit: contain;
    }


    .carousel .nav-indicator {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
    }

    .carousel .nav-indicator .dot,
    .swiper-pagination-bullet {
        margin: 0 5px !important;
        width: .5rem !important;
        height: .5rem !important;
        border-radius: 50% !important;
        background: #ffffff !important;
        cursor: pointer !important;
        transition: all 300ms ease !important;
    }

    .carousel .nav-indicator .dot.active,
    .swiper-pagination-bullet-active {
        background: #fff !important;
        -webkit-box-shadow: 0 0px 10px 0 #fff !important;
        -moz-box-shadow: 0 0px 10px 0 #fff !important;
        box-shadow: 0 0px 10px 0 #fff !important;
    }

    .hotMymC_1 {
        width: 100%;
        margin: auto;
        overflow: hidden;
    }

    .hotMymC_1 .mymC_wrap {
        width: 90%;
        height: 9rem;
    }

    .hotMymC_1 .mymC_memeName {
        padding: 0rem;
        width: 10rem;
        max-height: 1rem;
        display: inline;
        text-align: left;

        line-height: 1rem;

        top: 40%;
        left: -.5rem;
    }

    .hotMymC_1 .memeName_text p {
        font-size: .5rem;
    }



    .hotMymC_2 {
        width: 100%;
        margin-top: 2rem;
        overflow: hidden;
    }

    .hotMymC_2 .mymC_wrap {
        width: 90%;
        height: 9rem;
    }

    .hotMymC_2 .mymC_memeName {
        padding: 0rem;
        width: 10rem;
        max-height: 1rem;
        display: inline;
        text-align: left;
        height: 1rem;

        line-height: 1rem;

        top: 40%;
        left: -.1%;
    }

    .hotMymC_2 .memeName_text p {
        font-size: .5rem;
    }


    .hotMymCs {
        width: 100%;
        height: 15rem;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    #main .hotMymCs {
        height: 12rem !important;
    }

    .hotMymC_1 .hotMymCs>.stage>.cube div {
        height: 8rem;
        width: 8rem;
        position: absolute;
        color: white;
        text-align: center;
        line-height: 10rem;
    }

    .hotMymC_2>.hotMymCs>.stage>.cube div {
        height: 8rem;
        width: 8rem;
        position: absolute;
        color: white;
        text-align: center;
        line-height: 8rem;
    }


    .hotMymC_1 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: .5rem !important;
        grid-gap: .5rem !important;
        grid-auto-flow: row dense;
    }

    .hotMymC_2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: .5rem;
        grid-auto-flow: row dense;
    }


    .hotMymC_1 .hotMymCs>.stage>.cube .side_top {
        background: #ffffff;
        top: -5rem;
        left: 0pt;
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
        transform: rotateX(90deg);
    }

    .hotMymC_1 .hotMymCs>.stage>.cube .side_bottom {
        background: #ffffff;
        top: 5rem;
        left: 0pt;
        -webkit-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
    }

    .hotMymC_1 .hotMymCs>.stage>.cube .side_front {
        background: #818181;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(0deg) translateZ(0rem);
        -ms-transform: rotateY(0deg) translateZ(0rem);
        -o-transform: rotateY(0deg) translateZ(0rem);
        transform: rotateY(0deg) translateZ(0rem);
    }

    .hotMymC_1 .hotMymCs>.stage>.cube .side_back {
        background: #818181;
        opacity: 100%;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(180deg) translateZ(0rem);
        -ms-transform: rotateY(180deg) translateZ(0rem);
        -o-transform: rotateY(180deg) translateZ(0rem);
        transform: rotateY(180deg) translateZ(0rem);
    }

    .hotMymC_1 .hotMymCs>.stage>.cube .side_right {
        background: #2C3D4F;
        top: 0pt;
        left: 5rem;
        transform: rotateY(90deg) !important;
        -webkit-transform: rotateY(90deg);
        -ms-transform: rotateY(90deg);
        -o-transform: rotateY(90deg);
    }

    .hotMymC_1 .hotMymCs>.stage>.cube .side_left {
        background: #2C3D4F;
        top: 0pt;
        left: -5rem;
        -webkit-transform: rotateY(-90deg);
        -ms-transform: rotateY(-90deg);
        -o-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
    }

    .hotMymC_2>.hotMymCs>.stage {
        width: 10rem;
        height: 10rem;
    }

    .hotMymC_2>.hotMymCs>.stage>.cube {
        width: 10rem;
        height: 10rem;
    }


    #freeCommunity .hotMymC_2>.hotMymCs>.stage {
        width: 6rem;
        height: 6rem;
        margin: 0 auto !important;
    }

    #freeCommunity .hotMymC_2>.hotMymCs>.stage>.cube {
        width: 6rem;
        height: 6rem;
        margin: 0 auto !important;
    }

    #userProfile .card-back>img {
        height: 6.2rem !important;
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_top {
        background: #ffffff;
        top: -4rem;
        left: 0pt;
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
        transform: rotateX(90deg);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_bottom {
        background: #ffffff;
        top: 4rem;
        left: 0pt;
        -webkit-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_front {
        background: #818181;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(0deg) translateZ(0rem);
        -ms-transform: rotateY(0deg) translateZ(0rem);
        -o-transform: rotateY(0deg) translateZ(0rem);
        transform: rotateY(0deg) translateZ(0rem);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_back {
        background: #818181;
        opacity: 100%;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(180deg) translateZ(0rem);
        -ms-transform: rotateY(180deg) translateZ(0rem);
        -o-transform: rotateY(180deg) translateZ(0rem);
        transform: rotateY(180deg) translateZ(0rem);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_right {
        background: #2C3D4F;
        top: 0pt;
        left: 4rem;
        transform: rotateY(90deg) !important;
        -webkit-transform: rotateY(90deg);
        -ms-transform: rotateY(90deg);
        -o-transform: rotateY(90deg);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_left {
        background: #2C3D4F;
        top: 0pt;
        left: -4rem;
        -webkit-transform: rotateY(-90deg);
        -ms-transform: rotateY(-90deg);
        -o-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
    }



    #wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* Accordion Container */
    #wrapper .container {
        width: 98%;
        position: relative;
        height: auto;

    }

    #wrapper .container:before {
        content: '';
        position: absolute;
        width: calc(100% - 30px);
        height: 100%;
        margin: 0;
        left: 15px;
        background-color: transparent;
        top: 0px;
        box-shadow: 0px 20px 40px #52616b;
        opacity: 0.2;
        z-index: -1;
        transition: ease-in-out 0.6s all;
    }

    #wrapper .container:hover.container:before {
        box-shadow: 0px 40px 90px #52616b;
    }


    #wrapper ul {
        list-style: none;
        margin: 0;
        padding: 0;
        background-color: none;
        border-radius: 10px;
        border: 2px solid #2C3D4F;
    }

    #wrapper .accordionTitle {
        padding: 20px;
        position: relative;
        margin: 0;
        font-size: 1rem;
        font-family: "The Jamsil OTF 4 Medium";
        font-weight: 500;
        letter-spacing: 0.8px;
        color: #3F4F5F;
        transition: ease-in-out 0.2s all;
        cursor: pointer;
    }

    #wrapper .accordionTitle:hover {
        padding-left: 25px;
    }

    /* Accordion Item line */
    #wrapper .accordionTitle:before,
    #wrapper .accordionTitle:after {
        content: "";
        position: absolute;
        height: 2px;
        border-radius: 50px;
        transition: ease-in-out 0.6s all;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #wrapper .accordionTitle:before {
        width: 100%;
        background-color: #1C1A23;
        ;
    }

    #wrapper .accordionTitle:after {
        background-image: linear-gradient(90deg, #000, #ffffff);
        width: 0%;
    }

    #wrapper .accordionTitle:hover::after {
        width: 100%;
    }

    /* Accordion Item line - Active */
    #wrapper .accordionTitleActive:after {
        content: "";
        position: absolute;
        height: 2px;
        border-radius: 50px;
        transition: ease-in-out 0.6s all;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #wrapper .accordionTitleActive:after {
        background-image: linear-gradient(90deg, #52616b, #ffffff);
        width: 100%;
    }

    /* Accordion Item Icon  */
    #wrapper .accIcon {
        float: right;
        width: 2rem;
        height: 2rem;
        display: flex;
        margin-top: -.4rem;
        align-items: center;
        justify-content: center;
    }

    #wrapper .accIcon:before,
    #wrapper .accIcon:after {
        content: "";
        position: absolute;
        border-radius: 50px;
        background-color: #1C1A23;
        ;
        transition: ease 0.3s all;
    }

    #wrapper .accIcon:before {
        width: .2rem;
        height: 2rem;
    }

    #wrapper .accIcon:after {
        width: 2rem;
        height: .2rem;
    }

    #wrapper .accordionTitle:hover .accIcon:before,
    #wrapper .accordionTitle:hover .accIcon:after {
        background-color: #1C1A23;
        ;
    }

    #wrapper .accIcon.anime.accIcon:before {
        transform: rotate(90deg);
    }

    /* Text Content */
    #wrapper .accordion .item .text {
        font-family: 'The Jamsil OTF 3 Regular';
        opacity: 0;
        height: 0;
        padding: 0px 20px;
        position: relative;
        line-height: 24px;
        font-size: 1rem;
        font-weight: 300;
        transition: all 0.6s cubic-bezier(0.42, 0.2, 0.08, 1);
        overflow: hidden;
        letter-spacing: 0.5px;
    }

    /* Text Content - Class for JS to hide and show */
    #wrapper .accordion .item .text.show {
        opacity: 1;
        height: auto;
        padding: 25px 20px;
        position: relative;
        z-index: 0;
        border-radius: 0px 0px 3px 3px;
    }









    * {
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .accordion p {
        font-size: 14px;
    }

    .accordion ul {
        list-style: none;
    }

    .accordion li {
        list-style: none;
        position: relative;
    }

    .accordion a {
        color: #2C3D4F;
        text-decoration: none;
    }

    .accordion {
        width: 100%;
        padding: 0;
        margin-top: 1rem;
        /* max-width: 80rem; */
        background: none;
    }

    .accordion .link,
    #accordion .hotMymC_2 label {
        cursor: pointer;
        display: block;
        /* padding: 1rem .5rem; */
        color: #fff;
        font-size: 18px;
        font-weight: 800;
        border-bottom: 1px solid #010201;
        position: relative;
        transition: all .4s ease;
        left: 0;
    }

    #accordion .hotMymC_2 label {
        border-bottom: none;
    }

    .accordion li:last-child .link {
        border-bottom: 0;
    }

    .accordion li i {
        position: absolute;
        font-size: 20px;
        color: #3F4F5F;
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        right: 1rem;
    }


    .accordion li i.fa-circle-play {
        right: auto;
        left: 7%;
        font-size: 18pt;
        bottom: 19.3rem;
    }

    .accordion li.open .link {
        color: #9EEF20;
    }

    .accordion li.open i.fa-circle-play {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .submenu {
        display: none;
        /* background: #1C1A23;; */
        font-size: 1rem;
        position: relative;
        left: -1rem;

    }

    #freeCommunity .accordion li i.fa-circle-play {
        bottom: 16.5rem;
    }

    .submenu li {
        border-bottom: 2px solid #2C3D4F;
        position: relative;
        padding-left: .5rem;
        padding-bottom: 1rem;
        left: -1.25rem;
    }

    .submenu a {
        display: block;
        text-decoration: none;
        padding-top: 1rem;
        padding-bottom: 1rem;
        color: #3F4F5F;
        padding-left: 0rem;
        -webkit-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
    }

    .fa-heart {
        color: #2C3D4F;
    }

    .fa-heart:hover {
        cursor: pointer;
        color: #2C3D4F;
        transform: scale(1.2);
    }

    .fa-eye::after {
        content: "  ";
        text-shadow: none;
        color: #fff;
    }

    .fa-heart::after {
        content: "  ";
        text-shadow: none;
        color: #fff;
    }

    .fa-hand-spock:hover {
        cursor: pointer;
        color: #2C3D4F;
        transform: scale(1.2);
    }

    .fa-hand-spock::after {
        content: "  ";
        text-shadow: none;
        color: #fff;
    }

    /*----- 검색창 ------*/
    .search-wrapper {
        position: relative;
        transform: translate(-50%, -50%);
        top: 10%;
        left: 50%;
    }

    .search-wrapper .input-holder {
        height: 3rem;
        width: 3rem;
        overflow: hidden;
        background: rgba(255, 255, 255, 0);
        border-radius: 6px;
        position: relative;
        transition: all 0.3s ease-in-out;
    }

    .search-wrapper.active .input-holder {
        width: 20rem;
        border-radius: 10px;
        background: none;
        border: 2px solid #2C3D4F;
        color: #3F4F5F;
        transition: all .5s cubic-bezier(0.000, 0.105, 0.035, 1.570);
    }

    .search-wrapper .input-holder .search-input {
        width: 100%;
        height: 3rem;
        padding: 0px 5rem 0 2rem;
        opacity: 0;
        background: none;
        position: absolute;
        transform: translate(0, 4rem);
        transition: all .3s cubic-bezier(0.000, 0.105, 0.035, 1.570);
        transition-delay: 0.3s;
    }

    .search-wrapper.active .input-holder .search-input {
        opacity: 1;
        top: 0px;
        left: 0px;
        box-sizing: border-box;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.2rem;
        color: #fff;
        transform: translate(0);
        border: none;
    }

    .search-wrapper .input-holder .search-icon {
        width: 3rem;
        height: 3rem;
        border: none;
        border-radius: 6px;
        background: #111;
        padding: 1px;
        outline: none;
        position: relative;
        z-index: 2;
        float: right;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .search-wrapper.active .input-holder .search-icon {
        width: 3rem;
        height: 3rem;
        border-radius: 10px;
    }

    .search-wrapper .input-holder .search-icon span {
        width: 3rem;
        height: 3rem;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        transform: rotate(45deg);
        transition: all .4s cubic-bezier(0.650, -0.600, 0.240, 1.650);
    }

    .search-wrapper.active .input-holder .search-icon span {
        transform: rotate(-45deg);
    }

    .search-wrapper .input-holder .search-icon span::before,
    .search-wrapper .input-holder .search-icon span::after {
        position: absolute;
        content: '';
    }

    .search-wrapper .input-holder .search-icon span::before {
        width: .3rem;
        height: 1rem;
        left: 1.3rem;
        top: 1.6rem;
        border-radius: 2px;
        background: #2C3D4F;
    }

    .search-wrapper .input-holder .search-icon span::after {
        width: 1.3rem;
        height: 1.3rem;
        left: .8rem;
        top: .5rem;
        border-radius: 16px;
        border: 4px solid #2C3D4F;
    }

    .search-wrapper .close {
        position: absolute;
        z-index: 1;
        top: .6rem;
        right: 90%;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        transform: rotate(-180deg);
        transition: all .3s cubic-bezier(0.285, -0.450, 0.935, 0.110);
        transition-delay: 0.2s;
    }

    .search-wrapper.active .close {
        right: 0%;
        transform: rotate(45deg);
        transition: all .6s cubic-bezier(0.000, 0.105, 0.035, 1.570);
        transition-delay: 0.5s;
    }

    .search-wrapper .close::before, .search-wrapper .close::after {
        position: absolute;
        content: '';
        background: #2C3D4F;
        border-radius: 2px;
    }

    .search-wrapper .close::before {
        width: .5rem;
        height: 2rem;
        left: .7rem;
        top: 0px;
    }

    .search-wrapper .close::after {
        width: 2rem;
        height: .5rem;
        left: 0px;
        top: .7rem;
    }

    .search-wrapper input::placeholder {
        color: #3F4F5F;
    }



    .btn-container {
        position: relative;
        transform: translate(15%, -50%);
        top: 10%;
        left: 50%;
    }

    .btn-container button {
        width: 8rem;
        height: 3rem;
        border: 0;
        border-radius: 6px;
        position: relative;
        color: #2C3D4F;
        font-family: 'S-CoreDream-8';
        font-weight: bold;
        background: #111;
        cursor: pointer;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn-container button .text,
    .btn-container button .icon-container {
        position: relative;
        z-index: 2;
    }

    .btn-container button .icon-container {
        position: relative;
        width: var(--icon-size);
        height: var(--icon-size);
        margin-left: 1.3rem;
        transition: transform 500ms ease;
    }

    .btn-container button .icon-container .icon {
        position: absolute;
        left: 0;
        top: 0;
        width: var(--icon-size);
        height: var(--icon-size);
        transition: transform 500ms ease, opacity 250ms ease;
    }

    .btn-container button::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--btn-bg);
        border-radius: var(--height);
        z-index: 1;
        transition: transform 500ms ease;
    }

    .btn-container button:hover::after {
        transform: translateX(65%);
    }

    .btn-container button:hover .icon-container {
        transform: translateX(125%);
    }

    .postWrap {
        max-width: 50rem;
        padding: 1rem;

        margin: 0px auto;
        align-items: center;
        align-content: center;
        border: none !important;
    }

    .postWrap input {
        margin: 1rem 0;
    }

    .postWrap textarea {
        background: #111;
        border: none;
        border-radius: 0px 10px;
        -webkit-box-shadow: 0 1px 10px 0 #2C3D4F;
        -moz-box-shadow: 0 1px 10px 0 #2C3D4F;
        box-shadow: 0 1px 10px 0 #2C3D4F;

        color: #3F4F5F;
        padding: 1rem;
        margin: 1rem auto;

        width: 100%;
    }

    .postWrap .box {
        width: 100%;
    }

    .postWrap .btnsWrap {
        max-width: 50rem;
        text-align: center;
    }

    .postWrap .basicBtn {
        max-width: calc(50%/3);
        margin: 1rem auto;
    }

    .neonHr {
        border: 0.2rem solid #2C3D4F;
        height: 0.5rem;
        color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 5px #2C3D4F;
        padding: 0;
        width: 90.5vw;
        animation: glow 1.5s ease-in-out infinite;
        -webkit-animation: glow 1.5s ease-in-out infinite;
        opacity: 1;
    }

    .neonHr::before {
        content: '';
        position: absolute;
        border: 0.1rem solid #fff;
        width: 90.5vw;
        margin: 0 auto;
        left: 18px;
        border-radius: 2px;
        box-shadow: 0 0 3px #ffffff;
        animation: glowMinor 2s ease-in-out;
        -webkit-animation: glowMinor 2s ease-in-out infinite
    }

    @keyframes glow {
        0% {
            box-shadow: 0 0 2px #2C3D4F;
        }

        50% {
            box-shadow: 0 0 8px #2C3D4F;
        }

        100% {
            box-shadow: 0 0 2px #2C3D4F;
        }
    }

    @-webkit-keyframes glow {
        0% {
            box-shadow: 0 0 2px #2C3D4F;
        }

        50% {
            box-shadow: 0 0 8px #2C3D4F;
        }

        100% {
            box-shadow: 0 0 2px #2C3D4F;
        }
    }

    @keyframes glowMinor {
        0% {
            box-shadow: 0 0 2px #fff;
        }

        50% {
            box-shadow: 0 0 8px #fff;
        }

        100% {
            box-shadow: 0 0 2px #fff;
        }
    }

    @-webkit-keyframes glowMinor {
        0% {
            box-shadow: 0 0 2px #fff;
        }

        50% {
            box-shadow: 0 0 8px #fff;
        }

        100% {
            box-shadow: 0 0 2px #fff;
        }
    }

    .modal {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        display: none;

        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal.show {
        display: block;
    }

    .modal_body {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 70%;
        height: fit-content;
        padding: 30px 23px 50px;
        text-align: center;
        background-color: #1C1A23;
        opacity: 1;
        border-radius: 15px;
        color: #fff;
        overflow: scroll;
        transform: translateX(-50%) translateY(-50%);
    }

    .modal_body p.title {
        font-family: "The Jamsil OTF 4 Medium";

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */
        font-feature-settings: 'calt'off;
        text-align: center;
        color: #fff;
        ;

        width: 100%;
    }

    .modal_body p.content {
        font-family: "The Jamsil OTF 4 Medium";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */
        font-feature-settings: 'calt'off;
        text-align: center;
        color: #fff;
        ;
        margin-top: 6px;
        width: 100%;
    }

    .modal_body table,
    .modal_body th .modal_body td {
        border: 1px solid white;
        padding: .3rem;
    }

    .outer {
        position: fixed;
        top: 3rem;
        left: 3rem;

        width: 2rem;
        cursor: pointer;
    }

    .inner {
        width: inherit;
        text-align: center;
    }

    .inner:before, .inner:after {
        position: absolute;
        content: '';
        height: 1px;
        width: inherit;
        background: #2C3D4F;
        left: 0;
        transition: all .3s ease-in;
    }

    .inner:before {
        top: 50%;
        transform: rotate(45deg);
    }

    .inner:after {
        bottom: 50%;
        transform: rotate(-45deg);
    }

    .outer:hover label {
        opacity: 1;
    }

    .outer:hover .inner:before,
    .outer:hover .inner:after {
        transform: rotate(0);
    }

    .outer:hover .inner:before {
        top: 0;
    }

    .outer:hover .inner:after {
        bottom: 0;
    }

    .collectionWrap {
        width: 100%;
        height: 30rem;
        margin: 15pt auto;
        overflow-y: scroll;

        justify-content: right;
    }

    .collection {
        background: #818181;
        width: 13.5rem;
        height: 13.5rem;
        margin: 1.3rem;
        box-shadow: .2rem .2rem .2rem #fff;

        float: left;

        -webkit-transition: -webkit-transform 200ms;
        transition: -webkit-transform 200ms;
        transition: transform 200ms;
        transition: transform 200ms, -webkit-transform 200ms;
    }

    .collection:hover {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        cursor: pointer;
    }

    .collection .mymC_wrap {
        width: 100%;
        height: 11rem;
        background-size: cover;
    }

    .collection .mymC_wrap img {
        width: 85%;
        height: 70%;
        transform: translate(8%, 8%);
        background-color: black;
        border-radius: 0px 10px;
    }

    /* 김주연 작업 -- 0914 ~ */

    .mymC_wrap img {
        width: auto !important;
        max-height: .6rem;
        padding: 0rem;
    }

    .mymC_wrap .logo-box {
        text-align: right;
        top: 38%;
        right: 5%;
        height: 10%;
    }

    .collection .mymC_wrap .collection-logo-box {
        text-align: right;
        margin-top: .7rem;
    }

    .collection .mymC_wrap .collection-logo-box img {
        width: 1.5rem;
        padding: 0rem;
        background: none;
        border-radius: 0px;
    }

    .collection .mymC_wrap .mymC_memeName .name-tag {
        float: left;
        font-size: .5rem;
        margin-top: .7rem;
        width: 4rem;
    }

    #wallet-container .input-form.row {
        overflow: visible;
        margin-top: 0rem;
        padding-top: 2rem;
        align-items: initial;
        margin: 0 auto !important;
    }

    #poM_content .input-form.row {
        overflow: visible;
        margin-top: 0rem;
        padding-top: 2rem;
        align-items: center;
        margin: 0 auto !important;
    }

    #poM_content .input-form.row .text-center h3.py-3 {
        font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier !important;
        color: #2C3D4F !important;
    }

    #poM_content .pom-level {
        position: relative;
        z-index: 50;
    }

    #poM_content .pom-level>img {
        width: 17rem;
        z-index: 50;
    }


    #poM_content .pom-level-1 {
        position: absolute;
        top: 3rem;
        left: 60%;
        z-index: 49;
    }

    #poM_content .pom-level-1>img {
        opacity: 30%;
    }

    #poM_content .pom-level-2 {
        position: absolute;
        top: 5rem;
        left: 70%;
        z-index: 48;
    }

    #poM_content .pom-level-2>img {
        width: 10rem;
        opacity: 20%;
    }

    #poM_content .pom-level-3 {
        position: absolute;
        top: 7rem;
        left: 80%;
        z-index: 47;
    }

    #poM_content .pom-level-3>img {
        width: 7rem;
        opacity: 10%;
    }

    #twoLevel_top {
        margin-Top: 3rem
    }

    #twoLevel_top>img {
        margin-left: 1rem;
        width: auto;
    }

    #accordion .hotMymC_2 {
        margin-bottom: 0;
        display: flex !important;
    }

    /* .bg-dark {
    background: #101111 !important;
} */

    .overviewBtn {
        width: 100%;
        margin-top: 2rem
    }

    .swiper-wrapper img {
        width: 100% !important;
    }

    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: -3pt !important;
    }

    #logo_mymbID {
        margin-left: 1rem;
    }

    .l60-t6-pr {
        left: 55%;
        top: 7%;
    }

    /* .swiper-button-next:after{
    content: "\f054" !important;
} 

.swiper-button-prev:after{
    content: "\f053" !important;
} */

    .btn.buyTkt {
        width: 30%;
        margin: 0px;
        margin-left: 5%;
        color: #2C3D4F;
    }

    .current.ticketBtn {
        font-size: 1.5rem;
        border-bottom: 1px solid #939598;
        width: 50%;
        height: fit-content;
        text-align: center;
    }

    .current-cash {
        font-size: 1.5rem;
        border-bottom: 1px solid #939598;
        height: fit-content;
        text-align: center;
    }

    #twoLevel_top h3.py-3 {
        text-align: left;
        width: 100%;
        justify-self: baseline;
    }

    .mt-10 {
        margin-top: 10rem !important;
    }

    .mt-5 {
        margin-top: 5rem !important;
    }

    .mt-3 {
        margin-top: 3rem !important;
    }

    .mt-2 {
        margin-top: 2rem !important;
    }

    .mt-1 {
        margin-top: 1rem !important;
    }

    .mb-3 {
        margin-bottom: 3rem !important;
    }

    .mb-2 {
        margin-bottom: 2rem !important;
    }

    .mb-1 {
        margin-bottom: 1rem !important;
    }

    .ml-3 {
        margin-left: 3rem !important;
    }

    .ml-2 {
        margin-left: 2rem !important;
    }

    .ml-1 {
        margin-left: 1rem !important;
    }

    .mr-3 {
        margin-right: 3rem !important;
    }

    .mr-2 {
        margin-right: 2rem !important;
    }

    .mr-1 {
        margin-right: 1rem !important;
    }

    .m-0 {
        margin: 0 !important;
    }

    .pt-3 {
        margin-top: 3rem !important;
    }

    .pt-2 {
        margin-top: 2rem !important;
    }

    .pt-1 {
        margin-top: 1rem !important;
    }

    .pb-3 {
        margin-bottom: 3rem !important;
    }

    .pb-2 {
        margin-bottom: 2rem !important;
    }

    .pb-1 {
        margin-bottom: 1rem !important;
    }

    .pl-3 {
        margin-left: 3rem !important;
    }

    .pl-2 {
        margin-left: 2rem !important;
    }

    .pl-1 {
        margin-left: 1rem !important;
    }

    .pr-3 {
        margin-right: 3rem !important;
    }

    .pr-2 {
        margin-right: 2rem !important;
    }

    .pr-1 {
        margin-right: 1rem !important;
    }

    .p-0 {
        padding: 0 !important;
    }



    .btn_wrap.share {
        width: 50%;
        float: right;
        margin-top: 1rem;
        margin-right: .5rem;
    }

    #twoLevel_bottom h5.py-3,
    #twoLevel_left h5.py-3 {
        text-align: left;
        width: 15%;
        justify-self: baseline;
    }

    #nftCollection_content .input-form>.input-form {
        margin-top: 1rem;
        /* border: 1px solid #fff; */
        padding: 1rem;
        border-radius: 10px;
        height: 24rem;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    #nftCollection_content .input-form>.input-form:last-child {
        border: none;
    }

    #nftCollection_content #twoLevel_left {
        margin: 0 !important;
    }

    #nftCollection_content .twoLevel_right {
        float: right;
        padding-left: .5rem;
        margin: 0;
    }

    #nftCollection_content .twoLevel_right .container {}

    #nftCollection_content .mymC-logo-box {
        text-align: right;
        left: 20%;
        top: 6%;
    }

    #nftCollection_content .mymC-logo-box>img {
        max-height: 1rem;
    }

    #wallet-container .mymCard_create {
        margin-top: 6rem;
        width: 15rem;
        right: 0;
        display: block;
        position: absolute;
    }

    #wallet-container .btn_wrap.share {
        width: 15rem;
        margin-left: 1rem !important;
    }

    .t-1 {
        top: 1rem !important;
    }

    .l-1 {
        left: 1rem !important;
    }

    .text-center {
        text-align: center !important;
    }

    .l20-t6-pr {
        left: 20%;
        top: 6%
    }

    #currentTicket {
        font-size: 1.5rem;
        border-bottom: 1px solid #939598;
        width: 50%;
        height: fit-content;
        text-align: center;
    }

    .container {
        margin: 38pt 0;
        padding: 0 !important;
        overflow-y: scroll;
    }

    #main>content>div.input-form {
        overflow-y: scroll !important;
    }

    #main #twoLevel_left {
        display: inline-block;
        width: 50% !important;
    }

    #main #refcode {
        border: none;
        background: none;
        color: white;
        width: 18rem;
        cursor: default;
    }

    #trustlessCube_content {}


    .hotMymC-logo-box {
        text-align: right;
        top: 38%;
        right: -33%;
        height: 10%;
    }

    #mymbID_content {
        height: max-content;
    }

    #freeCommunity .hotMymC_2 {
        display: block !important;
        height: auto !important;
    }

    #freeCommunity .hotMymCs .fa-regular.fa-heart {
        left: 60% !important;
    }

    #freeCommunity .hotMymCs .fa-regular.fa-hand-spock {
        left: 60% !important;
        margin: 0 !important;
    }

    .form-control {
        box-sizing: border-box;

        border: 1px solid #939598;

        font-family: "The Jamsil OTF 4 Medium";
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 27px !important;
        letter-spacing: 0em !important;
        text-align: left !important;
        padding: 1px 12px !important;
        border-radius: 8px !important;
    }

    .d-flex {
        overflow-x: scroll;
    }

    .w-5 {
        width: 5% !important;
    }

    .w-10 {
        width: 10% !important;
    }

    .w-15 {
        width: 15% !important;
    }

    .w-20 {
        width: 20% !important;
    }

    .w-25 {
        width: 25% !important;
    }

    .w-30 {
        width: 30% !important;
    }

    .w-40 {
        width: 40% !important;
    }

    .w-50 {
        width: 50% !important;
    }

    .w-60 {
        width: 60% !important;
    }

    .w-70 {
        width: 70% !important;
    }

    .w-80 {
        width: 80% !important;
    }

    .w-90 {
        width: 90% !important;
    }

    .w-100 {
        width: 100% !important;
    }

    .navbar-dark .navbar-toggler {
        color: #1C1A23 !important;
        border-color: #1C1A23 !important;
    }

    #wallet-container .collection .mymC_wrap .mymC_memeName .mymC_tag {
        float: left;
        font-size: .5rem;
        margin-top: 0rem;
        width: 4rem
    }

    #wallet-container .collection .mymC_wrap .mymC_memeName .mymC_logo {
        text-align: right;
        margin-top: 2.7rem;
        width: fit-content;
    }

    #wallet-container .collection .mymC_wrap .mymC_memeName .mymC_logo>img {
        width: 1.5rem;
        padding: 0rem;
        background: none;
        border-radius: 0px
    }

    .collection {
        position: relative;
    }

    .collection .layerDim {
        display: block;
        width: 10.5rem;
        height: 10.5rem;
        background: #000000aa;
        position: absolute;
        z-index: 5;
        display: none;
    }

    .collection .buttonBox {
        display: block;
        width: 8rem;
        height: 8rem;
        position: absolute;
        z-index: 7;
        left: 50%;
        top: 32%;
        transform: translate(-50%, -50%);
        display: none;
    }

    .collection .shareBtn {
        width: 40%;
        float: right;
        z-index: 10;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    #wallet-container .collection .mymC_memeName {
        width: 100%;
    }

    #userInfo {
        background: #000;
    }

    #wallet-container .cashTable,
    #wallet-container .historyTable {
        border-collapse: separate;
        border: 1px solid #D3D3D3;
        text-align: center;
        border-radius: 10px;
    }


    #wallet-container .cashTable .unit,
    #wallet-container .historyTable .unit {
        font-size: 12pt;
        font-weight: 700;
        margin-left: 4pt;
    }

    #wallet-container .table-box {
        border: 1px solid #d3d3d3;
        border-radius: 10px;
    }

    #wallet-container .historyTable {
        width: 100%;
        border: none;
        border-bottom: 1px solid #d3d3d3 !important;
        border-radius: 0;
    }

    #wallet-container .cashTable tr,
    #wallet-container .cashTable th,
    #wallet-container .cashTable td {
        border: 1px solid #D3D3D3;
    }

    #wallet-container .cashTable td,
    #wallet-container .cashTable th {
        padding: 7pt;
    }

    #wallet-container .history td,
    #wallet-container .history th {
        padding: 4pt 8pt;
    }

    #wallet-container .tb-hcontent {
        width: 100%;
    }

    .card-back {
        margin: 0 auto;
        position: relative;
    }

    .react-player {
        position: absolute;
        left: 5%;
        top: 8%;
    }

    .card-player {
        background: #d3d3d3d3;
        left: 0%;
        top: 0%;
    }

    video {
        border-radius: 20px;
    }

    #wallet-container .history-box {
        max-height: 250px;
        overflow-y: scroll;
        width: 100%;
        text-align: center;
    }

    #wallet-container .history-box::-webkit-scrollbar {
        background: #D3D3D3 0% 0% no-repeat padding-box;
        mix-blend-mode: overlay;
        border: 3px solid #FFFFFF;
        border-radius: 4px;
        display: block;
    }

    #mymC_meme {
        /* position:relative; */
    }

    .replyBox {
        overflow-y: scroll;
        max-height: 300px;
    }

    #mymC_meme .rankInfo {
        position: absolute;
        left: -45%;
        top: -60%;
    }

    #mymC_meme .rankInfo>span {
        color: #fff;
        font-size: 12pt;
        font-weight: bolder;
    }


    .footer-box {
        margin: 0pt auto;
        padding: 30px 0;
        font-family: 'Pretendard-r' !important;
    }

    .footer-box.d-flex {
        display: block !important;
    }


    .footer-box .footer-data {
        margin: 0pt 0;
        width: fit-content;
        font-size: 13px;
        line-height: 1.6;
        color: #667080;
        font-weight: 500;
        text-align: left;
    }

    .footer-box .footer-data .footer-title {

        font-family: 'Pretendard-b' !important;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height, or 120% */
        letter-spacing: -0.02em;

        color: #667080;
        margin-bottom: 19px;

    }

    .f-logo {
        width: 60px;
        /* height: 96px; */
    }

    .f-logo img {
        width: 100%;
    }

    .hallOfRanking {
        position: absolute;
        bottom: 0pt;
        left: 50%;
        margin-left: 0 !important;
        transform: translate(-50%);
    }

    .community-hash-tag {
        margin-right: 4pt;
    }

    #mymbId #twoLevel_bottom {
        margin-top: 45pt;
    }

    #userInfo .react-player {
        height: 9rem !important;
        background: #fff;
        top: 11.7pt;
        left: 4pt;
        border-radius: 0 3px 0 3px !important;
    }

    #userInfo .swiper {
        height: 8rem !important;
    }

    #mymbId .react-player {
        height: 9.5rem !important;
    }


    .replyBox {
        max-height: 250px;
    }

    .stage {
        /* width: 9rem;
        height: 9rem; */
        position: relative;
        display: block;
        margin: 0;

        -webkit-perspective: 60rem;
        -ms-perspective: 60rem;
        -moz-perspective: 60rem;
        -o-perspecitive: 60rem;
        perspective: 60rem;
    }

    .cube {
        /* height: 9rem;
        width: 9rem; */
        position: relative;
        margin: .2rem 0;
        -webkit-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;
    }

    .cube div {
        /* height: 9rem;
        width: 9rem; */
        /* position: absolute; */
        color: white;
        text-align: center;
        line-height: 8rem;
    }

    .side_top {
        background: #ffffff;
        top: -6.1rem;
        left: 0pt;
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
        transform: rotateX(90deg);
    }

    .side_bottom {
        background: #ffffff;
        top: 6.1rem;
        left: 0pt;
        -webkit-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
    }

    .side_front {
        background: #818181;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(0deg) translateZ(0rem);
        -ms-transform: rotateY(0deg) translateZ(0rem);
        -o-transform: rotateY(0deg) translateZ(0rem);
        transform: rotateY(0deg) translateZ(0rem);
    }

    .side_back {
        background: #818181;
        opacity: 100%;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(180deg) translateZ(0rem);
        -ms-transform: rotateY(180deg) translateZ(0rem);
        -o-transform: rotateY(180deg) translateZ(0rem);
        transform: rotateY(180deg) translateZ(0rem);
    }

    .side_right {
        background: #2C3D4F;
        top: 0pt;
        left: 6.1rem;
        transform: rotateY(90deg) !important;
        -webkit-transform: rotateY(90deg);
        -ms-transform: rotateY(90deg);
        -o-transform: rotateY(90deg);
    }

    .side_left {
        background: #2C3D4F;
        top: 0pt;
        left: -6.1rem;
        -webkit-transform: rotateY(-90deg);
        -ms-transform: rotateY(-90deg);
        -o-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
    }

    .stage.rotate:hover .cube {
        -webkit-transform: rotateY(360deg);
        -ms-transform: rotateY(360deg);
        -o-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }

    .nav-link {
        text-decoration: none;
        color: white;
        text-align: left;
        margin-left: 10px;
    }

    .l20-t6-pr {
        left: 30%;
        top: 13%;
    }

    .l60-t6-pr {
        left: 60%;
        top: 13%;
    }

    #mymbID_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .hotMymC_1 .hotMymCs>.stage {
        height: 8rem;
        width: 8rem;
        margin-left: 1rem;
    }

    .hotMymCs>.stage>.cube {
        height: 7rem;
        width: 7rem;
        position: relative;
        margin: .5rem 0;
    }

    .hotMymC_2>.hotMymCs>.stage>.cube {
        height: 6rem;
        width: 6rem;
        position: relative;
        margin: .5rem 0;
    }

    .hotMymC_2>.hotMymCs>.stage {
        height: 6rem;
        width: 6rem;
    }

    .hotMymC_1 .hotMymCs>.stage>.cube div {
        height: 8rem;
        width: 8rem;
        position: absolute;
        color: white;
        text-align: center;
        line-height: 7rem;
    }

    .hotMymC_2>.hotMymCs>.stage>.cube div {
        height: 6rem;
        width: 6rem;
        position: absolute;
        color: white;
        text-align: center;
        line-height: 5rem;
    }


    .hotMymC_1 .hotMymCs>.stage>.cube .side_top {
        background: #ffffff;
        top: -4rem;
        left: 0;
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
        transform: rotateX(90deg);
    }

    .hotMymC_1 .hotMymCs>.stage>.cube .side_bottom {
        background: #ffffff;
        top: 4rem;
        left: 0pt;
        -webkit-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
    }

    .hotMymC_1 .hotMymCs>.stage>.cube .side_front {
        background: #818181;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(0deg) translateZ(0rem);
        -ms-transform: rotateY(0deg) translateZ(0rem);
        -o-transform: rotateY(0deg) translateZ(0rem);
        transform: rotateY(0deg) translateZ(0rem);
    }

    .hotMymC_1 .hotMymCs>.stage>.cube .side_back {
        background: #818181;
        opacity: 100%;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(180deg) translateZ(0rem);
        -ms-transform: rotateY(180deg) translateZ(0rem);
        -o-transform: rotateY(180deg) translateZ(0rem);
        transform: rotateY(180deg) translateZ(0rem);
    }

    .hotMymC_1 .hotMymCs>.stage>.cube .side_right {
        background: #2C3D4F;
        top: 0pt;
        left: 4rem;
        transform: rotateY(90deg) !important;
        -webkit-transform: rotateY(90deg);
        -ms-transform: rotateY(90deg);
        -o-transform: rotateY(90deg);
    }

    .hotMymC_1 .hotMymCs>.stage>.cube .side_left {
        background: #2C3D4F;
        top: 0pt;
        left: -4rem;
        -webkit-transform: rotateY(-90deg);
        -ms-transform: rotateY(-90deg);
        -o-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_top {
        background: #ffffff;
        top: -3rem;
        left: 0pt;
        transform: rotateX(90deg);
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_bottom {
        background: #ffffff;
        top: 3rem;
        left: 0pt;
        -webkit-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_front {
        background: #818181;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(0deg) translateZ(0rem);
        -ms-transform: rotateY(0deg) translateZ(0rem);
        -o-transform: rotateY(0deg) translateZ(0rem);
        transform: rotateY(0deg) translateZ(0rem);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_back {
        background: #818181;
        opacity: 100%;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(180deg) translateZ(0rem);
        -ms-transform: rotateY(180deg) translateZ(0rem);
        -o-transform: rotateY(180deg) translateZ(0rem);
        transform: rotateY(180deg) translateZ(0rem);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_right {
        background: #2C3D4F;
        top: 0pt;
        left: 3rem;
        transform: rotateY(90deg) !important;
        -webkit-transform: rotateY(90deg);
        -ms-transform: rotateY(90deg);
        -o-transform: rotateY(90deg);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .side_left {
        background: #2C3D4F;
        top: 0pt;
        left: -3rem;
        -webkit-transform: rotateY(-90deg);
        -ms-transform: rotateY(-90deg);
        -o-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
    }

    .hotMymC_2>.hotMymCs>.stage>.cube .mymC_memeName {
        margin-top: .25rem !important;
    }

    .hotMymC-logo-box {
        width: fit-content !important;
        height: fit-content !important;
        top: 47%;
        right: 5%;
    }

    #wallet-container .mymCard_create {
        margin-top: 3rem;
        width: 11rem;
        left: 1rem;
        display: block;
        position: relative;
        float: none;
    }

    #nftCollection_content .mymC-logo-box {
        top: 12%;
        left: 55%;
    }

    .postWrap .basicBtn {
        max-width: calc(80%/2);
    }

    /* in commPost */

    #commPost .stage,
    #wallet-container #nftCollection_content .stage {
        /* width: 15rem;
        height: 15rem; */
    }

    #commPost .cube,
    #wallet-container #nftCollection_content .cube {
        /* height: 15rem;
        width: 15rem; */
        margin: .2rem 0;
    }

    #commPost .cube div,
    #wallet-container #nftCollection_content .cube div {
        /* height: 15rem;
        width: 15rem; */
        position: absolute;
        color: white;
        text-align: center;
        line-height: 11rem;
    }

    #commPost .side_top,
    #wallet-container #nftCollection_content .side_top {
        background: #ffffff;
        top: -6rem;
        left: 0pt;
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
        transform: rotateX(90deg);
    }

    #commPost .side_bottom,
    #wallet-container #nftCollection_content .side_bottom {
        background: #ffffff;
        top: 6rem;
        left: 0pt;
        -webkit-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
    }

    #commPost .side_front,
    #wallet-container #nftCollection_content .side_front {
        background: #818181;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(0deg) translateZ(0rem);
        -ms-transform: rotateY(0deg) translateZ(0rem);
        -o-transform: rotateY(0deg) translateZ(0rem);
        transform: rotateY(0deg) translateZ(0rem);
    }

    #commPost .side_back,
    #wallet-container #nftCollection_content .side_back {
        background: #818181;
        opacity: 100%;
        top: 0px;
        left: 0px;
        -webkit-transform: rotateY(180deg) translateZ(0rem);
        -ms-transform: rotateY(180deg) translateZ(0rem);
        -o-transform: rotateY(180deg) translateZ(0rem);
        transform: rotateY(180deg) translateZ(0rem);
    }

    #commPost .side_right,
    #wallet-container #nftCollection_content .side_right {
        background: #2C3D4F;
        top: 0pt;
        left: 6rem;
        transform: rotateY(90deg) !important;
        -webkit-transform: rotateY(90deg);
        -ms-transform: rotateY(90deg);
        -o-transform: rotateY(90deg);
    }

    #commPost .side_left,
    #wallet-container #nftCollection_content .side_left {
        background: #2C3D4F;
        top: 0pt;
        left: -6rem;
        -webkit-transform: rotateY(-90deg);
        -ms-transform: rotateY(-90deg);
        -o-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
    }

    #commPost .stage.rotate:hover .cube,
    #wallet-container #nftCollection_content .stage.rotate:hover .cube {
        -webkit-transform: rotateY(360deg);
        -ms-transform: rotateY(360deg);
        -o-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }

    /* 탭 스타일 */
    .tab_item {
        height: 50px;
        padding: 0rem .7rem;
        margin: 0;
        border: 2px solid #2C3D4F;
        border-bottom: none !important;
        border-radius: 6px;
    }


    #mymbID_content>#twoLevel_left {
        width: 100% !important;
        margin: 15pt 0 0 0 !important;
    }

    #mymbID_content>#twoLevel_left .stage,
    #mymbID_content>#twoLevel_left .cube {
        margin: 0 auto !important;
    }

    #mymbID_content>.twoLevel_right {
        width: 100% !important;
        margin-top: 1rem !important;
        font-size: 10pt !important;
    }

    .mt-10 {
        margin-top: 1rem !important;
    }

    .mt-3 {
        margin-top: .75rem !important;
    }

    .mt-2 {
        margin-top: .5rem !important;
    }

    .mt-1 {
        margin-top: .25rem !important;
    }

    .mb-3 {
        margin-bottom: .75rem !important;
    }

    .mb-2 {
        margin-bottom: .5rem !important;
    }

    .mb-1 {
        margin-bottom: .25rem !important;
    }

    .ml-3 {
        margin-left: .75rem !important;
    }

    .ml-2 {
        margin-left: .5rem !important;
    }

    .ml-1 {
        margin-left: .25rem !important;
    }

    .mr-3 {
        margin-right: .75rem !important;
    }

    .mr-2 {
        margin-right: .5rem !important;
    }

    .mr-1 {
        margin-right: .25rem !important;
    }

    .m-0 {
        margin: 0 !important;
    }

    .pt-3 {
        margin-top: .75rem !important;
    }

    .pt-2 {
        margin-top: .5rem !important;
    }

    .pt-1 {
        margin-top: .25rem !important;
    }

    .pb-3 {
        margin-bottom: .75rem !important;
    }

    .pb-2 {
        margin-bottom: .5rem !important;
    }

    .pb-1 {
        margin-bottom: .25rem !important;
    }

    .pl-3 {
        margin-left: .75rem !important;
    }

    .pl-2 {
        margin-left: .5rem !important;
    }

    .pl-1 {
        margin-left: .25rem !important;
    }

    .pr-3 {
        margin-right: .75rem !important;
    }

    .pr-2 {
        margin-right: .5rem !important;
    }

    .pr-1 {
        margin-right: .25rem !important;
    }

    .p-0 {
        padding: 0 !important;
    }

    #wallet-container #nftCollection_content .twoLevel_right {
        padding-left: .5rem;
    }


    #wallet-container #nftCollection_content #twoLevel_left .stage {
        margin: 0 auto !important;
    }

    #nftCollection_content .input-form>.input-form {
        height: 25rem !important;
    }

    #wallet-container #nftCollection_content .input-form.d-flex {
        display: block !important;
    }

    #wallet-container .collection .mymC_wrap .mymC_memeName .mymC_tag {
        float: left;
        font-size: .5rem;
        margin-top: .7rem;
        width: 4rem
    }

    #wallet-container .collection .mymC_wrap .mymC_memeName .mymC_logo {
        text-align: right;
        margin-top: 2.7rem
    }

    #wallet-container .collection .mymC_wrap .mymC_memeName .mymC_logo>img {
        width: fit-content;
        padding: 0rem;
        background: none;
        border-radius: 0px
    }

    #wallet-container .collection .mymC_memeName {
        width: 100%;
    }

    .f-logo {
        width: 35px;
        /* height: 96px; */
    }

    .event-body>img {
        width: 100% !important;
    }

    .label-id {
        font-size: 12pt;
        margin-left: 4pt !important;
    }

    .label-content {
        font-size: 9pt;
    }

    #commPost .react-player,
    #wallet-container #nftCollection_content .react-player {
        top: 18pt;
    }

    #wallet-container #nftCollection_content .react-player {
        top: 11pt;
        height: 9rem !important;
    }

    #wallet-container #nftCollection_content .react-player.card-player {
        height: 13.7rem !important;
    }

    #wallet-container #nftCollection_content .twoLevel_right {
        display: flex !important;
        align-items: center;
        height: 12rem !important;
    }

    #wallet-container .mymCard_create {
        margin: 1rem auto 0 !important;
    }

    #myIdContent>.react-player.card-player {
        width: 90%;
        height: 60pt !important;
        left: 4pt;
        top: 6pt;
        border-radius: 10px;
    }


    #main>content>div.input-form {
        margin: 0pt auto 25pt !important;
    }

    .fa-solid.fa-arrow-right-from-bracket.fa-2x,
    .fa-solid.fa-arrow-right-to-bracket.fa-2x {
        margin-left: 6pt !important;
    }

    #wallet-container input[type="text"]:disabled {
        width: 70% !important
    }

    #commPost .cube div {
        /* position: relative; */
    }

    .hamMenu>img {
        width: 25px !important;
        margin-right: 0pt !important;
    }

    #wallet-container .wallet-img {
        top: 1rem;
        width: 90% !important;
        left: 0.6rem;
        border-radius: 10px;
        position: absolute;
        height: 10rem !important;
    }

    .board .fa-eye {
        left: 0pt;
    }

    .board .fa-heart {
        left: 16pt;
    }

    .board .eye-cnt {
        left: 6pt;
    }

    .board .likeCnt {
        left: 23pt;
    }

    .fa-heart:before,
    .fa-eye::before,
    .fa-hand-spock:before {
        font-size: 13pt;
    }

    #myIdContent .my-img {
        position: absolute;
        left: 3pt;
        border-radius: 4px;
        width: 90% !important;
        top: 6pt;
        height: 9.5rem !important;
    }

    #userInfo .info-img {
        position: absolute;
        left: 5%;
        top: 9%;
        width: 90% !important;
        height: 4.5rem;
    }

    #mymbId .card-player {
        top: 1rem;
        height: 9.5rem !important;
        width: 90% !important;
        left: 5%;
        border-radius: 10px;
    }

    #mymbId #target_fieldset {
        float: left !important;
        width: 100% !important;
        padding: 15pt 0pt !important;
    }

    #mymbId .tab_content {
        border: none !important;
        padding: 0 0 10px;
        margin-left: 0 !important;
    }

    #mymbId input {
        font-family: "The Jamsil OTF 4 Medium";
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        margin: 5px 0 27px;

    }

    #userView .side_top {
        background: #ffffff;
        top: -6.5rem;
        left: 0pt;
        width: 50vw;
        height: 35vw;
        position: absolute;
        -webkit-transform: rotateX(90deg);
        -ms-transform: rotateX(90deg);
        -o-transform: rotateX(90deg);
        transform: rotateX(90deg);
    }

    #userView .side_bottom {
        background: #ffffff;
        top: 6.5rem;
        left: 0rem;
        width: 50vw;
        height: 35vw;
        position: absolute;
        -webkit-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
    }

    #userView .side_front {
        background: #818181;
        top: 0px;
        left: 0px;
        width: 50vw;
        height: 35vw;
        position: absolute;
        -webkit-transform: rotateY(0deg) translateZ(0rem);
        -ms-transform: rotateY(0deg) translateZ(0rem);
        -o-transform: rotateY(0deg) translateZ(0rem);
        transform: rotateY(0deg) translateZ(0rem);
    }

    #userView .side_back {
        background: #818181;
        opacity: 100%;
        top: 0px;
        left: 0px;
        width: 50vw;
        height: 35vw;
        position: absolute;
        -webkit-transform: rotateY(180deg) translateZ(0rem);
        -ms-transform: rotateY(180deg) translateZ(0rem);
        -o-transform: rotateY(180deg) translateZ(0rem);
        transform: rotateY(180deg) translateZ(0rem);
    }

    #userView .side_right {
        background: #2C3D4F;
        top: 0pt;
        left: 14.3rem;
        width: 27vw;
        height: 35vw;
        position: absolute;
        transform: rotateY(90deg) !important;
        -webkit-transform: rotateY(90deg);
        -ms-transform: rotateY(90deg);
        -o-transform: rotateY(90deg);
    }

    #userView .side_left {
        background: #2C3D4F;
        top: 0pt;
        left: -5.5rem;
        width: 25vw;
        height: 35vw;
        position: absolute;
        -webkit-transform: rotateY(-90deg);
        -ms-transform: rotateY(-90deg);
        -o-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
    }

    .date-box {
        font-size: 5pt;
        padding: 0;
        top: 48%;
        text-align: left;
        width: 28%;
        line-height: 1;
    }

    #event .date-box {
        top: 81.3%;
        font-size: 11pt;
        width: 25%;
        left: 37%;
    }

    #userView .card-nickName {
        position: absolute;
        left: 28pt;
        top: 60pt;
    }

    #userView .card-cardName {
        position: absolute;
        left: 28pt;
        top: 63%;
    }

    #userView .card-hashTag {
        position: absolute;
        left: 28pt;
        top: 72pt;
    }

    #mymbID_content .btn_wrap.share {
        right: 33%;
    }

    #userView .card-back>img:nth-child(2) {
        height: 27.4vw;
        width: 44.7vw !important;
        position: absolute;
        top: 8pt;
        left: 7pt;
        border-radius: 0 10px 0 10px;
    }

    #main .my-img {
        left: 7pt !important;
        top: 10pt !important;
        width: 84% !important;
        height: 9.2rem !important;
        position: absolute;
        border-radius: 0 10px 0 10px !important;
    }

    #main .react-player {
        left: 7pt !important;
        top: 10pt !important;
        width: 84% !important;
        height: 9.2rem !important;
        border-radius: 0px 10px 0px 10px !important;
    }

    #userView .fa-regular.fa-heart {
        display: none;
    }

    #userView .hand-spock-count.like {
        display: none;
    }

    #userView .fa-regular.fa-hand-spock {
        left: 86% !important;
        bottom: 0.05rem !important;
    }

    #join .term-box .form-label {
        width: 75% !important;
    }

    .all-times {
        position: absolute;
        top: 506pt;
        background: #2C3D4F;
        color: #fff;
        font-size: 7pt;
        left: 40%;
        padding: 0 5pt;
    }

    .max-times {
        position: absolute;
        top: 542pt;
        background: #2C3D4F;
        color: #fff;
        font-size: 7pt;
        left: 40%;
        padding: 0 6pt;
    }

    #freeCommunity .hotMymC_2 .profile-data {
        height: 16rem !important;
    }

    #freeCommunity .comm-category {
        right: 7rem;
        top: 4%;
    }


    #freeCommunity .community-content-box .cube,
    #freeCommunity .community-content-box .stage {
        width: 20rem;
        height: 20rem;
        position: relative;
        margin: 0 auto !important;
    }

    #freeCommunity .community-content-box .hotMymCs,
    #freeCommunity .community-content-box .card-back>img {
        height: 20rem !important;
    }

    #freeCommunity .board .hotMymC_2 {
        height: 52rem !important;
    }

    .video-js-player {
        position: absolute;
        top: 11%;
        left: 8%;
        width: 14.7rem !important;
        height: 14.5rem !important;
        border-radius: 0 10px 0 10px;
    }

    .video-js-player>div {
        width: 14.7rem !important;
        border-radius: 0 10px 0 10px;
    }

    .video-js .vjs-tech {
        height: 14.5rem !important;
        width: 16.7rem !important;
        border-radius: 0 27px 0 27px;
        background: #000;
    }

    .vjs-control-bar {
        width: 16.7rem !important;
        height: 2rem !important;
        bottom: -15% !important;
        left: 1px !important;
        border-radius: 0 0 0 12px;
    }

    .vjs-control-bar>div {
        position: relative !important;
        width: 6rem !important;
    }

    .video-js.vjs-1-1, .video-js.vjs-16-9, .video-js.vjs-4-3, .video-js.vjs-9-16, .video-js.vjs-fluid {
        border-radius: 0 20px 0 20px;
    }

    #freeCommunity .community-content-box .card-back>img.comm-img {
        position: absolute;
        left: 13pt;
        top: 11.4%;
        height: 35rem !important;
        border-radius: 0 10px 0 10px;
        width: 84% !important;
        height: 14.5rem !important;
    }

    #freeCommunity .hotMymC .hotMymC_2 .profile-data {
        height: 10rem !important;
    }

    #userProfile .card-back>img.info-img {
        position: absolute;
        left: 4pt;
        top: 5pt;
        width: 5rem !important;
        height: 4.5rem !important;
        border-radius: 0 5px 0 5px;
    }

    #userProfile .input-form .hotMymC_2 .row.fs-6.w-80.ml-1 {
        margin: 0 !important;
        width: 45% !important;
    }

    #userProfile .input-form .hotMymC_2 {
        margin-bottom: 0px;
        display: inline-flex !important;
        height: auto !important;
        align-items: center;
        justify-content: center;
    }

    #userProfile .hotMymCs {
        width: fit-content;
    }

    #freeCommunity .hotMymC .board .hotMymC_2 {
        height: 50rem !important;
    }

    .projectCategory {
        font-family: 'S-CoreDream-8';
        border: 2px solid #ddd;
        background: #ddd;
        padding: 12pt 15pt;
        font-weight: 800;
        font-size: 16px;
        width: auto;
        line-height: 1.3;
        border-radius: 20px;
        margin: 20pt 4pt;
        color: #222;
        width: max-content;
    }

    .projectCategory.active {
        border: 2px solid #2C3D4F;
        background: #111;
        color: #fff;
    }

    #projectContent .content-box {
        padding: 5pt 2pt;
        margin-top: 15pt;
        border: 1px solid #2C3D4F;
        border-radius: 10px;
        height: 300px;
        overflow-y: scroll;
        line-height: 2.2;
    }


    #projectContent .content .input[type="checkbox"] {
        width: 20px;
        height: 20px;
    }


    .sub-project {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        color: #fff;
        padding: 12pt 0;
        width: 100%;
        margin: auto;
        position: relative;
        border-bottom: 1px solid #1C1A23;
        ;
    }

    .sub-project .name {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
    }

    .sub-project.created-at {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
    }

    .sub-project .badge {
        color: #fff !important;
        font-size: 14px;
    }

    .sub-project .delete-btn {
        font-family: 'S-CoreDream-8';
        background: #B40431;
        color: #fff;
        border: 1px solid #B40431;
        border-radius: 5px;
        font-weight: 800;
        font-size: 14px;
        padding: 4pt 15pt;
    }

    .project-item-name {
        font-size: 16px;
        color: #fff;
        position: absolute;
        top: 5pt;
        left: 5pt;
    }

    #main .project-swiper .swiper-slide {
        height: 20rem !important;
    }

    #main .mainItem {
        background: url('/img/logo_m1.png') center no-repeat !important;
        background-size: contain !important;
        width: 200px;
        height: 200px;
        border: 1px solid #fff;
    }

    .trailsText {
        position: relative;
        width: 100%;
        height: 80px;
        line-height: 80px;
        color: black;
        font-size: 6em;
        font-weight: 800;
        letter-spacing: -0.05em;
        will-change: transform, opacity;
        overflow: hidden;
    }

    .trailsText>div {
        padding-right: 0.05em;
        overflow: hidden;
    }

    .motion-bg {
        width: 100%;
        height: 100%;
        background: #222;
        position: absolute;
        top: 7%;
    }

    .voteItem .title {
        font-family: "The Jamsil OTF 4 Medium";
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
        margin: 0;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
    }

    #projectContent .content-list {
        padding: 15px;
    }

    .voteItem {
        position: relative;
    }

    #projectContent .content-box ul>li.voteItem {
        padding: 20px 13px 65px;
        display: block ! important;
    }

    .voteItem .content-area {
        font-family: "The Jamsil OTF 4 Medium";
        font-size: 13px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        width: 100%;
        white-space: break-spaces;
        display: -webkit-inline-box;
        color: #6b6e6f;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical
    }

    .voteItem .button-box {
        display: flex;
        width: max-content;
        position: absolute;
        bottom: 20pt;
        left: 5pt;
    }

    #projectContent .content-box .viewBtn,
    #projectContent .content-box .playerBtn {
        font-size: 14px;
        border-radius: 20px;
        padding: 3px 16px;
    }

    .checkmark {
        top: 0;
        right: 0pt;
        height: 22px;
        width: 22px;
    }

    .voteItem .title-box {
        width: 100%;
    }

    .voteItem .name {
        font-family: "The Jamsil OTF 4 Medium";
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
        margin: 0;
    }

    .voteItem .badge.vote-badge {
        margin: 0px 2px 17px 0px;
    }

    #mymbId .stage,
    #mymbId #twoLevel_top {
        display: inline-block;
    }

    #mymbId #twoLevel_top {
        float: right;
    }

    #mymbId .row>div {
        margin-bottom: 5px;
    }

    #mymbId .row label,
    #mymbId .row div {
        font-family: "The Jamsil OTF 4 Medium";
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;

        color: #fff;
    }

    #mymbId .row>div {
        padding: 0 15px;
    }

    #mymbId #refcode2 {
        width: fit-content;
    }

    #mymbId #twoLevel_left button {
        width: 18px;
        height: 18px;
        margin-left: 10px;
    }

    #mymbId .save-btn-box {
        text-align: center;
        width: 100%;
    }

    #mymbId #saveBtn {
        right: 31%;
        padding: 12px 48px;
        border-radius: 40px;
        background: #ee574c;
        width: fit-content;
        height: auto;
        border: none;
        display: flex;
        align-content: center;
        color: #fff;
    }

    #mymbId .coming-soon {
        font-family: "The Jamsil OTF 4 Medium";
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        color: #1F86EF;

    }

    #mymbId .img-text-box {
        margin: 35px 0 24px;
    }

    #mymbId .img-text-box .img-title {
        font-family: "The Jamsil OTF 4 Medium";
        font-size: 16px;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        margin: 5px 0;
    }

    #mymbId .img-text-box .img-content {
        font-family: "The Jamsil OTF 4 Medium";
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
    }

    .mb-40px {
        margin-bottom: 20px;
    }

    #editBtn {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1000;
        padding: 16px 12px !important;
        background: #9EEF20;
        border-radius: 8px 8px 0 0 !important;
        font-family: "The Jamsil OTF 4 Medium";

        font-family: 'Pretendard-r' !important;
        font-style: normal;
        font-weight: 500;
        font-size: 18px !important;
        line-height: 24px !important;
        /* identical to box height, or 133% */

        text-align: center;

        color: #000 !important;

        width: 100%;
    }

    .footer-box .footer-data .terms-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .footer-box .footer-data p {

        font-family: 'Pretendard-r';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* or 171% */
        letter-spacing: -0.02em;

        color: #667080;
        margin-bottom: 4px;
    }

    .navbar-dark .navbar-nav .nav-link {
        font-size: 14px;
    }

    .snsBox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin: 12pt 0;
        color: #667080;
        font-size: 1.2rem;
        grid-gap: 2rem;
    }

    .snsBox i {
        font-weight: 900;
        padding: 12px 1px;
        border-radius: 100%;
        /* width: 10px; */
        background: #A0FF0B;
        text-align: center;
    }

    .ld-brd-list.active {
        position: relative;
        left: auto;
        top: auto;
        z-index: 1;
    }

    .exp-prjt img {
        width: 100%;
    }


    .notice-title {
        font-family: 'The Jamsil OTF 4 Medium';
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #fff;
    }

    .notice-title::before {
        content: '';
        border: 1px solid #9fb88c;
        background: #A0FF0B;
        border-radius: 100%;
        width: 11px;
        height: 11px;
        display: inline-block;
        margin-right: 12px;
    }

    .notice-date {
        font-family: 'The Jamsil OTF 4 Medium';
        font-size: 10px !important;
        font-weight: 300;
        line-height: 17.6px;
        text-align: center;
        color: #fff;
    }

    .writer-name .d-day-text {
        font-size: 1rem;
    }

    .badge-btn {
        font-size: 8px;
        padding: 3px 6px;
    }

    .writer-name .percentage-text {
        font-size: 13px;
    }

}

.btn01 {
    border-radius: 0.75rem;
    border: 1px solid rgba(160, 255, 11, 0.76) !important;
    padding: 0.5rem 1rem;
    background: #4E5A8F;
    color: #FFF;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
}

.line-through-deco {
    text-decoration-line: line-through !important;
    color: #939598 !important;
}

.line-through-deco span, .line-through-deco p, .line-through-deco div {
    color: #939598 !important;
}

.price-item {
    font-family: Inter;
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 18.15px !important;
    text-align: center !important;
}

@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1024px !important;
    }
}

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1024px !important;
    }
}