
.container {
    width: 1200px !important;
    margin: 0 auto;
}

.m-version {
    display: none !important;
}

.p-version {
    display: block !important;
}

section {
    color: #fff;
}

section>.container {
    width: 1280px;
    margin: 0 auto;
}

section.sec-1 {
    margin-top: 52px;
    background: #1C1A23;;
}

/* section 1 */
section.sec-1 {
    padding: 0 0 80px;
}

.title-box {
    margin: 120px 0 100px;
}

.title-box h3 {
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    color: #B0B8C1;
}

.title-box.icon-title img {
    width: 28px;
    object-fit: cover;
    margin-right: 9px;
    vertical-align: bottom;
}

.title-box h1 {
    font-family: "The Jamsil OTF 4 Medium";
    font-weight: 500;
    font-size: 55px;
    line-height: 63px;
    color: #fff;;
    margin-top: 20px;
}

.title-box h1 .text-bold {
    font-family: "The Jamsil OTF 4 Medium";
}

.item-list>ul {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
}

.item-list>ul>li {
    list-style: none;
}

.item-list.icon-list>ul>.item-box {
    text-align: left;
}

.item-list.icon-list>ul>.item-box>img {
    width: 90px;
    height: 82px;
    object-fit: cover;
}

.item-list.icon-list>ul>.item-box .light-title {
    font-family: "The Jamsil OTF 4 Medium";
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #B0B8C1;
    margin-top: 29px;
}

/* section 2 */
section.sec-2 {
    background: #1C1A23;
    padding: 80px 0;
}

.title-box h5 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}

.text-em {
    color: #9EEF20 !important;
}

.item-list.funding-list>ul {
    justify-content: flex-start;
}

.item-list.funding-list>ul>.item-box {
    text-align: center;
}

.item-list.funding-list>ul>.item-box:not(:last-child) {
    margin-right: 66px;
}

.item-list.funding-list>ul>.item-box .more-btn-txt {
    margin-top: 9px;
    font-family: "The Jamsil OTF 4 Medium";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;

    text-align: center;

    color: #fff;;

}

.item-list.funding-list>ul>.item-box img {
    width: 260px;
    height: 400px;
    object-fit: contain;
}

.item-list.funding-list>ul>.item-box .more-btn {
    width: 72px;
    height: 72px;
    object-fit: contain;
}

.item-list.funding-list>ul>.item-box .more-btn-text {
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #fff;;
}

/* section 3 */
section.sec-3 {
    padding: 80px 0;
    background: #1C1A23;;
}

.title-box .sub-title {
    font-family: "The Jamsil OTF 4 Medium";
    font-style: normal;
    font-size: 24px;
    line-height: 28px;
    color: #fff;;
    margin-top: 13px;
}

.item-list.process-list>ul {
    justify-content: space-between;
}

.item-list.process-list>ul>.item-box {
    padding: 35px 42px;
    border: 1px solid #F2F2F2;
    border-radius: 8px;
}

.item-list.process-list>ul>.item-box>img {
    width: 100px;
    height: 102px;
    object-fit: contain;
    margin: 30px 0;
    ;
}

.item-list.process-list>ul>.item-box .more-btn {
    width: 72px;
    height: 72px;
    object-fit: contain;
}

.item-list.process-list>ul>.item-box .more-btn-text {
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #fff;;
}

.process-item .item-name {
    font-family: "The Jamsil OTF 4 Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height */
    text-align: center;

    color: #939598;
    padding-bottom: 11px;
    border-bottom: 1px solid #9EEF20;
}


@media (max-width: 769px) {
    .container {
        width: 100vw !important;
        padding:0 20px !important; 
    }

    .item-list>ul {
        display: grid !important;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        text-align: start;
        grid-gap: 28px;
        padding:0;
    }

    .item-list.funding-list>ul,
    .item-list.process-list>ul {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        list-style-type: none;
        width: 100vw;
        overflow-x: scroll;
        justify-content: flex-start;
    }

    .item-list.funding-list>ul>.item-box:not(:last-child) {
        margin-right: 56px;
    }

    .item-list.icon-list>ul>.item-box>img {
        width: 60px;
        height: 54px;
        object-fit: cover;
    }

    .process-item .item-name{
        font-size:18px;
    }

    .item-list.process-list>ul>.item-box{
        padding: 26px 27px;
    }

    .item-list.process-list>ul>.item-box {
        height: 230px;
    }

    .title-box {
        margin: 50px 0;
    }

    .fixed-top {
        
    }

    .title-box h1 {
        font-size: 24px;
        font-family: "The Jamsil OTF 4 Medium";
        line-height:1.5;
        margin-top:0;
    }

    .title-box h3 {
        font-size: 20px;
        line-height:1.5;
    }

    .title-box h5{
        font-size: 20px;
        line-height:1.5;
    }

    .title-box .sub-title {
        font-size: 15px;
        line-height:1.5;
    }

    section.sec-1,
    section.sec-2 ,
    section.sec-3 {
        padding: 50px 0;
    }

    section.sec-1{
        margin-top:0pt;
    }

    .m-version {
        display: block !important;
    }

    .p-version {
        display: none !important;
    }

    .item-list.icon-list>ul>.item-box .light-title{
        font-size:16px;
        line-height:1.5;
    }

}